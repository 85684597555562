import "./Navigation.scss";
import React from "react";
import classNames from "classnames";
import UserMenu from "./UserMenu";
import * as actions from "../actions/";
import EmailIcon from '@mui/icons-material/Email';
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import PointingArrow from "./PointingArrow";
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectVisible } from "../selectors/storeselectors";
import { useLocation } from 'react-router-dom';

const styles = theme => ({
  secure: {
    color: "#fff"
  },
  twofactorButton: {
    display: "inline-block",
    marginRight: "2rem",
    position: "relative",
    top: "0.3rem",
    cursor: "pointer",
    "&:after": {
      content: '""',
      position: "absolute",
      top: "0.3rem",
      right: "-1rem",
      height: "1rem",
      width: "1px",
      background: "#ffffff"
    }
  },
  emptyMail: {
    position: "relative",
    top: "0.2rem"
  },
  noClick: {
    cursor: "default"
  },
  btnOverWriteSignin: {
    height: "1.5rem",
    padding: "0 1.3rem",
    background: "#2196F3",
    color: "white",
    "&:hover": {
      background: "black"
    }
  },
  button: {
    margin: theme.spacing(1),
    color: "#fff",
    "&:hover": {
      cursor: "pointer",
      color: "#0273b5"
    }
  },
});

const canShowCount = (initials, showMailNotificationBadgde) => {
  if (initials) {
    return showMailNotificationBadgde;
  }
  return false;
};
const invitesCount = invites => {
  const pending =
    invites && invites.length
      ? invites.filter(item => !item.invitationRejectedDateTime)
      : [];
  return pending.length;
};
const showNavBar = (pathname, visible) => {
  if (
    pathname === "/auth"
  ) {
    return true;
  }
  return !visible;
};

const Navigation = ({ signout, pathname }) => {
  const dispatch = useDispatch();

  const selectInitials = state => state.userProfile?.initials;
  const selectShowMailNotificationBadgde = state => state.userProfile?.showMailNotificationBadgde;
  const selectEmailConfirmed = state => state.userProfile?.emailConfirmed;
  const selectStudyInvites = state => state.userProfile?.studyInvites;
  const visible = useSelector(selectVisible);

  const initials = useSelector(selectInitials);
  const showMailNotificationBadgde = useSelector(selectShowMailNotificationBadgde);
  const emailConfirmed = useSelector(selectEmailConfirmed);
  const studyInvites = useSelector(selectStudyInvites);

  const navigate = useNavigate();
  /*
    useEffect(() => {
  
    }, [isAuthenticated]);
  */
  const handleLogoClick = () => {
    dispatch(actions.resetStudyDetail());
    if (pathname !== '/') {
      navigate('/');
    }
  };

  const handleNewClick = () => {
    dispatch(actions.resetStudyDetail());
    //if (pathname !== "/") {
    navigate("/newStudy")
    //}
  };

  const showBagde = canShowCount(initials, showMailNotificationBadgde);
  const location = useLocation();
  //console.log(location.pathname);


  return (
    <nav
      className={classNames(
        "nav",
        "nav__transparent",
        { "nav--white": showNavBar(pathname, visible) }
      )}
    >
      <img
        alt="logo"
        src="/assets/ME-TA_logo_NEG.png"
        className="nav__logo"
        onClick={/*() =>*/ handleLogoClick /*()*/}
      />

      {
        initials && (

          <div className="nav__user-section">

            {/*!showBagde && initials !== "?" && emailConfirmed && location.pathname === "/" && (

              <div
                className="nav__studies"
                onClick={handleNewClick}
              >
                <div style={styles.twofactorButton}>
                  <Tooltip
                    title="New project"
                    aria-label="New project"
                    sx={styles.lightTooltip}
                  >
                    <AddIcon />
                  </Tooltip>
                </div>
              </div>
            )*/}

            {/*!showBagde && initials !== "?" && emailConfirmed && (
              <div
                className="nav__studies"
                onClick={handleLogoClick}
              >
                <div style={styles.twofactorButton}>
                  <Tooltip
                    title="My studies"
                    aria-label="My studies"
                    sx={styles.lightTooltip}
                  >
                    <AssignmentIcon />
                  </Tooltip>
                </div>
              </div>
            )*/}
            {!showBagde && initials !== "?" && emailConfirmed && (
              <div
                className="nav__inbox"
                onClick={() => dispatch(actions.userProfileToggleInbox())}
              >
                <div style={styles.emptyMail}>
                  <Tooltip
                    title="Notifications"
                    aria-label="Notifications"
                    sx={styles.lightTooltip}
                  >
                    <EmailIcon />
                  </Tooltip>
                </div>
              </div>
            )}

            {showBagde && initials !== "?" && emailConfirmed && (
              <div
                className="nav__inbox"
                onClick={() => dispatch(actions.userProfileToggleInbox())}
              >
                <Badge
                  color="error"
                  badgeContent={invitesCount(studyInvites)}
                  id="inviteCount"
                >
                  <Tooltip
                    title="Notifications"
                    aria-label="Notifications"
                    sx={styles.lightTooltip}
                  >
                    <EmailIcon
                    />
                  </Tooltip>
                </Badge>
              </div>
            )}
            {(!initials || initials === "" || initials === "?") &&
              <PointingArrow />
            }
            {initials &&
              <UserMenu emailConfirmed={emailConfirmed} initials={initials} />
            }
          </div>
        )
      }
    </nav >
  );
};

/*
Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  initials: PropTypes.string,
  showMailNotificationBadgde: PropTypes.bool,
  emailConfirmed: PropTypes.bool,
  studyInvites: PropTypes.array,
  signout: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  pathname: PropTypes.string
};
*/
export default Navigation;