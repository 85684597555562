import * as actions from "../actions/";
import {
  takeLatest,
  call,
  take,
  put,
  getContext,
  select,
  delay
} from "redux-saga/effects";
import { Auth } from "aws-amplify";
import { fetchUserAttributes, fetchMFAPreference } from '@aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { ConsoleLogger } from 'aws-amplify/utils';
import { generateClient } from "aws-amplify/api";
//import awsExports from '../aws-exports';
import { Amplify } from 'aws-amplify';
import {
  get as getAmplify,
  isCancelError,
  put as putAmplify,
  post as postAmplify,
} from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const logger = new ConsoleLogger('MyLoggerName', 'DEBUG');

export function* logout(action) {
  //const navigate = useNavigate();
  try {
    yield put(actions.authenticated(false));
    yield put(actions.clearStudiesStudyDetail());
    yield put(actions.userProfileSignout());
    yield call(signOut());
    //navigate("/auth");
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error in resp ", error);
  }
}
function* refreshTokenMonitor() {

  while (true) {
    const state = yield select();

    const { isAuthenticated } = state.root;

    if (isAuthenticated) {
      try {
        //yield call(Auth.currentSession())
        //yield call(loginService.renewToken);
        yield delay(15 * 60 * 1000); // 15 minutes
        yield put(actions.loginInitializedTokenMonitor());

      } catch (error) {
        console.log("error in resp ", error);
      }
    }
  }
}
const resolveApiResponse = res => {
  if ((res.status && res.status === 504) || (res.status && res.status === 404) || (res.status && res.status === 400)) {
    return false;
  }
  return true;
};

function* loginApi(action) {
  //console.log("loginApi")
  const { id } = action.payload
  //Amplify.configure(awsExports);

  try {

    //Get CognitoUser from aws amplify
    const cognitoUser = yield call(fetchUserAttributes);
    const mfasettings = yield call(fetchMFAPreference);
    //const api = yield getContext("api");
    //const res = yield call(awsPut, { apiName: 'metawebserviceRestApi', path: "customer/LoginCustomer" });
    //var sdfs = yield call(putAmplify, 'metawebserviceRestApi', "customer/LoginCustomer");
    //const res = yield call(getAmplify, 'metawebserviceRestApi', "customer/GetCustomer");
    const api = yield getContext("api");
    const res = yield call(api.put, "customer/LoginCustomer", {});
    const user = yield call(api.get, "customer/GetCustomer");

    //user exists in db so he has been registered
    if (res && !res.status) {
      console.log(res)
      //const Testsd = yield call(awsGet, 'metawebserviceRestApi', "customer/GetCustomer", {});
      //yield delay(200);
      if (resolveApiResponse(user)) {
        yield put(actions.userProfileReceiveCurrentUser(user, cognitoUser, mfasettings));
        yield put(actions.authenticated(true));
        //yield put(actions.userSetMfaStatus(cognitoUser.preferredMFA && cognitoUser.preferredMFA !== "NOMFA" ? true : false))

        if (id) {
          yield put(actions.userProfileResolveInvitation(true, id))
        }

        //yield put(actions.getStudies());
        yield put(actions.getStudiesV2());

        //yield delay(200);
        yield put(actions.userProfileGetStudyInvitations());
        yield put(actions.loginInitializedTokenMonitor());
        yield put(actions.userAttributesReceive(cognitoUser.attributes));
        yield put(actions.loginUserApiFinished())
        //console.log("LoginUserAPIFinished")
        //yield put({ type: IS_COMPLETE_A })
        //yield take(actions.loginUserApiFinished())
      } else {
        console.log(user)
      }
    }
    //user does not exist in db
    else if (res && res.status === 408) {
      //yield put(actions.loginStatusSet("Synchronizing user data, please wait"))
      //console.log("408 recieved waiting 3 seconds")
      yield delay(3000)
      //console.log("3 seconds up calling API again")
      yield put(actions.loginUserApi(id))
    }
    //user does not exist in db
    else if (res && res.status === 404) {
      const registered = yield call(api.get, "customer/RegisterCustomer");
      //yield put(actions.receiveCurrentUser(registered));

      if (resolveApiResponse(registered)) {
        yield put(actions.userProfileReceiveCurrentUser(registered, cognitoUser));
        yield put(actions.authenticated(true))

        if (id) {
          yield put(actions.userProfileResolveInvitation(true, id))
        }

        //yield put(actions.getStudies());
        yield put(actions.getStudiesV2())
        yield delay(200);
        yield put(actions.userProfileGetStudyInvitations())
        yield put(actions.loginInitializedTokenMonitor())
        yield put(actions.userAttributesReceive(cognitoUser.attributes))
        //yield put(actions.loginUserApiFinished())
      } else {
        console.log(registered)
      }
    }
  } catch (error) {
    console.log("error in resp ", error);
    // what error messages do we get 401 ,server asleep, or not authenticated 504 Gateway Timeout (504)
    // 403 forbidden
    yield put(actions.authenticated(false)); // bAD way fix, only temporary
  }
}

export function* loginSagas() {
  yield takeLatest(actions.LOGOUT_SELECTED, logout);
  yield takeLatest(actions.LOGIN_INITIALIZED_TOKEN_MONITOR, refreshTokenMonitor);
  yield takeLatest(actions.LOGIN_USER_API, loginApi);
}
