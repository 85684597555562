import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const basicType = "text";

const BasicTextField = ({
  value,
  onChange,
  id,
  placeholder,
  label,
  error,
  type = basicType,
  autoFocus,
  customStyles,
}) => {
  return (
    <TextField
      autoFocus={autoFocus || false}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      placeholder={placeholder}
      id={id}
      label={label}
      variant="filled"
      margin="normal"
      InputProps={{
        autoCapitalize: "none",
        sx: customStyles?.input
      }}
      inputProps={{
        autoCapitalize: "none"
      }}
      InputLabelProps={{
        required: false,
        error: false,
        shrink: false,
        sx: customStyles?.label
      }}
      sx={{ ...customStyles?.textField }}
      error={error}
      helperText={error}
      value={value}
      type={type}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

BasicTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default BasicTextField;
