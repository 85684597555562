import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react";
import PropTypes from "prop-types";
import * as actions from "../actions/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import EditableTextField from "../ui/EditableTextField";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Smartphone";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
//import { withStyles } from "@mui/material/styles";
import loginStyles from "../auth/LoginStyles";
import LinkBtn from "../ui/LinkButton";
import Button from "../ui/Button";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from "@mui/material/FormLabel";
import { getCurrentUser, updatePassword, fetchAuthSession, resetPassword, fetchUserAttributes } from '@aws-amplify/auth';
import WarningIcon from '@mui/icons-material/Warning';
import SecureIcon from '@mui/icons-material/VerifiedUser';
import TextField from "@mui/material/TextField";
import { green, red, grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ListItemAvatar, List, ListItem, ListItemText, Avatar } from '@mui/material'
import { selectUserProfile, selectPermissisonDetail } from '../selectors/storeselectors';
import { CollectionsBookmarkRounded } from '@mui/icons-material';
import { useNavigate, useLocation, useParams } from "react-router-dom";
const paperStyles = theme => ({
  paper: {
    width: 360
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  buttonWrapper: {
    background: "#0273b5",
    border: 0,
    color: "white",
    height: "44px",
    borderRadius: "2rem",
    top: "-1rem",
    // minWidth: '10rem',
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#0273b5"
    },

    width: "100%"
  },
  btnLabel: {
    fontSize: "0.5rem",
    color: "#333"
  },
  avatar: {
    color: "#fff",
    backgroundColor: "#333" /*deepPurple[500]*/,
    fontSize: "11px",
    width: "2rem",
    height: "2rem",
    marginTop: "1rem",
    marginBottom: "2.5rem"
  },
  content: {
    width: "90%",
    margin: "5rem auto"
  },
  textFieldOverwrite: {
    margin: 0,
    '@media (max-width:600px)': {
      width: "100%"
    }
  },
  grid: {
    marginBottom: "1.8rem"
  },
  m1: {
    marginBottom: "1rem"
  },
  tLeft: {
    textAlign: "left",
    display: "inline-block",
    verticalAlign: "top",
    marginLeft: "0.3rem"
  },
  innerSpan: {
    position: "relative",
    marginLeft: "1.3rem",
    top: "-1.2rem",
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: "1rem"
  },
  link: {
    marginTop: "1rem",
    display: "block",
    "&:hover": {
      color: "#0273b5"
    }
  },
  closeButton: {
    position: "absolute",
    color: grey[500]
  },
  profileCompletion: {
    position: "absolute",
    color: grey[500]
  },
  labelroot: {
    color: "#333",
    fontSize: "0.5rem",
    padding: 0,
    /*fontWeight: 500,*/
    marginBottom: "0.4rem",
    textTransform: "uppercase"
  },
  inputPadding: {
    borderRadius: 0
  },
  danger: {
    color: "red",
  },
  successGreen: {
    color: green[500]
  },
  secure: {
    color: "#fff"
  },
  percentage: {
    fontWeight: 700,
  },
  listItem: {
    height: "2.5rem",
    marginBottom: "0.5rem",
    width: "100%",
    position: "relative",
    border: 0,
    cursor: "pointer",
    padding: 0,
    /*borderBottom: "1px dotted lightgrey",
    "&:last-of-type": {
      border: 0
    }*/
  },
  avatar: {
    background: "#fff",
    /*...theme.typography,*/
    fontSize: "0.6rem"
  },
});


const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ top: "8px" }} position="absolute" display="inline-flex">
      <CircularProgress size={75} thickness={5} variant="determinate" value={props.value} />
      <Box
        top={15}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="block;"
        /*alignItems="center"
        justifyContent="center"*/
        textAlign='center'
      >
        <Typography sx={{ fontWeight: 700 }} variant="caption" component="div" color="textPrimary">{`${Math.round(
          props.value,
        )}%`}</Typography>
        {props.mfaEnabled &&
          <Tooltip
            title="You have two facor authentication enabled"
            aria-label="You have two facor authentication enabled"
          /*classes={{
            tooltip: classNames(
              props.classes.lightTooltip,
              props.classes.tooltipStudy
            )
          }}*/
          >
            <SecureIcon /*className={props.classes.secure}*/ sx={paperStyles.secure} style={{ color: green[500] }} />
          </Tooltip>
        }
        <Box
          top={-10}
          left={90}
          bottom={0}
          right={0}
          position="absolute"
          display="block;"
          /*alignItems="center"
          justifyContent="center"*/
          textAlign='center'
        >
          <Typography variant="h6" component="h6">
            Profile
          </Typography>
          <Typography variant="h6" component="h5">
            {props.value > 30 && !props.emailConfirmed ?
              "Not Confirmed" :
              props.value < 100 && props.value >= 30 && props.emailConfirmed ?
                "Confirmed" :
                props.value === 100 && props.emailConfirmed ?
                  "Complete" :
                  props.value === 100 && props.emailConfirmed && props.mfaEnabled ?
                    "Complete" : "Incomplete"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


const UserProfile = (props) => {
  const { onClose } = props
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const permissionDetail = useSelector(selectPermissisonDetail);
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(actions.userProfileSubmit());
  };

  const toggleDrawer = () => {
    //const { onClose } = props;
    //setState({ open: false });
    setOpen(false)
    setTimeout(() => {
      onClose();
    }, 150);
  };

  const handleMFAChange = (event) => {
    dispatch(actions.userToggleMFA(event.target.checked, userProfile.phoneConfirmed));
  };

  const signOut = () => {
    dispatch(actions.showIdleModal(false));
    dispatch(actions.clearStudiesStudyDetail());
    dispatch(actions.logoutSelected());
    navigate("/");
  };


  const VerifyPhoneNumber = async () => {
    try {
      //const user = await getCurrentUser();
      //await Auth.currentSession()
      //await Auth.verifyUserAttribute(user, 'phone_number')
      const user = await fetchUserAttributes();
      await fetchAuthSession({ forceRefresh: true })
      await verifyUserAttribute(user, 'phone_number')
      dispatch(actions.userProfileEditField("phone_number"))
    }
    catch (e) {
      console.log(e)
    }
  }

  /*VerifyEmail = async () => {

    const { actions } = props;

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.currentSession()
      await Auth.verifyUserAttribute(user, 'email')
      dispatch(actions.userProfileEditField("email"))
    }
    catch (e) {
      console.log(e)
    }
  }*/

  const changePassword = async () => {
    try {
      //const user = await Auth.currentAuthenticatedUser();
      //await Auth.currentSession()
      //await Auth.forgotPassword(user.username);
      //const user = await getCurrentUser();
      const user = await fetchUserAttributes();
      await fetchAuthSession({ forceRefresh: true })
      const { email } = user
      await resetPassword({ username: email });
      dispatch(actions.userProfileEditField("password"))
    }
    catch (e) {
      console.log(e)
    }
  }

  console.log(userProfile, userProfile.phoneConfirmed, userProfile.emailConfirmed, userProfile.contactNameSet, userProfile.companySet)
  const percentageComplete = 20 + /*Email and phone number entered*/
    (userProfile.phoneConfirmed * 10) +       /*Phone confirmed*/
    (userProfile.emailConfirmed * 30) +          /*Email confirmed*/
    (userProfile.contactNameSet * 20) +       /*Contact name is set*/
    (userProfile.companySet * 20)             /*Company name is set*/
  /*(mfaEnabled * 20)*/


  const permissionList =
    /*id &&*/
    permissionDetail &&
      permissionDetail.length > 0
      ? permissionDetail.filter(item => item.selected === true).map(item => item.permission)
      : [];


  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer}
      sx={{ width: 360 }}
    >
      <div style={{ width: "90%", margin: "5rem auto" }}>

        <CircularProgressWithLabel
          sx={{ color: green[500], fontWeight: 700 }}
          value={percentageComplete}
          mfaEnabled={userProfile.mfaEnabled}
          emailConfirmed={userProfile.emailConfirmed} />

        <IconButton
          aria-label="Close"
          sx={{
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            color: grey[500]
          }}
          onClick={() => dispatch(actions.userProfileToggleDrawer())}
        >
          <CloseIcon />
        </IconButton>

        <Grid container direction="column">
          {/*Contact Name*/}
          <Grid item sx={{ marginBottom: "1.8rem" }}>
            <FormGroup row>
              <FormLabel sx={{
                color: "#333",
                fontSize: "0.5rem",
                padding: 0,
                /*fontWeight: 500,*/
                marginBottom: "0.4rem",
                textTransform: "uppercase"
              }} component="legend">Contact name (required)</FormLabel>
              <EditableTextField
                placeholder="Please fill in your name (displayed on overview)"
                value={userProfile.contactName}
                onChange={val =>
                  //dispatch(actions.userProfileUpdateInput("contactName", val))
                  dispatch(actions.userProfileSubmit("contactName", val))
                }
              />
            </FormGroup>
          </Grid>
          {/*company*/}
          <Grid item sx={{ marginBottom: "1.8rem" }}>
            <FormGroup row>
              <FormLabel sx={{
                color: "#333",
                fontSize: "0.5rem",
                padding: 0,
                /*fontWeight: 500,*/
                marginBottom: "0.4rem",
                textTransform: "uppercase"
              }} component="legend">Company (required)</FormLabel>
              <EditableTextField
                value={userProfile.company}
                placeholder="Please fill in company name"
                onChange={val =>
                  //dispatch(actions.userProfileUpdateInput("company", val))
                  dispatch(actions.userProfileSubmit("company", val))
                }
              />
            </FormGroup>
          </Grid>
          {/* email field - emails are non-mutable*/}
          <Grid item sx={{ marginBottom: "1.8rem" }}>
            <TextField
              disabled
              id="email"
              label="Email (required)"
              sx={{ flexGrow: 1, width: '100%' }}
              value={userProfile.email}
              onChange={val => console.log("email can not be changed!")}
            />
            {userProfile.emailConfirmed &&
              <div>
                <span
                  style={{
                    position: "relative",
                    fontSize: "0.6rem",
                    color: "green"
                  }}
                >
                  Verified
                </span>
                <CheckIcon fontSize="small" style={{ color: green[500] }} />
              </div>
            }
            {/*!userProfile.emailConfirmed &&
              <LinkBtn id="verify-email" onClick={VerifyEmail}>
                <WarningIcon fontSize="small" style={{ color: red[500] }} />
                <span
                  style={{
                    position: "relative",
                    fontSize: "0.6rem",
                    color: "red"
                  }}
                >
                  Not verified. Click here to verify!
                </span>
              </LinkBtn>
                */}
          </Grid>
          {/* Phone field - phone numbers are non-mutable*/}
          <Grid item sx={{ marginBottom: "1.8rem" }}>
            <TextField
              disabled
              id="phoneNumber"
              label="Phone number (required)"
              sx={{ flexGrow: 1, width: '100%' }}
              value={userProfile.phone}
              onChange={val => console.log("Phone number can not be changed!")}
            />
            {userProfile.phoneConfirmed &&
              <div>
                <span
                  style={{
                    position: "relative",
                    fontSize: "0.6rem",
                    color: "green"
                  }}
                >
                  Verified
                </span>
                <CheckIcon fontSize="small" style={{ color: green[500] }} />
              </div>
            }
            {!userProfile.phoneConfirmed &&
              <LinkBtn id="verify-phone" onClick={VerifyPhoneNumber}>
                <WarningIcon fontSize="small" style={{ color: red[500] }} />
                <span
                  style={{
                    position: "relative",
                    fontSize: "0.6rem",
                    color: "red"
                  }}
                >
                  Not verified. Click here to verify!
                </span>
              </LinkBtn>
            }
          </Grid>
        </Grid>

        {/*Notice to verify email if not confirmed*/}
        {(!userProfile.emailConfirmed) &&
          <p sx={paperStyles.danger}>You have not confirmed your user!. You need to verify your email to confirm.</p>
        }

        {/*Change password button*/}
        <div style={loginStyles.wrapper}  /*sx={classNames(classes.wrapper)}*/>
          <LinkBtn id="password-change" onClick={changePassword}>
            <LockIcon />
            <span
              style={{
                position: "relative",
                fontSize: "0.7rem",
                marginLeft: "0.4rem"
              }}
            >
              Change password
            </span>
          </LinkBtn>
        </div>

        {/*MFA switch */}
        <div style={loginStyles.wrapper}/*sx={classNames(classes.wrapper)}*/>
          <Grid item sx={{ marginBottom: "1.8rem" }}>
            <FormGroup row>
              <FormControlLabel
                control={<Switch disabled={!userProfile.phoneConfirmed} checked={userProfile.mfaEnabled} onChange={handleMFAChange} name="mfaEnabled" color="primary" />}
                label="Multi Factor Authentication" />
            </FormGroup>
            {!userProfile.phoneConfirmed &&
              <span
                style={{
                  position: "relative",
                  fontSize: "0.6rem",
                  color: "red"
                }}
              >
                Verified phone required before you can enable MFA!
              </span>
            }
          </Grid>
        </div>

        {/* RESET ROS */}
        {(permissionList.includes("SASUPLOAD") && permissionList.includes("ADMIN")) &&
          <div style={loginStyles.wrapper} /*sx={classNames(classes.wrapper)}*/>
            <Grid item sx={{ marginBottom: "1.8rem" }}>
              <List>
                <ListItem
                  key={"studyFileRO"}
                  sx={paperStyles.listItem}
                  onClick={() => dispatch(actions.resetStudyFileRO(id))}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={paperStyles.avatar}
                    /*className={classNames(
                      classes.avatar,
                      classes.inline
                    )}*/
                    >
                      <WarningIcon style={{ color: "red" }} />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    /*classes={{ primary: classes.primary }}*/
                    primary={"Reset studyFileRO"}
                  />
                </ListItem>
                <ListItem
                  key={"sasJobRO"}
                  sx={paperStyles.listItem}
                  /*className={classNames(
                    classes.listItem,
                  )}*/
                  onClick={() => dispatch(actions.resetSasJobRO(id))}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={paperStyles.avatar}
                    /*className={classNames(
                      classes.avatar,
                      classes.inline
                    )}*/
                    >
                      <WarningIcon style={{ color: "red" }} />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    /*classes={{ primary: classes.primary }}*/
                    primary={"Reset sasJobRO"}
                  />
                </ListItem>
              </List>
            </Grid>
          </div>
        }

        {/*Help for anything else*/}
        <a
          sx={paperStyles.link}
          href="mailto:contact@me-ta.dk"
          target="_top"
        >
          <p sx={{
            textAlign: "left",
            display: "inline-block",
            verticalAlign: "top",
            marginLeft: "0.3rem"
          }}>
            <PhoneIcon />
            <span sx={{
              position: "relative",
              marginLeft: "1.3rem",
              top: "-1.2rem",
              display: "inline-block",
              verticalAlign: "top",
              lineHeight: "1rem"
            }}>
              If you need support you can always contact us by clicking here.
            </span>
          </p>
        </a>

        <div style={{ position: "relative", padding: '0 2rem' }}>
          <Button
            fullWidth={true}
            onClick={signOut}
            id="logout"
          >
            Log out
          </Button>
        </div>
      </div>
    </Drawer >
  );
}


UserProfile.propTypes = {
  phoneConfirmed: PropTypes.bool,
  contactName: PropTypes.string,
  company: PropTypes.string,
  errorMessage: PropTypes.array,
  phone: PropTypes.string,
  email: PropTypes.string,
  mfaEnabled: PropTypes.bool,
  emailConfirmed: PropTypes.bool,
  contactNameSet: PropTypes.bool,
  companySet: PropTypes.bool,
  dashboardPermissions: PropTypes.array
};

export default UserProfile;
