import * as actions from "../actions/";
import { takeLatest, call, put, getContext, select, delay } from "redux-saga/effects";
import uniqueId from 'lodash/uniqueId';
import { Amplify } from "aws-amplify";
import { root } from "../reducers";
import { getCurrentUser, fetchAuthSession, updateMFAPreference, confirmUserAttribute, updatePassword } from 'aws-amplify/auth';

function* userProfileSubmit(action) {
  const { type, value } = action.payload;
  try {
    yield put(actions.userProfileUpdateInput(type, value))

    const api = yield getContext("api");
    const state = yield select();
    const {
      company,
      contactName,
      email,
      cognitoUserId,
      emailConfirmed,
    } = state.userProfile;

    const params = {
      ContactName: (contactName) ? contactName : "",
      Company: (company) ? company : "",
      CVR: '5588775544',/* remove this from api */
      CognitoUserId: cognitoUserId,
      Email: email,
      isConfirmed: emailConfirmed
    };

    const user = yield call(api.put, "customer/updateCustomerById", params);
    //const cognitoUser = yield call(getcurrentAuthenticatedUser({ bypassCache: true }))
    const cognitoUser = yield call(getcurrentAuthenticatedUser())
    console.log(cognitoUser)
    yield put(actions.userProfileSubmitSuccess())
    yield put(actions.userProfileReceiveCurrentUser(user, cognitoUser))
    const toastId = uniqueId('userCreate');
    yield put(actions.finalMessage(toastId, "User updated successfully.", "success"))
    yield delay(2500);
    yield put(actions.toastClose(toastId))

  } catch (error) {
    // studyname must be unique, or try again later, that kind og error message
    //yield put(actions.userProfileValidationError('Error in input'));
    console.log("error in resp ", error);
  }
}
function* getStudyInvites() {
  try {
    const api = yield getContext("api");
    const res = yield call(api.get, "StudyInvitation/GetReceivedStudyInvitesbyUser");
    yield put(actions.userProfileStudyInvitationsReceive(res))
  }
  catch (error) {
    const state = yield select();
    const std = state.root.studies;
    yield put(actions.userProfileStudyInvitationsReceive([]))
    console.log('getStudyInvites error what error', error, std);
  }
}
function* resolveInvite(action) {
  try {
    const { accept, studyInvitationId } = action.payload;
    const api = yield getContext("api");

    if (accept) {
      yield call(api.get, `StudyInvitation/AcceptInvite/${studyInvitationId}`);
      yield put(actions.getStudiesV2())
    }
    else {
      yield call(api.get, `StudyInvitation/RejectInvite/${studyInvitationId}`);
    }

    yield put(actions.userProfileGetStudyInvitations())

  }
  catch (error) {
    console.log('error ');
  }
}

async function getcurrentAuthenticatedUser() {
  //return await Auth.currentAuthenticatedUser({ bypassCache: true })
  return await getCurrentUser()
}

async function getCurrentSession() {
  //return await Auth.currentSession()
  return await fetchAuthSession({ forceRefresh: true })
}

async function setMFA(MFAType) {
  if (MFAType === "SMS") {
    return await updateMFAPreference({ sms: 'ENABLED' });
  }
  console.log(MFAType)
  return await updateMFAPreference({ sms: 'DISABLED' });
}


const verifyUserAttribute = async options => {
  //return await Auth.verifyUserAttributeSubmit(options.user, options.type, options.code)
  return await confirmUserAttribute(options.user, options.type, options.code)
}

const forgotPassword = async options => {
  //return await Auth.forgotPasswordSubmit(options.username, options.code, options.password)
  return await updatePassword(options.username, options.code, options.password)
}

const resolveApiResponse = res => {
  if ((res.status && res.status === 504) || (res.status && res.status === 404) || (res.status && res.status === 400)) {
    return false;
  }
  return true;
};

function* toggleMFA(action) {
  const { mfaEnabled, phoneConfirmed } = action.payload;

  try {
    yield put(actions.userSetMfaStatus(mfaEnabled))
    const cognitoUser = yield call(getcurrentAuthenticatedUser)
    let MFAType = "NOMFA"

    //Make sure session is renewed
    yield call(getCurrentSession)

    if (!phoneConfirmed) {

    }

    if (mfaEnabled) {
      MFAType = "SMS"
    }

    console.log(mfaEnabled, MFAType)
    yield call(setMFA, MFAType)
    yield put(actions.userSetMfaStatus(mfaEnabled))
  }
  catch (error) {
    yield put(actions.userSetMfaStatus(!mfaEnabled))
    // we should handle this error
    console.log('error enabling mfa ', error)
  }
}

function* changePassword(action) {
  const { password, verificationCode } = action.payload;
  const validPassword = password && password.length > 8;
  const validVerificationCode = verificationCode && verificationCode.length > 2

  if (!validVerificationCode) {
    yield put(actions.userProfilePasswordErrorMessage("The verification code is required!"))
    return
  }

  if (!validPassword) {
    yield put(actions.userProfilePasswordErrorMessage("The password is required"))
    return
  }

  try {
    yield put(actions.userProfileUpdateSubmitted())
    const cognitoUser = yield call(getcurrentAuthenticatedUser)
    yield call(getCurrentSession)
    const res = yield call(forgotPassword, { username: cognitoUser.username, code: verificationCode, password: password })
    console.log(res)
    yield put(actions.userProfileAttributeChangeSuccess())
    //yield put(actions.userProfileCloseAttributeModal())
    const toastId = uniqueId('userVerify');
    yield put(actions.finalMessage(toastId, "Email verified successfully.", "success"))
    yield delay(2500);
    yield put(actions.toastClose(toastId))
    console.log(cognitoUser)

  } catch (error) {
    const { message } = error;
    yield put(actions.userProfilePasswordErrorMessage(message))
  }
}

export function* verifyPhoneNumber(action) {
  const { verificationCode } = action.payload
  const api = yield getContext("api");

  const validVerificationCode = verificationCode && verificationCode.length > 2

  if (!validVerificationCode) {
    yield put(actions.userProfilePasswordErrorMessage("The verification code is required!"))
    return
  }

  try {
    yield put(actions.userProfileUpdateSubmitted())
    const cognitoUser = yield call(getcurrentAuthenticatedUser)
    yield call(getCurrentSession)
    //console.log(cognitoUser, verificationCode)
    const res = yield call(verifyUserAttribute, { user: cognitoUser, type: 'phone_number', code: verificationCode })

    if (res === 'SUCCESS') {
      const verifiedUser = yield call(getcurrentAuthenticatedUser)
      const user = yield call(api.get, "customer/GetCustomer");

      if (resolveApiResponse(user)) {
        yield put(actions.userProfileReceiveCurrentUser(user, verifiedUser))
        yield put(actions.userAttributesReceive(verifiedUser.attributes))
        yield put(actions.userProfileCloseAttributeModal())
        const toastId = uniqueId('userVerify');
        yield put(actions.finalMessage(toastId, "Phone verified successfully.", "success"))
        yield delay(2500);
        yield put(actions.toastClose(toastId))
        console.log(user, verifiedUser)
      }
      else {
        //We did not get a user..???
        console.log(user)
      }
    }

    else {
      //error messages can be wrong verification code, we can before hand validate that it holds 6 digits
      //if user haven received it , the phone-number mkight not be correct, change it here ?
    }
  } catch (error) {
    console.log('error ', error);
  }
}

export function* userProfileSagas() {
  yield takeLatest(actions.USER_PROFILE_SUBMIT, userProfileSubmit);
  yield takeLatest(actions.USER_PROFILE_GET_STUDY_INVITATIONS, getStudyInvites);
  yield takeLatest(actions.USER_PROFILE_RESOLVE_INVITATION, resolveInvite);
  yield takeLatest(actions.USER_TOGGLE_MFA, toggleMFA);
  yield takeLatest(actions.USER_PROFILE_VERIFY_PHONE_NUMBER, verifyPhoneNumber);
  yield takeLatest(actions.USER_PROFILE_CHANGE_PASSWORD, changePassword);
}


