import { viewModes } from "../constants";
import { getStudyFileStatusByState } from "../utils/studyFileHelpers";
import {
  getPermissionRequestItem,
  setPermissionRequest
} from "../utils/permissionRequstsHelper";
import { formatDateTime, timeDifference } from "../utils/date";
import * as actions from "../actions";
import { resolveTableSort, resolveFilter /*, resolveSpecialFilter*/, resolvePayerFilter, resatPayerFilter, resolvePastFilter, resolveNotification, resolveRadioFilter } from '../utils/dataSorting'
import { timeDifferenceTwoDates } from "../utils/date"
import { combineReducers } from '@reduxjs/toolkit';
import { root as createStudy } from "./createStudy";
import { root as userProfile } from "./userProfile";
import { root as studyInvite } from "./studyInvite";
import { root as permissions } from "./adminPermissions";
import { root as issues } from "./issues";
import { root as toasts } from "./toasts";
import { root as fileVersions } from "./fileVersions";
import { root as fileReview } from "./fileReview";
import { root as sasJob } from "./sasJob";

export const initialState = {
  isAuthenticated: false,
  /*userAuthenticated: false,*/
  authenticating: true,
  /*authenticating: false,*/
  fetching: false,
  specs: undefined,
  studies: [],
  studiesLoaded: false,
  /*sasJobs: [],*/
  studyDetail: undefined /* object with id, permissions, studypath, files*/,
  userId: undefined,
  isIdle: false,
  cognitoUser: undefined,
  showOnboarding: false,
  finishedGettingUser: false
};

export function root(state = initialState, action) {
  switch (action.type) {
    case actions.TEST_API:
      return { ...state, fetching: true, error: null };
    case actions.SPECS_RECEIVED:
      return { ...state, fetching: true, specs: action.payload.res };
    case actions.GET_STUDIES: {
      return { ...state, fetching: true };
    }
    case actions.SHOW_IDLE_MODAL: {
      return { ...state, isIdle: action.payload.state };
    }

    case actions.SHOW_ONBOARDING: {
      return {
        ...state,
        showOnboarding: true
      };
    }
    case actions.HIDE_ONBOARDING: {
      return {
        ...state,
        showOnboarding: false
      };
    }

    case actions.USER_PROFILE_RECEIVE_CURRENT_USER: {
      const { customerId } = action.payload.user;
      return {
        ...state,
        userId: customerId
      };
    }

    case actions.STUDY_FILE_STATUS_CHANGE: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true
        }
      };
    }

    case actions.STUDY_FILE_STATUS_CHANGED: {

      const { id, value } = action.payload;

      let currentdownloadFiles = (state.studyDetail.downloadFiles) ? state.studyDetail.downloadFiles : []

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          downloadFiles: currentdownloadFiles.map((file, i) => file.id === id ? { ...file, status: value } : file),
          loading: false
        }
      }
    }

    case actions.STUDY_FILE_TYPE_CHANGE: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true
        }
      };
    }
    case actions.STUDY_TABLE_SORT: {

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          tableSort: resolveTableSort(state.studyDetail.tableSort, action.payload.prop)
        }
      };
    }
    case actions.STUDY_SAS_TABLE_SORT: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasTableSort: resolveTableSort(state.studyDetail.sasTableSort, action.payload.prop)
        }
      };
    }
    case actions.RESET_FILTERS: {

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          fileTypefilter: [],
          filter: [],
          past: [],
          sasfilter: {
            ...state.sasfilter,
            folders: [],
            runStatus: [],
            qcStatus: [],
            sasRunStatus: [],
          }
        }
      }
    }
    case actions.STUDY_UPLOADS_RECEIVED: {
      const { files, fileType } = action.payload;

      const uploadedFiles = files.map(item => {
        return {
          id: item.studyFileId,
          fileName: item.fileName,
          folder: item.folder,
          displayDate: formatDateTime(item.uploadDateTime),
          created: item.uploadDateTime,/*formatDateTime(item.uploadDateTime),*/
          version: item.version,
          checkBox: 0,
          download: 0,
          delete: 0,
          s3BucketName: item.s3BucketName,
          s3Key: item.s3Key,
          status: getStudyFileStatusByState(item),
          sasJobId: (item.sasJobId) ? item.sasJobId : null
        };
      });

      let currentdownloadFiles = (state.studyDetail.downloadFiles) ? state.studyDetail.downloadFiles : [];
      let currentuploadedFiles = (state.studyDetail.uploadedFiles) ? state.studyDetail.uploadedFiles : [];

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: false,
          cnt: 0,
          allSelected: false,
          downloadFiles: (fileType.toUpperCase() === "UPLOAD") ? currentdownloadFiles : uploadedFiles,
          uploadedFiles: (fileType.toUpperCase() === "UPLOAD") ? uploadedFiles : currentuploadedFiles,
          tableSort: ['created', 'asc']
        }
      };
    }

    case actions.GET_STUDY_FILES_FOLDERS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true
        }
      };
    }

    case actions.GET_SASJOB_FILES_FOLDERS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true
        }
      };
    }

    case actions.STUDY_FILE_FOLDERS_RECEIVED: {
      const { folders, fileType } = action.payload;

      let folderToUpdate = fileType + "Folders"


      //let currentFolders = (state.studyDetail[folderToUpdate]) ? state.studyDetail[folderToUpdate] : [];
      let downloadReceivedDate = (state.studyDetail.downloadReceivedDate) ? state.studyDetail.downloadReceivedDate : null
      let uploadReceivedDate = (state.studyDetail.uploadReceivedDate) ? state.studyDetail.uploadReceivedDate : null
      let releaseReceivedDate = (state.studyDetail.releaseReceivedDate) ? state.studyDetail.releaseReceivedDate : null

      let newVisualFolders = []
      let visualFolders = []
      if (state.studyDetail.dashboardPermissions) {
        if (state.studyDetail.dashboardPermissions.filter(perm => perm.selected === false && perm.permission.toUpperCase() === "SASUPLOAD").length > 0) {
          visualFolders = folders.filter(folder => folder.folder.toUpperCase() !== "CONFIGURATION" && folder.folder.toUpperCase() !== "PROGRAMS")
        }
        else {
          visualFolders = folders
        }
      }
      else {
        visualFolders = folders.filter(folder => folder.folder.toUpperCase() !== "CONFIGURATION" && folder.folder.toUpperCase() !== "PROGRAMS")
      }

      if (state.studyDetail.configuration) {
        const configurationDetails = (state.studyDetail.configuration[fileType]) ? state.studyDetail.configuration[fileType] : []
        newVisualFolders = visualFolders.map(folder => {
          const ConfFolder = configurationDetails.filter(f => f.id === folder.folder)
          if (ConfFolder.length > 0) {
            return { ...folder, description: ConfFolder[0].description }
          }
          return { ...folder }
        })
      }
      else {
        newVisualFolders = visualFolders
      }


      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: false,
          folderLoading: false,
          [folderToUpdate]: newVisualFolders,
          downloadReceivedDate: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "RELEASE") ? downloadReceivedDate : new Date().toISOString() /*new Date().toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/,
          uploadReceivedDate: (fileType.toUpperCase() === "UPLOAD") ? new Date().toISOString()/*.toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/ : uploadReceivedDate,
          releaseReceivedDate: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "DOWNLOAD") ? releaseReceivedDate : new Date().toISOString() /*new Date().toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/,
        }
      };
    }

    case actions.STUDY_FILE_FOLDER_RECEIVED: {
      const { folder, fileType, selectedFolder } = action.payload;

      let folderToUpdate = fileType + "Folders"

      let currentFolders = (state.studyDetail[folderToUpdate]) ? state.studyDetail[folderToUpdate] : [];

      let visualFolder
      if (state.studyDetail.configuration) {
        const configurationDetails = (state.studyDetail.configuration[fileType]) ? state.studyDetail.configuration[fileType] : []
        visualFolder = folder.map(folder => {
          const ConfFolder = configurationDetails.filter(f => f.id === folder.folder)
          if (ConfFolder.length > 0) {
            return { ...folder, description: ConfFolder[0].description }
          }
          return { ...folder }
        })
      }
      else {
        visualFolder = folder
      }

      let newFolders = (visualFolder.length === 0) ? currentFolders.filter(o => o.folder !== selectedFolder) :
        currentFolders.map(obj => visualFolder.find(o => o.folder === obj.folder) || obj)

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: false,
          folderLoading: false,
          [folderToUpdate]: newFolders
        }
      };
    }

    case actions.FILES_CHANGED_AFTER_DATE_FINISHED: {
      const { changes, fileType } = action.payload;
      let downloadReceivedDate = (state.studyDetail.downloadReceivedDate) ? state.studyDetail.downloadReceivedDate : null
      let uploadReceivedDate = (state.studyDetail.uploadReceivedDate) ? state.studyDetail.uploadReceivedDate : null
      let releaseReceivedDate = (state.studyDetail.releaseReceivedDate) ? state.studyDetail.releaseReceivedDate : null

      //No changes to any folders - just update the receivedDate
      if (changes.length === 0) {
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            loading: false,
            downloadReceivedDate: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "RELEASE") ? downloadReceivedDate : new Date().toISOString() /*new Date().toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/,
            uploadReceivedDate: (fileType.toUpperCase() === "UPLOAD") ? new Date().toISOString()/*.toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/ : uploadReceivedDate,
            releaseReceivedDate: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "DOWNLOAD") ? releaseReceivedDate : new Date().toISOString() /*new Date().toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/,
          }
        }
      }
      const changesAmmended = changes.map(folder => {
        return { ...folder, needsUpdate: true, fileCount: 0 }
      })

      let currentdownloadFolders = (state.studyDetail.downloadFolders) ? state.studyDetail.downloadFolders : [];
      let currentuploadedFolders = (state.studyDetail.uploadedFolders) ? state.studyDetail.uploadedFolders : [];
      let currentreleaseFolders = (state.studyDetail.releaseFolders) ? state.studyDetail.releaseFolders : [];

      //let result = []
      if (fileType.toUpperCase() === "UPLOAD") {
        //First run-through currentfolders and update them with needsUpdate        
        var newUploadFolders = currentuploadedFolders.map(folder => {
          if (changesAmmended.filter(f => f.folder === folder.folder).length > 0) {
            return { ...folder, needsUpdate: true }
          }
          return { ...folder }
        })
      }
      else if (fileType.toUpperCase() === "DOWNLOAD") {
        //First run-through currentfolders and update them with needsUpdate        
        var newDownloadFolders = currentdownloadFolders.map(folder => {
          if (changesAmmended.filter(f => f.folder === folder.folder).length > 0) {
            return { ...folder, needsUpdate: true }
          }
          return { ...folder }
        })
      }
      else if (fileType.toUpperCase() === "RELEASE") {
        var newReleaseFolders = currentreleaseFolders.map(folder => {
          if (changesAmmended.filter(f => f.folder === folder.folder).length > 0) {
            return { ...folder, needsUpdate: true }
          }
          return { ...folder }
        })
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: false,
          folderLoading: false,
          downloadFolders: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "RELEASE") ? currentdownloadFolders : /*result*/ newDownloadFolders,
          uploadedFolders: (fileType.toUpperCase() === "UPLOAD") ? /*result*/ newUploadFolders : currentuploadedFolders,
          releaseFolders: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "DOWNLOAD") ? currentreleaseFolders : /*result*/ newReleaseFolders,
          downloadReceivedDate: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "RELEASE") ? downloadReceivedDate : new Date().toISOString() /*new Date().toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/,
          uploadReceivedDate: (fileType.toUpperCase() === "UPLOAD") ? new Date().toISOString() /*.toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/ : uploadReceivedDate,
          releaseReceivedDate: (fileType.toUpperCase() === "UPLOAD" || fileType.toUpperCase() === "DOWNLOAD") ? releaseReceivedDate : new Date().toISOString() /*new Date().toLocaleString(undefined, { timeZone: "Europe/Dublin" })*/,
        }
      };
    }

    case actions.CLEAR_POTENTIAL_UPLOADS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          potentialUploads: [],
          potentialUploadsWithStatus: []
        }
      }
    }

    case actions.STORE_POTENTIAL_UPLOADS: {
      const { files } = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          potentialUploads: files,
        }
      }
    }
    case actions.POTENTIAL_UPLOAD_STATUS_DETERMINED: {
      const { upload } = action.payload;
      let potentialUploadsWithStatus = (state.studyDetail.potentialUploadsWithStatus) ? state.studyDetail.potentialUploadsWithStatus : [];
      potentialUploadsWithStatus = [...potentialUploadsWithStatus, upload];

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          potentialUploadsWithStatus: potentialUploadsWithStatus,
        }
      }
    }

    case actions.UPDATE_UPLOAD_STATUS_ON_UPLOAD: {
      const { status, upload } = action.payload;

      const newPotentialUploadsWithStatus = state.studyDetail.potentialUploadsWithStatus.map(p =>
        p.id === upload.id && p.folder === upload.folder
          ? { ...p, loading: status }
          : p
      );
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          potentialUploadsWithStatus: newPotentialUploadsWithStatus,
        }
      }
    }

    case actions.EDIT_FOLDER_ON_POTENTIAL_UPLOAD: {
      const { id, value } = action.payload;

      const newPotentialUploadsWithStatus = state.studyDetail.potentialUploadsWithStatus.map(p =>
        p.id === id
          ? { ...p, folder: value }
          : p
      );

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          potentialUploadsWithStatus: newPotentialUploadsWithStatus,
        }
      }
    }



    case actions.UPDATE_UPLOAD_PROGRESS_ON_UPLOAD: {
      const { progress, upload } = action.payload;

      const newPotentialUploadsWithStatus = state.studyDetail.potentialUploadsWithStatus.map(p =>
        p.id === upload.id && p.folder === upload.folder
          ? { ...p, progress: Math.round((progress.loaded / progress.total) * 100) }
          : p
      );
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          potentialUploadsWithStatus: newPotentialUploadsWithStatus,
        }
      }
    }


    case actions.SAS_FILES_CHANGED_AFTER_DATE_FINISHED: {
      const { changes } = action.payload;

      //No changes to any folders - just update the receivedDate
      if (changes.length === 0) {
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            loading: false,
            sasReceivedDate: new Date().toISOString()
          }
        }
      }

      const changesAmmended = changes.map(folder => {
        return { ...folder, needsUpdate: true, fileCount: 0 }
      })

      let currentsasFolders = (state.studyDetail.sasFolders) ? state.studyDetail.sasFolders : [];

      let result = []
      var newSasFolders = currentsasFolders.map(folder => {
        if (changesAmmended.filter(f => f.folder === folder.folder).length > 0) {
          return { ...folder, needsUpdate: true }
        }
        return { ...folder }
      })

      //Next add any folder which is only in changes
      result = newSasFolders.concat(changesAmmended.filter(bo => newSasFolders.every(ao => ao.folder !== bo.folder)));

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: false,
          folderLoading: false,
          sasFolders: result,
          sasReceivedDate: new Date().toISOString()
        }
      };
    }

    case actions.STUDY_UPLOADS_RECEIVED_PAGING: {
      const { paginatedResult, fileType } = action.payload;


      const filesToUpdate = fileType + "Files"
      const MetadataToUpdate = fileType + "Metadata"

      const loadedFiles = paginatedResult.data.map(item => {
        if (state.studyDetail.configuration) {
          const configurationDetails = (state.studyDetail.configuration[fileType]) ? state.studyDetail.configuration[fileType] : []
          const confFolder = configurationDetails.filter(f => f.id.toUpperCase() === item.folder.toUpperCase())
          const confFile = (confFolder.length > 0 && confFolder[0].files) ? confFolder[0].files : []
          const fileDesc = confFile.filter(file => file.id.toUpperCase() === item.fileName.toUpperCase())
          console.log()

          return {
            id: item.studyFileId,
            fileName: item.fileName,
            folder: item.folder,
            displayDate: timeDifference(item.uploadDateTime),
            created: item.uploadDateTime,
            checkBox: 0,
            md5: item.mD5,
            download: 0,
            delete: 0,
            status: getStudyFileStatusByState(item),
            sasJobId: (item.sasJobId) ? item.sasJobId : null,
            description: (fileDesc.length > 0) ? fileDesc[0].description : ""
          };
        }
        else {
          return {
            id: item.studyFileId,
            fileName: item.fileName,
            folder: item.folder,
            displayDate: timeDifference(item.uploadDateTime),
            created: item.uploadDateTime,
            checkBox: 0,
            md5: item.mD5,
            download: 0,
            delete: 0,
            status: getStudyFileStatusByState(item),
            sasJobId: (item.sasJobId) ? item.sasJobId : null,
            description: ""
          };
        }
      });

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          folderLoading: true,
          cnt: 0,
          allSelected: false,
          [filesToUpdate]: loadedFiles,
          [MetadataToUpdate]: paginatedResult.metadata,
          tableSort: ['created', 'asc']
        }
      };
    }

    case actions.STUDY_RESET_STUDY_DETAIL: {
      console.log("resetting study detail");
      return {
        ...state,
        selectedStudy: -1,
        studyDetail: undefined
      };
    }

    case actions.GET_STUDY_PATH: {
      // resolve permissions
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true
        }
      };
    }
    case actions.LATEST_UPDATE_DATE_RECEIVED: {
      const { datetime, fileType } = action.payload;

      let maxDate;

      if (fileType.toUpperCase() === "UPLOAD") {
        maxDate = state.studyDetail.uploadReceivedDate;
      }
      else if (fileType.toUpperCase() === "DOWNLOAD") {
        maxDate = state.studyDetail.downloadReceivedDate;
      }
      else if (fileType.toUpperCase() === "RELEASE") {
        maxDate = state.studyDetail.releaseReceivedDate;
      }

      let latestRefreshdate = new Date(datetime)

      const currentUploadRefreshRequired = (state.studyDetail.uploadRefreshRequired) ? state.studyDetail.uploadRefreshRequired : false
      const currentDownloadRefreshRequired = (state.studyDetail.downloadRefreshRequired) ? state.studyDetail.downloadRefreshRequired : false
      const currentReleaseRefreshRequired = (state.studyDetail.releaseRefreshRequired) ? state.studyDetail.releaseRefreshRequired : false

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploadRefreshRequired: (maxDate < latestRefreshdate && fileType.toUpperCase() === "UPLOAD") ? true : currentUploadRefreshRequired,
          downloadRefreshRequired: (maxDate < latestRefreshdate && fileType.toUpperCase() === "DOWNLOAD") ? true : currentDownloadRefreshRequired,
          releaseRefreshRequired: (maxDate < latestRefreshdate && fileType.toUpperCase() === "RELEASE") ? true : currentReleaseRefreshRequired,
          loading: false
        }
      }
    }

    case actions.LATEST_SAS_UPDATE_DATE_RECEIVED: {
      const { datetime } = action.payload;


      //No sasjobs exists on study
      if (datetime === "") {
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            loading: false
          }
        }
      }

      const maxDate = state.studyDetail.sasReceivedDate;
      let latestRefreshdate = new Date(datetime)

      const currentSasRefreshRequired = (state.studyDetail.sasRefreshRequired) ? state.studyDetail.sasRefreshRequired : false

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasRefreshRequired: (maxDate < latestRefreshdate) ? true : currentSasRefreshRequired,
          loading: false
        }
      }
    }

    case actions.EMPTY_STUDY_FILE_FOLDERS: {
      const { fileType } = action.payload;

      let filesToUpdate = fileType + "Files"
      let folderToUpdate = fileType + "Folders"
      //let metadataToUpdate = fileType + "Metadata"
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true,
          folderLoading: true,
          cnt: 0,
          allSelected: false,
          [filesToUpdate]: [],
          [folderToUpdate]: [],
        }
      };
    }
    case actions.EMPTY_SASJOB_FILE_FOLDERS: {

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          folderLoading: true,
          cnt: 0,
          /*allSelected: false,*/
          allSASSelected: false,
          sasFolders: [],
          //sasJobs: []
        }
      };
    }
    case actions.SET_SAS_JOB_FOLDER: {
      const { folders } = action.payload;

      let cnt = 0
      let currentSasJobs = (state.studyDetail.sasjobsFiles) ? state.studyDetail.sasjobsFiles : []
      let transformedFiles = currentSasJobs.map(item => { return { ...item, checkBox: 0 } });

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          selectedSasJobfolders: folders,
          folderLoading: false,
          sasjobsFiles: transformedFiles,
          cnt: cnt,
          allSelected: cnt === transformedFiles.length
          /*loading: false*/
        }
      };
    }
    case actions.SET_STUDY_FILE_FOLDER: {

      const { folders } = action.payload;
      let cnt = 0;
      const viewMode = state.studyDetail.viewMode;

      const type = viewMode + "Files";

      let currentFiles = (state.studyDetail[type]) ? state.studyDetail[type] : [];

      let transformedFiles = currentFiles.map(item => { return { ...item, checkBox: 0 } });

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          selectedUploadDownloadfolder: folders,
          [type]: transformedFiles,
          cnt: cnt,
          allSelected: cnt === transformedFiles.length,
          folderLoading: false,
        }
      };
    }

    case actions.GET_STUDY_PATH_PAGING_FINISHED: {

      const fileType = state.studyDetail.viewMode + "Files"
      const { folder } = action.payload;

      //const payerFile = state.studyDetail.downloadFiles.filter(item => item.name.toUpperCase() === "PAYER.JSON")[0];
      //const payerStudy = state.studyDetail.downloadFiles.filter(item => item.name.toUpperCase() === "PAYER.JSON").length > 0

      const currentFolders = (state.studyDetail[fileType]) ? state.studyDetail[fileType] : []
      const transformedFolders = currentFolders.map(item => {
        if (folder && item.folder.toUpperCase() === folder.toUpperCase()) {
          return { ...item, updatedDate: new Date() }
        }
        else {
          return { ...item }
        }
      })

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          folderLoading: false,
          /*payerStudy: payerStudy,
          payerFile: payerFile,*/
          [fileType]: transformedFolders,
        }
      };
    }

    case actions.GET_SASJOBS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          viewMode: viewModes.SASJOBS,
          loading: true
        }
      };
    }
    case actions.SASJOBS_GET_PAGING: {
      const { folders } = action.payload;


      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          viewMode: viewModes.SASJOBS,
          sasjobsfolders: folders,
          folderLoading: true,
          loading: false
        }
      };
    }

    case actions.SASJOBS_GET_FOLDERS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          allSASSelected: false,
          viewMode: viewModes.SASJOBS,
          loading: true
        }
      };
    }

    case actions.SASJOBS_GET_FILES: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          allSASSelected: false,
          loading: true
        }
      };
    }

    case actions.SASJOBS_FOLDERS_RECEIVED: {
      const { res } = action.payload;
      const fileType = state.studyDetail.viewMode + "Files"

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          viewMode: viewModes.SASJOBS,
          sasFolders: res,
          //selectedSasJobfolders: [],
          allSASSelected: false,
          //[fileType]: [],
          sasReceivedDate: new Date().toISOString(),
          loading: false,
          folderLoading: false
        }
      };
    }

    case actions.SASJOBS_FILES_RECEIVED: {
      const { res } = action.payload;
      const fileType = state.studyDetail.viewMode + "Files"

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          [fileType]: res,
          loading: false
        }
      };
    }

    case actions.STUDY_FILE_TOGGLED: {
      // make generic to also work for downloads
      const { id } = action.payload;
      const fileType = state.studyDetail.viewMode + "Files"

      let cnt = 0;
      const currentdownloadFiles = (state.studyDetail[fileType]) ? state.studyDetail[fileType] : [];
      const transformedFiles = currentdownloadFiles.map(
        item => {
          let base = { ...item };
          if (item.id === id) {
            if (item.checkBox === 1) {
              base.checkBox = 0;
            } else {
              base.checkBox = 1;
            }
          }
          if (base.checkBox === 1) {
            cnt++;
          }
          return base;
        }
      );
      // on route change reset selectedDate to new Date
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          [fileType]: transformedFiles,
          cnt: cnt,
          allSelected: cnt === transformedFiles.length
        }
      };
    }

    case actions.SAS_JOB_TOGGLED: {
      const { id } = action.payload;
      let cnt = 0;

      const viewMode = state.studyDetail.viewMode;
      let transformedFiles = [];
      if (viewMode === "sasjobs") {
        transformedFiles = state.studyDetail.sasJobs.map(
          item => {
            let base = { ...item };
            if (item.sasJobId === id) {
              if (item.checkBox === 1) {
                base.checkBox = 0;
              } else {
                base.checkBox = 1;
              }
            }

            if (base.checkBox === 1) {
              cnt++;
            }
            return base;
          }
        );
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasJobs: transformedFiles,
          sascnt: transformedFiles.filter(t => t.checkBox === 1).length,
          allSASSelected: cnt === transformedFiles.length
        }
      };
    }

    case actions.EXECUTION_FOLDERS_SET: {
      const { items, type } = action.payload;

      if (type.toUpperCase() === "FILES") {

        const executionFiles = items.map(group => {
          return group.map(item => {
            return { ...item, id: item.file }
          })
        });

        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            sasExecutionFiles: executionFiles
          }
        };
      }
      else {

        const executionFolders = items.map(group => {
          return group.map(item => {
            return { ...item, id: item.folder }
          })
        });


        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            sasExecutionFolders: executionFolders
          }
        };
      }
    }

    case actions.EXECUTION_FILES_SET: {
      const { items /*, type*/ } = action.payload;

      const executionFiles = items.map(group => {
        return group.map(item => {
          return { ...item, id: item.file }
        })
      });

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasExecutionFiles: executionFiles
        }
      };
    }

    case actions.SET_EXECUTION_GROUP: {

      const { type } = action.payload;

      if (type.toUpperCase() === "FILES") {

        const sasExecutionFiles = state.studyDetail.sasExecutionFiles
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            sasExecutionFiles: [...sasExecutionFiles, []]
          }
        }
      }

      else {

        const sasExecutionFolders = state.studyDetail.sasExecutionFolders

        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            sasExecutionFolders: [...sasExecutionFolders, []]
          }
        };
      }
    }

    case actions.VALIDATE_EXECUTION_FOLDERS_RECEIVED: {

      const { sasExecutionFolders, resTopologicSort } = action.payload;

      let validationIssues = []

      for (let i = 0; i < sasExecutionFolders.length; i++) {
        const executionFolders = sasExecutionFolders[i];
        for (const executionFolder of executionFolders) {
          for (let j = 0; j <= i; j++) {
            const auto = sasExecutionFolders[j]
            for (const f of auto) {
              if (f.folder === executionFolder.folder) { continue; }
              if (resTopologicSort.graph[executionFolder.folder]?.indexOf(f.folder) > -1) {
                console.log(executionFolder.folder, " must run before ", f.folder, i, j);
                const validationIssue = { "folder": executionFolder.folder, "msg": `${executionFolder.folder} must run before ${f.folder}` }
                validationIssues.push(validationIssue)
              }
            }
          }
        }
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          validationIssues: validationIssues,
          hasCycle: resTopologicSort.hasCycle
        }
      }
    }
    case actions.VALIDATE_EXECUTION_FILES_RECEIVED: {

      const { sasExecutionFiles, resTopologicSort } = action.payload;

      let validationIssues = []

      for (let i = 0; i < sasExecutionFiles.length; i++) {
        const executionFiles = sasExecutionFiles[i];
        for (const executionFile of executionFiles) {
          for (let j = 0; j <= i; j++) {
            const auto = sasExecutionFiles[j]
            for (const f of auto) {
              if (f.file === executionFiles.file) { continue; }
              if (resTopologicSort.graph[executionFile.file]?.indexOf(f.file) > -1) {
                console.log(executionFile.file, " must run before ", f.file, i, j);
                const validationIssue = { "file": executionFile.file, "msg": `${executionFile.file} must run before ${f.file}` }
                validationIssues.push(validationIssue)
              }
            }
          }
        }
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          validationIssues: validationIssues,
          hasCycle: resTopologicSort.hasCycle
        }
      }
    }
    case actions.GET_EXECUTION_FILES_INITIATED: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true,

        }
      }
    }
    case actions.GET_EXECUTION_FILES: {
      const { folder } = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          loading: true,
          sasjobsfolder: folder,
          /*executionFiles: [],
          sasExecutionFiles: [[]]*/
        }
      }
    }

    case actions.EXECUTION_FOLDERS_RECEIVED: {
      const { folders } = action.payload;

      let firstIndex = 0
      let groupedFoldersArray = []
      const sorted = folders.filter(item => item.order !== null).sort((a, b) => a.order - b.order);

      sorted.map((item, index) => {
        if (sorted[index - 1]) {
          if (sorted[index - 1].order === item.order) {
            groupedFoldersArray[firstIndex].push(item)
          }
          else {
            firstIndex = firstIndex + 1
            groupedFoldersArray.push([])
            groupedFoldersArray[firstIndex].push(item)
          }
        }
        else {
          groupedFoldersArray.push([])
          groupedFoldersArray[firstIndex].push(item)
        }
        return true
      })

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasExecutionFolders: (groupedFoldersArray.length === 0) ? [[]] : groupedFoldersArray,
          loading: false
        }
      }
    }

    case actions.UPDATED_EXECUTION_ORDER_RECEIVED: {
      const { rowsUpdated } = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          rowsUpdated: rowsUpdated
        }
      }
    }

    case actions.EXECUTION_FILES_RECEIVED: {
      const { files } = action.payload;

      let firstIndex = 0
      let groupedFilesArray = []
      const sorted = files?.sort((a, b) => a.order - b.order) ?? [];
      sorted.map((item, index) => {
        if (sorted[index - 1]) {
          if (sorted[index - 1].order === item.order) {
            groupedFilesArray[firstIndex].push(item)
          }
          else {
            firstIndex = firstIndex + 1
            groupedFilesArray.push([])
            groupedFilesArray[firstIndex].push(item)
          }
        }
        else {
          groupedFilesArray.push([])
          groupedFilesArray[firstIndex].push(item)
        }
        return true
      })

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasExecutionFiles: (groupedFilesArray.length === 0) ? [[]] : groupedFilesArray
        }
      }
    }

    case actions.STUDY_PAYER_JSON_RECEIVED: {
      const val = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          payerFilter: val
        }
      };
    }
    case actions.STUDY_CONFIG_JSON_RECEIVED: {
      const val = action.payload;

      let currentdownloadFolders = (state.studyDetail.downloadFolders) ? state.studyDetail.downloadFolders : [];
      let currentuploadedFolders = (state.studyDetail.uploadedFolders) ? state.studyDetail.uploadedFolders : [];

      if (currentdownloadFolders.length === 0 && currentuploadedFolders.length === 0) {
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            configuration: val
          }
        };
      }

      console.log(state.studyDetail)

      let visualFolders = []
      if (state.studyDetail.fileType.toUpperCase() === "UPLOAD") {
        visualFolders = currentuploadedFolders.map(folder => {
          const ConfFolder = val[state.studyDetail.fileType].filter(f => f.id === folder.folder)
          if (ConfFolder.length > 0) {
            return { ...folder, description: ConfFolder[0].description }
          }
          return { ...folder }
        })
      }

      else if (state.studyDetail.fileType.toUpperCase() === "DOWNLOAD") {
        visualFolders = currentdownloadFolders.map(folder => {
          const ConfFolder = val[state.studyDetail.fileType].filter(f => f.id === folder.folder)
          if (ConfFolder.length > 0) {

            return { ...folder, description: ConfFolder[0].description }
          }
          return { ...folder }
        })
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          configuration: val,
          downloadFolders: (state.studyDetail.fileType.toUpperCase() === "UPLOAD") ? currentdownloadFolders : visualFolders,
          uploadedFolders: (state.studyDetail.fileType.toUpperCase() === "UPLOAD") ? visualFolders : currentuploadedFolders,
        }
      };
    }

    case actions.STUDY_CONFIG_CSV_RECEIVED: {
      const val = action.payload;
      let refinedCsv = []
      let downloadFiles = state.studyDetail.downloadFiles;
      val.map(item => {
        var filename = item.inputPath.split("\\").pop().toUpperCase();
        let downloadItem = downloadFiles.filter((s) => s.name.toUpperCase() === filename)[0];
        if (downloadItem !== undefined) {

          refinedCsv.push({ ...item, "s3BucketName": downloadItem.s3BucketName, "s3Key": downloadItem.s3Key })
        }
        return true
      })



      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          filesToCollect: refinedCsv
        }
      };
    }

    case actions.PAYER_FILTER_CHANGED: {
      const { value, name } = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          payerFilter: {
            ...state.studyDetail.payerFilter,
            [name]: resolvePayerFilter(state.studyDetail.payerFilter[name], value)
          }
        }
      };
    }

    case actions.RESET_PAYER_FILTER: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          payerFilter: resatPayerFilter(state.studyDetail.payerFilter)
        }
      };
    }

    case actions.SAS_PRIORITY_CHANGE: {
      const { id, value } = action.payload;

      let transformedFiles = [];
      transformedFiles = state.studyDetail.sasJobs.map(
        item => {
          let base = { ...item };
          if (base.sasJobId === id) {
            base.sasJobPriority = value;
          }
          return base;
        }
      );

      return {
        ...state,
        sasJobs: transformedFiles,
        studyDetail: {
          ...state.studyDetail,
          sasJobs: transformedFiles
        }
      }
    }


    case actions.STUDY_CHANGE_FILTER_INIT: {
      const { val } = action.payload;
      let disable = false
      let currentFilter = state.studyDetail.filter
      if (currentFilter && currentFilter.length > 0) {
        if (currentFilter.includes(val)) {
          disable = true
        }
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          filter: (!disable) ? [val] : [] /*resolveFilter(state.studyDetail.filter, val)*/
        }
      };
    }

    case actions.STUDY_FILE_TYPE_FILTER: {
      const { val } = action.payload;

      const value = resolveFilter(state.studyDetail.fileTypefilter, val)

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          fileTypefilter: value
        }
      };
    }

    case actions.SAS_CHANGE_FILTER: {
      let { val, type } = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasfilter: {
            ...state.sasfilter,
            folders: (type.toUpperCase() === "FOLDERS") ? resolveRadioFilter(state.studyDetail.sasfilter.folders, val) : state.studyDetail.sasfilter.folders,
            runStatus: (type.toUpperCase() === "RUNSTATUS") ? resolveRadioFilter(state.studyDetail.sasfilter.runStatus, val) : state.studyDetail.sasfilter.runStatus,
            qcStatus: (type.toUpperCase() === "QCSTATUS") ? resolveRadioFilter(state.studyDetail.sasfilter.qcStatus, val) : state.studyDetail.sasfilter.qcStatus,
            sasRunStatus: (type.toUpperCase() === "SASRUNSTATUS") ? resolveRadioFilter(state.studyDetail.sasfilter.sasRunStatus, val) : state.studyDetail.sasfilter.sasRunStatus,
          }
        }
      };
    }

    case actions.UPLOAD_CHANGE_FILTER: {
      const { val } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploadfilter: resolveFilter(state.studyDetail.uploadfilter, val)
        }
      };
    }

    case actions.STUDY_PASTWEEKS_FILTER: {
      const { val } = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          past: resolvePastFilter(state.studyDetail.past, val)
        }
      };
    }
    case actions.STUDY_FILES_CHANGE_DATE: {
      const { date } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          selectedDate: date
        }
      };
    }
    case actions.STUDY_ALL_FILES_TOGGLED: {

      const { ids } = action.payload
      const fileType = state.studyDetail.viewMode + "Files";

      const resolveAllSelection = () => {
        if (state.studyDetail) {
          if (state.studyDetail.allSelected) {
            return false;
          }
          if (state.studyDetail.cnt === 0) {
            return true;
          }
        }
        return true;
      };

      const resolveSelection = (item) => {
        if (state.studyDetail) {
          if (state.studyDetail.allSelected) {
            return false;
          }
          if (item) {
            if (ids.includes(item.id)) {
              return true;
            }
          }
        }
        return false;
      };

      const currentFiles = (state.studyDetail[fileType]) ? state.studyDetail[fileType] : [];
      const transformedFiles = currentFiles.map(
        item => {
          const checkboxValue = resolveSelection(item)
          let base = { ...item, checkBox: checkboxValue ? 1 : 0 };
          return base;
        }
      );

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          [fileType]: transformedFiles,
          cnt: transformedFiles.filter(t => t.checkBox === 1).length,
          allSelected: resolveAllSelection()
        }
      };
    }

    case actions.STUDY_ALL_SAS_FILES_TOGGLED: {

      const { ids } = action.payload

      const resolveAllSelection = () => {
        if (state.studyDetail) {
          if (state.studyDetail.allSASSelected) {
            return false;
          }
          if (state.sascnt === 0) {
            return true;
          }
        }
        return true;
      };

      const resolveSelection = (item) => {
        if (state.studyDetail) {
          if (state.studyDetail.allSASSelected) {
            return false;
          }
          if (item) {
            if (ids.includes(item.sasJobId)) {
              return true;
            }
          }
        }
        return false;
      };

      const viewMode = state.studyDetail.viewMode;
      let transformedFiles = [];
      if (viewMode === "sasjobs") {
        transformedFiles = state.studyDetail.sasJobs.map(
          item => {
            const checkboxValue = resolveSelection(item)
            let base = { ...item, checkBox: checkboxValue ? 1 : 0 };
            return base;
          }
        );
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasJobs: transformedFiles,
          sascnt: transformedFiles.filter(t => t.checkBox === 1).length,
          allSASSelected: resolveAllSelection()
        }
      };
    }



    case actions.STUDIES_RECEIVED: {
      const { res } = action.payload;
      return {
        ...state,
        studiesLoaded: true,
        studies: res,
        studiesRefresh: false
      };
    }

    case actions.LOGIN_USER_API_FINISHED: {
      return {
        ...state,
        finishedGettingUser: true
      };
    }

    case actions.STUDIES_RECEIVED_V2: {
      const { res } = action.payload;

      //const currentStudies = (state.studies) ? state.studies : []
      const deepCopiedRes = JSON.parse(JSON.stringify(res));
      // Use deepCopiedRes in place of res for mapping

      const newStudies = deepCopiedRes.map(item => {
        const exist = state.studies.filter(s => s.studyId === item.studyId);
        /*if (exist.length > 0) {
          return { ...exist[0], lastModifiedDate: item.lastModifiedDate };
        }
        else {*/
        return { ...item }
        /*}*/
      })

      //sorting studies by lastModifiedDate
      newStudies.sort((b, a) => new Date(b.lastModifiedDate) - new Date(a.lastModifiedDate))

      return {
        ...state,
        studiesLoaded: true,
        studies: newStudies,
        studiesRefresh: false
      };
    }

    case actions.RECEIVED_QC_JOB: {
      const { qcJob } = action.payload;
      const newSasjobsFiles = state.studyDetail.sasjobsFiles.map(item => {
        if (item.folder === qcJob.folder.substring(0, qcJob.folder.length - 3) &&
          item.fileName === qcJob.fileName) {
          return { ...item, qcStatus: (qcJob.sasLogError) ? "Errors in QC program" : (qcJob.sasJobExecutedDate < item.sasJobExecutedDate) ? "Qc programs needs a re-run" : "Double programmed" };
        }
        else {
          return { ...item };
        }
      })

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasjobsFiles: newSasjobsFiles
        }
      }
    }

    case actions.RECEIVED_SIMPLE_STUDY: {
      const { study } = action.payload;

      const newStudies = state.studies.map(item => {
        if (item.studyId === study.studyId) {
          return { ...item, studyProject: study.studyProject }
        }
        return item;
      });

      return {
        ...state,
        studies: newStudies,
      };
    }

    case actions.STUDY_REQUEST_PERMISSION: {
      const { permission } = action.payload;
      setPermissionRequest(state.studyDetail.id, permission);
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          dashboardPermissions: state.studyDetail.dashboardPermissions.map(
            item => {
              let perm = { ...item };
              if (!perm.selected) {
                perm.requested = getPermissionRequestItem(
                  state.studyDetail.id,
                  perm.permission
                );
              }
              return perm;
            }
          )
        }
      };
    }
    case actions.FETCH_ACCESS_LOG_INFO: {
      return {
        ...state,
        accessLogInfo: {
          ...state.accessLogInfo,
          loading: true
        }
      };
    }
    case actions.ACCESS_LOG_INFO_RECEIVED: {
      const { metadata, studyId } = action.payload;
      return {
        ...state,
        accessLogInfo: {
          metadata,
          studyId,
          loading: false,
          s3keys: []
        }
      };
    }
    case actions.ACCESS_LOG_EXTRACT_RECEIVED: {
      const { s3key } = action.payload;
      return {
        ...state,
        accessLogInfo: {
          ...state.accessLogInfo,
          s3keys: [...state.accessLogInfo.s3keys, s3key]
        }
      };
    }

    case actions.STUDY_CHANGE_VIEW_TOGGLE_INIT: {
      const { value } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          isSasUserToggledOn: value,
        }
      };
    }

    case actions.STUDY_PERMISSIONS_FOR_DASHBOARD_RECEIVED: {
      const { permissions, studyId } = action.payload;

      const isSasUserToggledOn = permissions.some(perm => perm.permission === "SASUPLOAD" && perm.selected === true)

      if (state.studyDetail && state.studyDetail.id === parseInt(studyId)) {
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            isSasUserToggledOn: isSasUserToggledOn,
            dashboardPermissions: permissions.map(item => {
              let perm = { ...item };
              if (!perm.selected) {
                perm.requested = getPermissionRequestItem(
                  studyId,
                  perm.permission
                );
              }
              return perm;
            })
          }
        };
      }

      return state;
    }

    case actions.SAS_ISSUES_RECEIVED: {
      const { issues, studyId } = action.payload;
      if (state.studyDetail && state.studyDetail.id === studyId) {
        const sasIssues = issues.map(issue => ({ ...issue, issue: 'Error in program' }))

        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            sasIssues: sasIssues
          }
        };

      }
      return state;
    }

    case actions.ISSUES_FOR_DASHBOARD_RECEIVED: {
      const { issues, studyId } = action.payload;
      let uploadIssues = []

      if (state.studyDetail && state.studyDetail.id === parseInt(studyId)) {

        let finalIssues = []

        for (const [key, value] of Object.entries(issues.nextConflict)) {
          finalIssues.push({ folder: key, affected: [], corrected: [], issue: "Change folder name" })
          value.forEach(path => {
            const re = new RegExp(key, "i");
            const corrected = path.replace(re, key);
            finalIssues.filter(item => item.folder === key)[0].affected.push(path)
            finalIssues.filter(item => item.folder === key)[0].corrected.push(corrected)
          })
        }

        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            dashboardIssues: finalIssues
          }
        };
      }

      return state;
    }

    case actions.RECEIVED_ADMIN_ON_STUDY: {
      const { admins } = action.payload;
      const { users } = state.studyDetail
      const users_admin_on = users.map(user => {
        if (admins.includes(user.customerId)) {

          return { ...user, studyAdmin: true }
        }
        return { ...user, studyAdmin: false }
      })

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          users: users_admin_on
        }
      }
    }

    //case actions.SELECT_STUDY: {
    case actions.RECEIVED_STUDY: {
      const receievedStudy = action.payload.study;
      const currentSelectedStudy = state.selectedStudy;

      if (currentSelectedStudy === receievedStudy.studyId) {
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            fileType: action.payload.viewMode || viewModes.UPLOAD,
            viewMode: action.payload.viewMode || viewModes.UPLOAD
          }
        }
      }

      /*
      const transformedStudies = state.studies.map(item => {
        let study = { ...item };
        if (item.studyId === receievedStudy.studyId) {
          return { ...study, ...receievedStudy }
        }
        return study;
      });
      */

      // dont set all data apart from viewMode
      if (receievedStudy) {

        const {
          studyId,
          studyName,
          studyProject,
          isAdminFlag,
          /*permissions,*/
          created,
          users,
          sponsor
        } = receievedStudy;

        return {
          ...state,
          //studies: transformedStudies,
          selectedStudy: studyId,
          studyDetail: {
            filter: [],   /* reset filter on study change */
            sasfilter: {
              ...state.sasfilter,
              folders: [],
              runStatus: [],
              qcStatus: [],
              sasRunStatus: [],
            },
            past: [],
            allSASSelected: false,
            allSelected: false,
            uploadNotification: [],
            uploadRefreshRequired: false,
            downloadRefresRequired: false,
            releaseRefresRequired: false,
            fileType: action.payload.viewMode || viewModes.UPLOAD,
            viewMode: action.payload.viewMode || viewModes.UPLOAD,
            isAdminFlag,
            studyProject,
            id: studyId,
            createdDate: created,
            studyName,
            users,
            sponsor,
            study: receievedStudy
          }
        };
      } else {
        console.log('where is the study')
        // study not found
        return state;
      }
    }

    case actions.STUDY_PERMISSION_TOGGLED: {
      const { id } = action.payload;
      const transformedPermissions = state.studyDetail.dashboardPermissions.map(item => {
        let perm = { ...item };
        if (item.permission === id) {
          perm.selected = !perm.selected;
        }
        return perm;
      });
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          /*permissions: transformedPermissions*/
          /*teamPermissions: transformedPermissions*/
          dashboardPermissions: transformedPermissions
        }
      };
    }
    case actions.STUDY_SAVE_PERMISSIONS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          permissionsLoading: true
        }
      };
    }
    case actions.FETCH_STUDY_PERMISSIONS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          /*teamPermissions: [],*/
          recipientUserId: action.payload.customerId
        }
      };
    }

    case actions.STUDY_PERMISSIONS_CHANGED: {
      const { permissions, studyId } = action.payload;
      if (state.studyDetail && state.studyDetail.id === studyId) {

        /*const transformedStudies = state.studies.map(item => {
          let study = { ...item };
          if (item.studyId === state.studyDetail.id) {
            study.permissions = permissions
              .filter(item => item.selected)
              .map(perm => {
                return {
                  permissionId: perm.permission,
                  description: perm.description
                };
              });
          }
          return study;
        });*/
        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            dashboardPermissions: permissions,
            permissionsLoading: false,
            /*permissions: permissions*/
          }
        };
      }
      return state;
    }

    case actions.STUDY_FILE_UPLOADED: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploadSucces: true,
          uploading: false,
          uploadRefreshRequired: true
        }
      };
    }

    case actions.STUDY_FILE_UPLOADED_RESET: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploadSucces: false,
          uploading: false,
          uploadRefreshRequired: false,
          potentialUploads: [],
          potentialUploadsWithStatus: []
        }
      };
    }

    case actions.CLEAR_STUDIES: {
      return {
        ...state,
        studies: [],
        studiesLoaded: false,
        studyDetail: undefined,
      };
    }

    case actions.STUDY_FILE_ADD_UPLOAD_NOTIFICATION: {
      const { folder } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploadNotification: (state.studyDetail.viewMode !== folder) ? resolveNotification(state.studyDetail.uploadNotification, folder) : state.studyDetail.uploadNotification
        }
      };
    }

    case actions.STUDY_UPLOAD_FILES: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploading: true
        }
      };
    }
    case actions.STUDY_TOGGLE_FILE_TYPE: {

      console.log(state.studyDetail)
      const { fileType, selectedUploadDownloadfolder, selectedSasJobfolders } = state.studyDetail

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          fileType: action.payload.fileType,
          viewMode: action.payload.fileType,
          selectedUploadDownloadfolder: (action.payload.fileType === fileType) ? selectedUploadDownloadfolder : [],
          selectedSasJobfolders: (action.payload.fileType === fileType) ? selectedSasJobfolders : [],
          loading: true
        }
      };
    }
    case actions.STUDY_OPEN_UPLOAD_MODAL: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploadModal: true
        }
      };
    }
    case actions.STUDY_CLOSE_UPLOAD_MODAL: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploading: false,
          uploadModal: false,
          potentialUploads: [],
          potentialUploadsWithStatus: []
        }
      };
    }

    case actions.STUDY_PERMISSIONS_RECEIVED: {
      const { res } = action.payload;
      // consider moving to own permission reducer
      const { permissions, assignToUserId, assignToUserName, studyId } = res;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          id: studyId,
          /*permissions: permissions,*/
          dashboardPermissions: permissions,
          userId: assignToUserId,
          userName: assignToUserName,
          permissionsLoading: false,
        }
      };
    }

    case actions.SASJOBS_RECEIVED: {
      const { res } = action.payload;

      const jobsQced = res && res.length
        ? res.filter(r => r.folder.toUpperCase().replace(/\\/g, '/').endsWith("/QC")).map(item => { return item.folder.replace(/\\/g, '/').substring(0, item.folder.length - 3) + "/" + item.fileName })
        : []
        ;
      const jobsQcedNoErrors = res && res.length
        ? res.filter(r => r.folder.toUpperCase().replace(/\\/g, '/').endsWith("/QC") && r.sasLogError === 0).map(item => { return item.folder.replace(/\\/g, '/').substring(0, item.folder.length - 3) + "/" + item.fileName })
        : []

      const sasJobs = res.map(item => {
        return {
          ...item,
          checkBox: 0,
          status: (jobsQcedNoErrors.indexOf(item.folder.replace(/\\/g, '/') + "/" + item.fileName) > -1) ? "Passed" : (jobsQced.indexOf(item.folder.replace(/\\/g, '/') + "/" + item.fileName) > -1) ? "Failed" : "Not QCed"
        }
      })

      return {
        ...state,
        sasJobs: sasJobs,
        studyDetail: {
          ...state.studyDetail,
          sasJobs: sasJobs,
          loading: false
        }
      };
    }
    case actions.APPLY_SAS_REGEX: {
      const { value } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          sasjobsRegexExpression: value
        }
      }
    }
    case actions.APPLY_UPLOAD_REGEX: {
      const { value } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          uploadRegexExpression: value
        }
      }
    }
    case actions.APPLY_DOWNLOAD_REGEX: {
      const { value } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          downloadRegexExpression: value
        }
      }
    }

    case actions.APPLY_RELEASE_REGEX: {
      const { value } = action.payload;
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          releaseRegexExpression: value
        }
      }
    }
    case actions.START_REFRESH_FOLDER_FILES: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          folderLoading: true
        }
      };
    }

    case actions.SASJOBS_RESET_PAGING: {
      //const { folder } = action.payload;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          folderLoading: true
        }
      };
    }

    case actions.STUDY_FILE_RESET_PAGING: {

      const { fileType } = action.payload;


      let currentdownloadFiles = (state.studyDetail.downloadFiles) ? state.studyDetail.downloadFiles : [];
      let currentuploadedFiles = (state.studyDetail.uploadedFiles) ? state.studyDetail.uploadedFiles : [];


      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          downloadFiles: (fileType.toUpperCase() === "UPLOAD") ? currentdownloadFiles : [],
          uploadedFiles: (fileType.toUpperCase() === "UPLOAD") ? [] : currentuploadedFiles,
          folderLoading: true
        }
      };
    }

    case actions.CLEAR_SELECTED_FOLDERS: {
      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          selectedUploadDownloadfolder: [],
        }
      };
    }
    case actions.CLEAR_SELECTED_FOLDER_BEGIN: {
      const { folder } = action.payload;

      const currentFolders = (state.studyDetail.selectedUploadDownloadfolder) ? state.studyDetail.selectedUploadDownloadfolder : []
      const index = (currentFolders) ? currentFolders.indexOf(folder) : -1

      //if (index > -1) {
      //  currentFolders.splice(index, 1)
      //}
      // Create a new array excluding the element at `index`
      const newFolders = index > -1 ? [
        ...currentFolders.slice(0, index),
        ...currentFolders.slice(index + 1)
      ] : currentFolders;

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          selectedUploadDownloadfolder: newFolders,
        }
      };
    }

    case actions.CLEAR_SELECTED_SAS_FOLDER: {
      const { folder } = action.payload;

      const currentFolders = (state.studyDetail.selectedSasJobfolders) ? state.studyDetail.selectedSasJobfolders : []
      const index = (currentFolders) ? currentFolders.indexOf(folder) : -1

      if (index > -1) {
        currentFolders.splice(index, 1)
      }

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          selectedSasJobfolders: currentFolders,
        }
      };

    }


    case actions.STUDY_FILE_PLACE_COMPARER_JOB: {
      const { studyId, studyFile, fileContent } = action.payload;
      return {
        ...state,
        loading: false,
        studyDetail: {
          ...state.studyDetail,
          ComparerStudyId: studyId,
          compareFile: studyFile,
          Comparer: fileContent
        }
      };
    }

    case actions.STUDY_FILE_RESET: {
      return {
        ...state,
        loading: false,
        studyDetail: {
          ...state.studyDetail,
          fileVersions: [],
          file: undefined,
          fileContent: undefined
        }
      }
    }

    case actions.STUDY_FILE_COMPARER_RESET: {
      return {
        ...state,
        loading: false,
        studyDetail: {
          ...state.studyDetail,
          ComparerStudyId: undefined,
          compareFile: undefined,
          Comparer: undefined
        }
      }
    }

    case actions.STUDY_FILE_PLACE_INITIAL_JOB: {
      const { /*studyId,*/ studyFile, versions, content, contentType, latest } = action.payload;
      //const previousVersions = versions.slice(1);
      //const lastfive = previousVersions.length > 5 ? previousVersions.slice(0, 5) : previousVersions

      const versions_converted = versions.map(item => {
        return {
          id: item.studyFileId,
          fileName: item.fileName,
          folder: item.folder,
          created: item.uploadDateTime,/*formatDateTime(item.uploadDateTime),*/
          version: item.version,
          s3BucketName: item.s3BucketName,
          s3Key: item.s3Key
        };
      });



      return {
        ...state,
        loading: false,
        studyDetail: {
          ...state.studyDetail,
          file: studyFile,
          fileContent: content,
          fileContentType: contentType,
          fileVersions: versions_converted,
          fileNotLatest: latest
        }
      };
    }



    case actions.STUDY_FILE_RESET_PAGING_WITH_FOLDER: {

      //const { folder, fileType } = action.payload;
      //const type = fileType + "Files"

      //let currentFiles = (state.studyDetail[type]) ? state.studyDetail[type].filter(sf => sf.type.toUpperCase() !== folder.toUpperCase()) : [];

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          /*[type]: [],*/
          folderLoading: true
        }
      };
    }


    case actions.SASJOBS_RECEIVED_PAGING: {
      const { res } = action.payload;

      const fileType = state.studyDetail.viewMode + "Files"
      const metadata = state.studyDetail.viewMode + "Metadata"

      const sasJobs = res.data.map(item => {
        return {
          id: item.sasJobId,
          fileName: item.fileName,
          folder: item.folder,
          displayDate: timeDifference(item.sasJobCreatedDate),
          created: item.sasJobCreatedDate,
          sasComputerId: item.sasComputerId,
          sasJobExecutedDate: item.sasJobExecutedDate,
          sasJobPriority: item.sasJobPriorit,
          sasJobReadyForReviewDate: item.sasJobReadyForReviewDate,
          sasJobReviewedDate: item.sasJobReviewedDate,
          sasJobStartedDate: item.sasJobStartedDate,
          sasLogCharNumConversion: item.sasLogCharNumConversion,
          sasLogError: item.sasLogError,
          sasLogMETAnote: item.sasLogMETAnote,
          sasLogMerge: item.sasLogMerge,
          sasLogUninitialized: item.sasLogUninitialized,
          sasLogWarning: item.sasLogWarning,
          checkBox: 0,
          rt: timeDifferenceTwoDates(item.sasJobStartedDate, item.sasJobExecutedDate)
        }
      })

      return {
        ...state,
        studyDetail: {
          ...state.studyDetail,
          [fileType]: sasJobs,
          [metadata]: res.metadata,
          folderLoading: false
        }
      };
    }

    case actions.SASJOBS_QC_STATUS_ADD: {


      const { sasJobs } = state.studyDetail

      const qcjobs = sasJobs && sasJobs.length ? sasJobs.filter(r => r.folder.toUpperCase().replace(/\\/g, '/').endsWith("/QC")) : []
      const qcjobsnoerror = qcjobs.filter(r => r.sasLogError === 0)
      const jobsQced = qcjobs.map(item => { return item.folder.replace(/\\/g, '/').substring(0, item.folder.length - 3) + "/" + item.fileName })
      const jobsQcedNoErrors = qcjobsnoerror.map(item => { return item.folder.replace(/\\/g, '/').substring(0, item.folder.length - 3) + "/" + item.fileName })
      let jobsQCedNotErrorsDate = []
      qcjobsnoerror.map(item => {
        let realSasjob = sasJobs.filter(r => r.folder.replace(/\\/g, '/') === item.folder.replace(/\\/g, '/').substring(0, item.folder.length - 3).replace(/\\/g, '/') &&
          r.fileName === item.fileName)[0];
        if (realSasjob) {
          if (realSasjob.sasJobStartedDate < item.sasJobStartedDate) {
            jobsQCedNotErrorsDate.push(item.folder.replace(/\\/g, '/').substring(0, item.folder.length - 3) + "/" + item.fileName)
          }
        }
        return null
      })


      const sasJobsWQcStatus = sasJobs.map(item => {
        return {
          ...item,
          status: (jobsQCedNotErrorsDate.indexOf(item.folder.replace(/\\/g, '/') + "/" + item.fileName) > -1) ? "Passed" :
            (jobsQcedNoErrors.indexOf(item.folder.replace(/\\/g, '/') + "/" + item.fileName) > -1) ? "Needs re-run" :
              (jobsQced.indexOf(item.folder.replace(/\\/g, '/') + "/" + item.fileName) > -1) ? "Failed" : "Not QCed"
        }
      })


      return {
        ...state,
        /*sasJobs: sasJobsWQcStatus,*/
        studyDetail: {
          ...state.studyDetail,
          sasJobs: sasJobsWQcStatus,
          folderLoading: false
          /*sasExecutionFiles: [[]]*/
        }
      };
    }

    case actions.STUDY_PREVIEW_RECEIVE: {
      const { studyId, fileType } = action.payload;
      if (state.studyDetail && state.studyDetail.id === studyId && state.studyDetail.fileType === fileType) {

        return {
          ...state,
          studyDetail: {
            ...state.studyDetail,
            preview: action.payload,
          }
        };

      }

      return state;
    }

    case actions.AUTHENTICATED: {
      const { isAuth } = action.payload;
      return {
        ...state,
        isAuthenticated: isAuth,
        authenticating: false
      };
    }

    case actions.STORE_API_URL: {
      const { apiURL } = action.payload;
      return {
        ...state,
        apiURL: apiURL
      };
    }
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  // your reducers here
  createStudy,
  userProfile,
  studyInvite,
  permissions,
  issues,
  toasts,
  fileVersions,
  fileReview,
  sasJob,
  root
});

export default rootReducer;