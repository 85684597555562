import React from 'react';
import './EditableTextField.scss';
import EdiText from 'react-editext';

const EditableTextField = ({ value, onChange, placeholder }) => {
  // The component doesn't have its own state or lifecycle methods,
  // so no hooks are needed here.

  return (
    <EdiText
      type='text'
      viewContainerClassName='my-custom-view-wrapper'
      onSave={(v) => onChange(v)}
      hint={placeholder}
      validationMessage="Please type at least 2 characters."
      validation={val => (val.length >= 2)}
      onCancel={v => v}
      value={value}
      inputProps={{
        style: {
          fontWeight: 500,
          width: 210,
          minWidth: 210
        }
      }}
      viewProps={{
        style: {
          color: ((value && value.length < 2) || !value) ? "red" : "black",
          width: 240,
          minWidth: 240
        }
      }}
    />
  );
};

export default EditableTextField;
