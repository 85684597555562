import "core-js";
import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/index.js'; // Consolidate your reducers here
import { rootSagas } from './sagas/index'; // Assuming you have a rootSaga
import App from './app/app.js'; // Your main App component
import './index.scss'; // Your global styles
import reportWebVitals from './reportWebVitals';
import APIService from './common/api';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // default theme
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

const Meta = lazy(() => import('./auth/Meta'));
const Leo = lazy(() => import('./auth/Leo'));
const Hansson = lazy(() => import('./auth/Hansson'));
const Mke = lazy(() => import('./auth/Mke'));

// Create the saga middleware with context
const sagaMiddleware = createSagaMiddleware({
  context: {
    api: new APIService(),
  }
});

const loggerMiddleware = store => next => action => {
  console.log('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  return result;
};

// Configure the store with middleware and reducers
const store = configureStore({
  reducer: rootReducer, // Use a single rootReducer to combine your reducers
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false })
      //.concat(sagaMiddleware)
      .concat(/*loggerMiddleware,*/ sagaMiddleware)
      .concat(process.env.NODE_ENV !== 'production' ? reduxImmutableStateInvariant() : []),
});

// Then run the saga
sagaMiddleware.run(rootSagas);

// Render the application
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Authenticator.Provider>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/Meta" element={<Meta />} />
              <Route path="/Leo" element={<Leo />} />
              <Route path="/Hansson" element={<Hansson />} />
              <Route path="/Mke" element={<Mke />} />
              <Route path="*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </Authenticator.Provider>
    </React.StrictMode>
  );
}

reportWebVitals();
