export const viewModes = {
    INTRO: 'intro',
    UPLOAD: 'upload',
    DOWNLOAD: 'download',
    RELEASE: 'release',
    SASJOBS: 'sasjobs',
    SECURITY: 'security',
    PERMISSIONS: 'permissions',
    SASEXECUTION: 'sasexecution',
    UTILITIES: 'utilities'
}
export const fileTypes = {
    DATA: 'Data',
    DOCUMENT: 'Document'
}
export const fileStatusActions = {
    DRAFT: 'draft',
    REVIEW: 'review',
    FINAL: 'final',
    SPONSOR_FINAL: 'sponsor final'
}
export const statusColors = {
    DRAFT: '#ffc107',
    REVIEW: '#00bcd4',
    FINAL: '#e435e4',
    SPONSOR_FINAL: '#29de30'
}

export const sasColors = {
    ERROR: '#dc3545',
    WARNING: '#ffc107',
    UNINITIALIZED: '#ffc107',
    OK: '#28a745',
    NOT_RUN: '#6c757d',
    MERGE_CONFLICTS: '#ffc107',
    META_NOTE: '#00bcd4'
}

export const filechangedActions = {
    WEEK1: '< 1 week',
    WEEK2: '< 2 weeks'
}