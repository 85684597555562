import { createSelector } from 'reselect';
import Inbox from "../pages/components/Inbox";
import "core-js";
import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useIdleTimer, IdleTimerComponent } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import * as actions from '../actions/';
import "./app.scss";
import { blueGrey, pink } from '@mui/material/colors';
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";

import Sidebar from "../navigation/Sidebar";
import Navigation from "../navigation/Navigation";
import Bottombar from "../navigation/Bottombar";
import MyComponent from "../routes";
import UserProfile from "../pages/UserProfile";
import ToastWrapper from "../pages/components/ToastWrapper";
import IdleModal from "../pages/modals/IdleModal";
import { selectInboxOpen, selectFileReviewOpen, selectFileVersionsOpen, selectSasJobComparer, selectStudyDetailComparer, selectToasts } from '../selectors/storeselectors';

import './app.scss';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, fetchUserAttributes } from '@aws-amplify/auth';
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  get as getAmplify,
  put as putAmplify,
  post as postAmplify,
} from 'aws-amplify/api';

import { delay } from "redux-saga/effects";

//import { root } from '../reducers/createStudy';
import { RootReducer, initialState } from '../reducers';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import VerifyAndUpdateUserField from '../pages/modals/VerifyAndUpdateUserField'

const apiName = 'metawebserviceRestApi';

const existingConfig = Amplify.getConfig();
console.log("Existing config 1.1", existingConfig);
Amplify.configure(awsExports);
const existingConfig2 = Amplify.getConfig();
console.log("Existing config 1.2", existingConfig2);

const timer = 1000 * 60 * 15; //1000 * 10; //1000 * 60 * 15;

const theme = createTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    fontSize: 10
  },
  palette: {
    primary: {
      lightest: "#91d1ec",
      lighter: "#0273b5" /*blueGrey[100]*/ /*'#91d1ec'*/,
      light: "#0273b5" /*'#91ecd3'/*blueGrey[300]*/,
      main: "#0273b5" /*teal[500]*/,
      dark: blueGrey[700]
    },
    secondary: {
      light: blueGrey[300],
      main: blueGrey[500],
      dark: blueGrey[700]
    },
    error: {
      main: pink[500],
      light: "pink"
    },
    warning: {
      main: pink[500],
      light: "red"
    },
    type: "light"
  },
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, 0.5)"
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: "10px"
      }
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        top: "2.55rem",
        left: 0,
        width: "100%",
        color: "red",
        "&$error": {
          color: "red"
        }
      }
    },
    MuiFilledInput: {
      root: {
        padding: 0,
        "&:hover": {
          backgroundColor: "white" /*"rgba(0, 0, 0, 0)"*/
          // boxShadow: "0 1px 4px 4px rgba(222, 220, 221, 0.1)"
        },
        "&$focused": {
          padding: 0,
          "&$focused": {
            color: "black",
            boxShadow: "0 1px 4px 4px rgba(222, 220, 221, 0.1)",
            backgroundColor: "rgba(205, 240, 247, 0.2)"
          }
          /*color: "black",
          backgroundColor: "rgba(205, 240, 247, 0.2)"*/
        },
        "&$error": {
          border: "1px solid red"
        }
      },
      input: {
        //boxSizing: "border-box",
        boxSizing: "content-box",
        // backgroundColor: "white",
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0)"
        }
      },
      /*// this is the outer frame
      focused: {
        padding: 0,
        "&$focused": {
          color: "black",
          boxShadow: "0 1px 4px 4px rgba(222, 220, 221, 0.1)",
          backgroundColor: "rgba(205, 240, 247, 0.2)"
        }
      }*/
    }
  }
});


const App = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { route } = useAuthenticator(context => [context.route]);

  const dispatch = useDispatch();
  /*
    // Individual selectors for each piece of state
    const selectApiURL = state => state.root.apiURL;
    const selectId = state => state.root.studyDetail?.id;
    const selectStudyDetailComparer = state => state.root.studyDetail?.Comparer;
    const selectToasts = state => state.toasts;
    const selectOpen = state => state.userProfile?.open;
    const selectFileReviewOpen = state => state.fileReview?.open;
    const selectFileVersionsOpen = state => state.fileVersions?.open;
    const selectInboxOpen = state => state.userProfile?.inboxOpen;
    const selectSasJobComparer = state => state.sasJob?.Comparer;
    const selectStudies = state => state.root.studies;
  */
  // Memoized selector that combines them
  /*
    const selectStudyDetails = createSelector(
      [selectApiURL, selectId, selectStudyDetailComparer, selectToasts, selectOpen, selectFileReviewOpen, selectFileVersionsOpen, selectInboxOpen, selectSasJobComparer, selectStudies],
      (apiURL, id, studyDetailComparer, toasts, open, fileReviewOpen, fileVersionsOpen, inboxOpen, sasJobComparer, studies) => ({
        apiURL,
        id,
        studyDetailComparer,
        toasts,
        open,
        fileReviewOpen,
        fileVersionsOpen,
        inboxOpen,
        sasJobComparer,
        studies
      })
    );
    */
  const open = useSelector(state => state.userProfile?.open);
  const inboxOpen = useSelector(selectInboxOpen);
  const fileReviewOpen = useSelector(selectFileReviewOpen);
  const fileVersionsOpen = useSelector(selectFileVersionsOpen);
  const sasJobComparer = useSelector(selectSasJobComparer);
  const studyDetailComparer = useSelector(selectStudyDetailComparer);
  const toasts = useSelector(selectToasts);
  const userProfile = useSelector(state => state.userProfile)
  const confirmUserAttributesModal = useSelector(state => state.userProfile?.confirmUserAttributesModal)
  //const studyDetails = useSelector(selectStudyDetails);
  //console.log(studyDetails);

  /*
  const {
    apiURL,
    id,
    studyDetailComparer,
    toasts,
    open,
    fileReviewOpen,
    fileVersionsOpen,
    inboxOpen,
    sasJobComparer,
    studies,
  } = useSelector(state => (
    {
      apiURL: state.root.apiURL,
      id: state.root.studyDetail?.id,
      studyDetailComparer: state.root.studyDetail?.Comparer,
      toasts: state.toasts,
      open: state.userProfile?.open,
      fileReviewOpen: state.fileReview?.open,
      fileVersionsOpen: state.fileVersions?.open,
      inboxOpen: state.userProfile?.inboxOpen,
      sasJobComparer: state.sasJob?.Comparer,
      studies: state.root.studies
    }));
    */
  /*  
    const [fileReviewOpen, setFileReviewOpen] = useState(false);
    const [fileVersionsOpen, setFileVersionsOpen] = useState(false);
    const [sasJobComparer, setSasJobComparer] = useState("");
    const [fileVersions, setFileVersions] = useState([]);
    const [fileReview, setFileReview] = useState([]);
    const [apiURL, setApiURL] = useState("");
    const [id, setId] = useState("");
    const [studyDetailComparer, setStudyDetailComparer] = useState("");
    const [open, setOpen] = useState(false);
    const [inboxOpen, setInboxOpen] = useState(false);
    const [studies, setStudies] = useState([]);
    const [toasts, setToasts] = useState([]);
  */
  //const [confirmUserAttributesModal, setConfirmUserAttributesModal] = useState(null);
  /*
  const [confirmUserAttributesModal, setConfirmUserAttributesModal] = useState(null);
  const [isIdle, setIsIdle] = useState(false);
  */
  //const [amplifyConfigured, setAmplifyConfigured] = useState(false);
  //const [cognitoUser, setCognitoUser] = useState(null);
  /*
  const [loaded, setLoaded] = useState(false);
  
  const [jwt, setJwt] = useState("");
  //const [intervalID, setIntervalID] = useState(null);
  const [userProfileOpen, setUserProfileOpen] = useState(false);
  */
  //const [userProfile, setUserProfile] = useState(null);

  //const navigate = useNavigate();
  /*
    const configureAuth = async () => {
      console.log('configureAuth')
      async function applyConfig() {
        await fetchAuthSession().then((data) => {
          console.log('Success fetching data:', data);
          const existingConfig = Amplify.getConfig();
          console.log("Existing config 2", existingConfig);
  
          Amplify.configure(awsExports, {
            API: {
              REST: {
                headers: async () => {
                  return {
                    "Authorization": `Bearer ${data?.tokens?.idToken?.toString()}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                  };
                },
              }
            }
          }
          );
          setAmplifyConfigured(true);
        })
      }
      await applyConfig();
  
      try {
      }
      catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow or handle error  catch (error) {
  
      }
    }
    */
  /*
    const onIdle = useCallback(() => {
      setIsIdle(true);
      dispatch(actions.showIdleModal(true));
    }, [actions]);
    const idleTimer = useIdleTimer({
      timeout: 1000 * 60 * 15, // 15 minutes
      onIdle,
    });
  */
  const handleSignoutClick = async () => {
    try {
      signOut();
      //setCognitoUser(null);
      setJwt("");
      setLoaded(false);
      //setStudies([]);
      //navigate('/studies');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  //const fetchUser = async () => {
  // You can await here
  //const currentUser = await fetchUserAttributes()
  //return currentUser;
  //}

  useEffect(() => {
    //const callFetchUser = async () => {
    //  if (!cognitoUser) {
    //    setCognitoUser(await fetchUser());
    //  }
    //};

    //callFetchUser().catch(e => {
    //  console.log(e);
    // setIsAmplifyConfigured(false)
    //});

    //if (!userProfile.cognitoUserId) {
    //dispatch(actions.loginUserApi(undefined));
    //}

    const handleUtilityActions = (event) => {
      dispatch(actions.utilityActionsReceived(event.detail));
    };

    window.addEventListener('utilityActions', handleUtilityActions, false);

    return () => {
      window.removeEventListener('utilityActions', handleUtilityActions, false);
      // clearInterval(intervalID)
    };
  }, []);

  //useEffect(() => {
  //  dispatch(actions.loginUserApi(undefined));
  //}, []);


  const location = useLocation();
  const pathname = location.pathname;

  return (
    <MuiThemeProvider theme={theme}>
      <>
        <Authenticator />
        <CssBaseline />
        {/*
      {(
        //add idle timer
        <IdleTimerComponent
          ref={ref => {
            idleTimer = ref;
          }}
          timeout={timer}
          onIdle={onIdle}
          debounce={250}
          element={document}
        />

      )}
        */}

        {/*isIdle && (
        <IdleModal
          stayLoggedIn={() => dispatch(actions.showIdleModal(false))}
          logout={handleSignoutClick}
        />
      )*/}
        <Grid item xs={12} style={{ top: "2.5rem", position: "relative", paddingBottom: "3.2rem" }}>
          <Sidebar studiesSelected={true} />

          <MyComponent />

          <Navigation
            signout={handleSignoutClick}
            pathname={pathname}
          />

          {open && (
            <UserProfile onClose={() => dispatch(actions.userProfileToggleDrawer())} />
          )}

          {inboxOpen && (
            <Inbox />
          )}
          {confirmUserAttributesModal && (
            <VerifyAndUpdateUserField
              actions={actions}
              {...confirmUserAttributesModal}
            />
          )}
          {fileReviewOpen && <FileReviewModal />}
          {fileVersionsOpen && <FileVersionsModal />}
          {sasJobComparer && <FileComparerModal />}
          {studyDetailComparer && <FileComparerModal />}
          {toasts.length > 0 && (
            <ToastWrapper toasts={toasts} onClose={() => dispatch(actions.toastClose())} />
          )}

          <Bottombar />

        </Grid>
      </>
    </MuiThemeProvider>
  );
}
export default withAuthenticator(App);

