import "./UploadFilesV2.scss";
//import * as actions from "../actions/";
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormControl, Typography, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dropzone from "react-dropzone";
import { uploadSvg } from '../ui/SvgIcons';
import Grid from "@mui/material/Grid";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadFileWidget from "./components/UploadFileWidget";
import AutoCompleteField from "../ui/AutoCompleteField";
import { viewModes } from "../constants";
import { folder } from "jszip";
import * as actions from "../actions/";
const styles = theme => ({
  wrapper: {
    position: "absolute"
  },
  dropzoneIcon: {
    color: '#0273b5',//theme.palette.primary.light

  },
  dropzoneFileRefbutton: {
    /*position: "absolute",*/
    background: '#0273b5',//theme.palette.primary.light,
    padding: "0 1.5rem",
    height: "2.2rem",
    color: "white",
    borderRadius: "30px",
    textAlign: "center",
    lineHeight: "2rem",
    margin: "3rem auto",
    /*top: "50%",
    left: "50%",
    transform: `translate3d(-50%, -50%, 0)`,*/
    width: '10rem',
  },
  text: {
    textAlign: "center",
    width: "50%",
    position: "relative",
    margin: "3rem auto",
    color: '#0273b5'
  },
  disclaimer: {
    fontSize: "0.7rem",
    textAlign: "center",
    margin: "4rem auto",
    lineHeight: "1rem"
  },

  spinner: {
    position: "absolute",
    height: "2.2rem",
    textAlign: "center",
    lineHeight: "2rem",
    top: "50%",
    left: "50%",
    transform: `translate3d(-50%, -50%, 0)`
  },
  icon: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "50%",
    left: "50%",
    fontSize: "6rem",
    color: /*theme.palette.primary.light*/ "#4caf50",
    transform: `translate3d(-50%, -0%, 0)`
  },
  dropDown: {
    /*position: 'relative',
    width: '30%',
    top: '6rem',
    left: '1.5rem',*/
    marginBottom: "1rem"

  },
  dropDownAdmin: {
    top: '4.5rem',
  },
  dropDownHeader: {
    fontSize: '0.8rem',
    marginBottom: '0.5rem',
  },
  radioGroup: {
    display: 'inline-block',
    position: 'relative',
  },
  radioItem: {
    marginRight: '2rem'
  },
  btnSmall: {
    width: "25px",
    minWidth: "25px",
    display: "inline-flex",
    verticalAlign: "middle",
    padding: "0 1rem"
  },
  AutoComplete: {
    width: "80%",
    display: "inline-flex",
    verticalAlign: "middle",
    margin: 0
  },
  fullwidth: {
    width: "100%",
    display: "block"
  },
  buttonWrapper: {
    background: "#0273b5",
    border: 0,
    color: "white",
    padding: "0 2rem",
    height: "44px",
    borderRadius: "2rem",
    top: "1.5rem",
    // minWidth: '10rem',
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#0273b5"
    },
    /*width: "50%"*/
  },
  title: {
    marginBottom: "0.5rem"
  }
});

const dropzoneRef = React.createRef();

const UploadFilesV2 = (props) => {

  const { permissions, uploading, uploadSucces, potentialUploads, potentialUploadsWithStatus, selectedUploadDownloadfolder, uploadFolders, viewMode } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState("upload");
  const [folder, setFolder] = useState("");
  const [contentType, setContentType] = useState("");

  console.log("UploadFilesV2 " + viewMode)

  useEffect(() => {
    if (!uploadFolders) {
      dispatch(actions.studyToggleFileType(viewModes.UPLOAD));
      dispatch(actions.getStudyFileFolders());
    }
  }, []);

  const handleUpload = files => {
    dispatch(actions.updatePotentialUploads(files))
    setStep("arrange")
    setFolder("arrange")
  };

  const handleFreeTextChange = val => {

    var RealInvalidPathChars = /[<>:!"\\|?* \\&]/;
    let invalid = RealInvalidPathChars.test(val)

    if (val.toUpperCase().startsWith("DOWNLOAD")) {
      invalid = true
    }

    if (invalid) {
      setContentType(val)
      setErrorMessage("Folder name is invalid")
    }
    else {
      setContentType(val)
      setErrorMessage("")
    }
  };

  const handleChange = value => {
    setFolder(value)
  };

  const handleClick = () => {
    const folders = []
    folders.push(folder)
    dispatch(actions.setStudyFileFolder(folders))
  }

  const displayInitialStep = !uploading && !uploadSucces;
  return (
    <div className="upload-files">

      <Grid container>
        <Grid item xs={12}>

          {/*(step === "upload" && !selectedUploadDownloadfolder) && <div> ANNOYING </div>*/}


          {((step === "upload" && !selectedUploadDownloadfolder) ||
            (step === "upload" && selectedUploadDownloadfolder && selectedUploadDownloadfolder.length === 0)) &&
            <FormControl sx={styles.fullwidth} variant="outlined">
              <Typography variant="h6" sx={styles.title}>
                Choose/write the folder where the files must be placed
              </Typography>
              <AutoCompleteField
                initialValue={folder}
                placeholder={'The folder name for the files'}
                allSuggestions={(uploadFolders) ? uploadFolders.map(item => {
                  return { label: item.folder };
                }) : []}
                onSelect={handleChange}
              />
              <Grid item>
                <Button
                  id="applyChange"
                  sx={styles.buttonWrapper}
                  disabled={folder === "" || folder === undefined}
                  onClick={handleClick}
                >
                  {folder === "" || folder === undefined ? "Enter folder" : "Select folder"}
                </Button>
              </Grid>
            </FormControl>
          }

          {step === "upload" && selectedUploadDownloadfolder && selectedUploadDownloadfolder.length > 0 &&
            <Dropzone ref={dropzoneRef} onDrop={this.handleUpload} disabled={errorMessage !== ""}>
              {({ getRootProps, getInputProps }) => (
                <div id="uploadDropzone" {...getRootProps()} className={classNames((errorMessage === "") ? "upload-files__dropzone" : "upload-files__dropzone_disabled")}>
                  <input {...getInputProps()} />
                  {uploadSucces && (
                    <CheckCircleIcon sx={styles.icon} />
                  )}
                  {(uploading && !uploadSucces) && (
                    <div style={styles.spinner}>
                      <CircularProgress />
                    </div>
                  )}
                  {displayInitialStep && (
                    <p style={styles.text}>Drop your files here</p>
                  )}

                  {displayInitialStep && (
                    <div style={styles.dropzoneFileRefbutton}>
                      {uploadSvg} <span style={{ marginLeft: '0.2rem', fontSize: '0.7rem' }}>Or choose file</span>
                    </div>
                  )}

                  {displayInitialStep && (
                    <p style={styles.disclaimer}>Max file size 2 GB.</p>
                  )}

                </div>
              )}
            </Dropzone>
          }

          {step === "arrange" &&
            <UploadFileWidget
              actions={actions}
              files={potentialUploads}
              permissions={permissions}
              potentialUploadsWithStatus={potentialUploadsWithStatus}
              viewMode={viewMode}
            />
          }

        </Grid>
      </Grid>
    </div>
  );
}


UploadFilesV2.propTypes = {
  //classes: PropTypes.object.isRequired,
  viewMode: PropTypes.string,
  uploading: PropTypes.bool,
  uploadSucces: PropTypes.bool,
  permissions: PropTypes.array,
  potentialUploads: PropTypes.array,
  potentialUploadsWithStatus: PropTypes.array,
  selectedUploadDownloadfolder: PropTypes.array,
  uploadFolders: PropTypes.array
};

export default UploadFilesV2;
