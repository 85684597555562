export const LOGOUT_SELECTED = 'LOGOUT_SELECTED';
export const LOGIN_INITIALIZED_TOKEN_MONITOR = 'LOGIN_INITIALIZED_TOKEN_MONITOR';
export const LOGIN_USER_API = 'LOGIN_USER_API';
export const LOGIN_USER_API_FINISHED = 'LOGIN_USER_API_FINISHED'

export const logoutSelected = () => ({
    type: LOGOUT_SELECTED,
    payload: { /*history*/ }
});

export const loginInitializedTokenMonitor = () => ({
    type: LOGIN_INITIALIZED_TOKEN_MONITOR,
    payload: {}
});

export const loginUserApi = (id) => ({
    type: LOGIN_USER_API,
    payload: { id }
})

export const loginUserApiFinished = () => ({
    type: LOGIN_USER_API_FINISHED,
    payload: {}
})


