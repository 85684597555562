import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

function Btn(props) {
  const { children, disabled, onClick, className, to, fullWidth, id } = props;

  const buttonStyles = {
    root: {
      background: '#2196F3',
      border: 0,
      color: 'white',
      padding: '0 2rem',
      height: '44px',
      borderRadius: '2rem',
      "&:hover": {
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        background: 'black',
      },
      typography: 'body1',
      fontSize: '0.6rem',
      textTransform: 'none',
      ...(fullWidth && { width: '100%' }),
      ...(className && { className })
    }
  };

  if (to) {
    return (
      <Button
        id={id}
        component={Link}
        to={to}
        disabled={disabled}
        size='small'
        sx={buttonStyles.root}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      id={id}
      disabled={disabled}
      size='small'
      sx={buttonStyles.root}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

Btn.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullWidth: PropTypes.bool,
  id: PropTypes.string
};

export default Btn;
