import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Paper, MenuItem } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useTheme } from '@mui/material/styles';

function IntegrationAutosuggest({ initialValue, allSuggestions, onSelect, placeholder, label, error }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (initialValue) {
      setInputValue(initialValue);
    }
  }, [initialValue]);

  const theme = useTheme();
  const styles = {
    // Define your styles here using theme
  };

  // ... rest of your component logic

  return (
    // Your JSX using Material-UI components with sx prop for styling
    <div style={{ root }}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          error: error,
          label: label,
          displayInForm: displayInForm,
          placeholder: placeholder,
          value: this.state.single,
          onChange: this.handleChange("single")
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

IntegrationAutosuggest.propTypes = {
  // Define your PropTypes
};

export default IntegrationAutosuggest;

/*
function renderInputComponent(props) {
  const { classes, inputRef = () => { }, error, ref, displayInForm, ...other } = props;

  return (
    <TextField
      fullWidth
      helperText={
        error
          ? error
          : ""
      }
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        style: error ? { "border": '1px solid red' } : {},
        classes: {
          // input: classes.input,
          input: classes.inputPadding,
          root: classes.input
        }
      }}
      inputProps={{
        autoCapitalize: 'none'
      }}
      variant="filled"
      margin="normal"
      InputLabelProps={{
        required: false,
        error: false,
        shrink: false,
        classes: {
          root: classes.label,
          focused: classes.cssFocused
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value, suggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
      const keep =
        count < 5 &&
        suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const suggestStyles = theme => ({
  root: {
    height: "3rem",
    flexGrow: 1,
    width: "100%",
    position: "relative"
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 10,
    marginTop: 0,
    top: '2.9rem',
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing(2)
  },

});
const styles = theme => ({
  ...loginStyles(theme),
  ...suggestStyles(theme)
});

class IntegrationAutosuggest extends React.Component {
  state = {
    single: "",
    popper: "",
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { allSuggestions } = this.props;
    this.setState({
      suggestions: getSuggestions(value, allSuggestions)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue }) => {
    const { onSelect } = this.props;
    this.setState({
      [name]: newValue
    });
    onSelect(newValue);
  };

  render() {
    const { classes, placeholder, displayInForm, label, error } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      placeholder,
      displayInForm,
      label
    };
    return (
      <div sx={{ root }}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            error: error,
            label: label,
            displayInForm: displayInForm,
            placeholder: placeholder,
            value: this.state.single,
            onChange: this.handleChange("single")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired
};

export default (IntegrationAutosuggest);
*/