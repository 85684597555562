import * as actions from "../actions/";

import {
  takeLatest,
  call,
  /*all,*/
  put,
  getContext,
  select,
  delay
} from "redux-saga/effects";
import uniqueId from "lodash/uniqueId";

function* initialize(action) {
  console.log("initialize", action.payload.studyId, action.payload.sasJobId)
  try {
    const api = yield getContext("api");
    const { studyId, sasJobId } = action.payload;
    const job = yield call(api.get, `sas/Job/${sasJobId}`);
    const latest = yield call(api.get, `sas/Job/${studyId}/${encodeURIComponent(job.folder)}/${job.fileName}`);
    const sasjobsRes = yield call(api.get, `sas/GetSasJobVersions/${sasJobId}`);
    yield put(dispatch(actions.sasJobPlaceInitialJob(studyId, job, sasjobsRes, (latest.sasJobId === job.sasJobId) ? "" : latest.sasJobId)));
  } catch (error) { }
}

function* compareProgram(action) {
  try {
    const api = yield getContext("api");
    const { sasJobId, studyId } = action.payload;
    const job = yield call(api.get, `sas/Job/${sasJobId}`);
    yield put(dispatch(actions.sasJobPlaceComparerJob(studyId, job)));
    //}
  } catch (error) { }
}

// http://projects.wojtekmaj.pl/react-pdf/  show pdf in app, download section typically, show pdf icon in file is of pdf

function* updatePrograms(action) {

  const toastId = uniqueId('updatePrograms');
  try {
    const state = yield select();
    const api = yield getContext("api");
    const { studyDetail } = state.root;
    const { sasjobsFiles, id } = studyDetail;
    yield put(dispatch(actions.initiateMessage(toastId, studyDetail.id, 'Starting running jobs...')));

    var idlist = sasjobsFiles.filter(item => item.checkBox) /*.sort((a, b) => a.sasJobPriority - b.sasJobPriority)*/

    var updateList = []
    if (idlist.length > 100) {
      console.log("More than 100 jobs taking the first 100 jobs!")
      updateList = idlist.slice(0, 100);
      yield put(dispatch(actions.updateMessage(toastId, 'More than 100 jobs taking the first 100 jobs!.')));
    }
    else {
      updateList = idlist
      yield put(dispatch(actions.updateMessage(toastId, "Starting " + updateList.length + " jobs. ")));
    }

    updateList = updateList.sort((a, b) => a.sasJobPriority - b.sasJobPriority)

    //conver int to string 
    const StudyId = id.toString()

    let __count = 0;
    for (let file of updateList) {
      const job = yield call(api.get, `sas/Job/${file.id}`);
      const sasCode = job.sasCode

      const params = {
        "studyId": parseInt(StudyId),
        sasCode,
        Folder: job.folder,
        FileName: job.fileName,
        automatic: true
      };

      yield call(api.post, "sas/update", params);
      if (job) {
        __count++;
        yield put(dispatch(actions.updateMessage(toastId, "Starting job " + __count + " of " + updateList.length)));
      }
    }

    //yield put(dispatch(actions.setSasJobRefreshRequired(updateList[0].sasJobId));
    if (__count === updateList.length) {
      yield put(dispatch(actions.finalMessage(toastId, "Succesfully started jobs. Refresh page to get the latest updates", "success")));
    }
    else {
      yield put(dispatch(actions.finalMessage(toastId, "Started " + __count + " of " + updateList.length + " jobs. Refresh page to get the latest updates", "warning")));
    }
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
    yield put(dispatch(actions.getSasJobFileFolders()));
  } catch (error) {
    yield put(dispatch(actions.sasJobError()));
    console.log("error in resp ", error);
    yield put(dispatch(actions.finalMessage(toastId, "Failed starting jobs!", "error")));
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
  }
}

function* updateProgram(action) {
  const toastId = uniqueId('updateProgram');
  try {
    const api = yield getContext("api");
    let state = yield select();
    const { studyId, sasJob } = state.sasJob;
    yield put(dispatch(actions.initiateMessage(toastId, studyId, 'Saving job...')));
    const { fileName, folder } = sasJob;
    const { sasCode } = action.payload;
    const params = {
      studyId,
      sasCode,
      Folder: folder,
      FileName: fileName,
      automatic: true
    };

    const guid = yield call(api.post, "sas/update", params);
    state = yield select();
    if (state.sasJob && state.sasJob.studyId === studyId) {
      //yield put(dispatch(actions.sasJobsReceived(jobs ? jobs : []));
      const job = yield call(api.get, `sas/Job/${guid}`);
      yield put(dispatch(actions.sasJobReceive(job)));
      yield put(dispatch(actions.finalMessage(toastId, "Succesfully saved job", "success")));
      yield delay(2500);
      yield put(dispatch(actions.toastClose(toastId)));
    }
  } catch (error) {
    yield put(dispatch(actions.sasJobError()));
    yield put(dispatch(actions.finalMessage(toastId, "Failed saving job!", "error")));
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
    console.log("error in resp ", error);
  }
}

function* createProgram(action) {
  try {
    const api = yield getContext("api");

    const { program, folder, studyId, history } = action.payload;
    console.log(action.payload)
    const params = {
      studyId,
      sasCode: " ",
      Folder: folder,
      FileName: program
    };

    const sasJobId = yield call(api.post, "sas/create", params);
    // we have to know if we already have content in the modal, dont overwrite with new stuff
    history.replace(`/study/${studyId}/sasjob/${sasJobId}`);
  } catch (error) {
    yield put(dispatch(actions.sasJobError()));
    console.log("error in resp ", error);
  }
}

function* deleteProgram(action) {
  try {
    const api = yield getContext("api");
    const { sasJobId } = action.payload;
    yield call(api.delete, `sas/delete/${sasJobId}`);
    yield put(action.setSasJobRefreshRequired(sasJobId));
  } catch (error) { }
}

function* deleteAll(action) {
  const toastId = uniqueId('deleteAll');
  try {
    const state = yield select();
    const api = yield getContext("api");
    const { studyDetail } = state.root;
    const { sasjobsFiles /*, id, sasjobsfolder*/ } = studyDetail;
    var idlist = sasjobsFiles.filter(item => item.checkBox);
    yield put(dispatch(actions.initiateMessage(toastId, studyDetail.id, 'Starting to delete ' + idlist.length + " jobs.")));

    //call delete for all selected files
    let __count = 0
    for (let file of idlist) {
      const res = yield call(api.delete, `sas/delete/${file.id}`);
      if (res) {
        __count++;
        yield put(dispatch(actions.updateMessage(toastId, "Deleting " + __count + " of " + idlist.length + " jobs.")));
      }
    }
    //const id = 'delete';
    //yield put(action.setSasJobRefreshRequired(id));
    //Finalizing toast
    if (__count === idlist.length) {
      yield put(dispatch(actions.finalMessage(toastId, "Succesfully deleted jobs. Refreshing page!", "success")));
    }
    else {
      yield put(dispatch(actions.finalMessage(toastId, "Deleted " + __count + " of " + idlist.length + " jobs. Refreshing page!", "warning")));
    }
    //Closing toast
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
    yield put(dispatch(actions.getSasJobFileFolders()))
  } catch (error) {
    yield put(dispatch(actions.sasJobError()));
    console.log("error in resp ", error);
    yield put(dispatch(actions.finalMessage(toastId, "Delete failed!", "error")));
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
  }
}

function* exportAllSasJobs(action) {
  const toastId = uniqueId('SasExport');

  try {
    const state = yield select();
    const { studyDetail, studies } = state.root;
    const api = yield getContext("api");

    yield put(dispatch(actions.initiateMessage(toastId, studyDetail.id, 'Starting export...')));

    const selectedStudy = studies.filter(p => p.studyId === studyDetail.id)[0];
    const uploadPerm = selectedStudy.permissions.filter(p => p.permissionId === "UPLOAD");
    const downloadPerm = selectedStudy.permissions.filter(p => p.permissionId === "DOWNLOAD");

    if (uploadPerm.length !== 1) {
      yield put(dispatch(actions.finalMessage(toastId, "Insufficient permissions to export sas files.!", "error")));
      yield delay(2500);
      yield put(dispatch(actions.toastClose(toastId)));
      return
    }

    let obj = {
      "UtilId": "SasExport",
      "UtilPermissions": [{ "StudyId": studyDetail.id, "Permission": uploadPerm[0].permissionId }, { "StudyId": studyDetail.id, "Permission": downloadPerm[0].permissionId }],
      "sync": false,
      "Payload": {
        "userId": "not used",
        "destination": "all_sas_files.zip",
        "studyId": studyDetail.id,
        "sponsorGuid": selectedStudy.sponsorGuid,
        "studyGuid": selectedStudy.studyGuid
      }
    }

    const res = yield call(api.post, "Utils/initiateUtilLambda", obj);
    if (res === "Accepted") {
      yield put(dispatch(actions.finalMessage(toastId, "Export called successfully. Archive will be availeble in uploads.", "success")));
    }
    else {
      yield put(dispatch(actions.finalMessage(toastId, "Export failed.", "warning")));
    }
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
  } catch (error) {
    yield put(dispatch(actions.finalMessage(toastId, "Export failed", "error")));
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
    console.log("error in resp ", error);
  }
}

function* exportProgrammingPlan(action) {

  const toastId = uniqueId('programmingStatus');
  //Must be an input at some point
  const folders = ["programs"]
  try {
    const state = yield select();
    const { studyDetail, studies } = state.root;
    const api = yield getContext("api");

    yield put(dispatch(actions.initiateMessage(toastId, studyDetail.id, 'Starting export...')));

    const selectedStudy = studies.filter(p => p.studyId === studyDetail.id)[0];
    const uploadPerm = selectedStudy.permissions.filter(p => p.permissionId === "UPLOAD");

    if (uploadPerm.length !== 1) {
      yield put(dispatch(actions.finalMessage(toastId, "Insufficient permissions to export programmingplan.!", "error")));
      yield delay(2500);
      yield put(dispatch(actions.toastClose(toastId)));
      return
    }

    let obj = {
      "UtilId": "programmingStatus",
      "UtilPermissions": [{ "StudyId": studyDetail.id, "Permission": uploadPerm[0].permissionId }],
      "sync": false,
      "Payload": {
        "userId": "not used",
        "destination": "programmingStatus",
        "folders": folders,
        "studyId": studyDetail.id,
        "sponsorGuid": selectedStudy.sponsorGuid,
        "studyGuid": selectedStudy.studyGuid
      }
    }

    const res = yield call(api.post, "Utils/initiateUtilLambda", obj);
    if (res === "Accepted") {
      yield put(dispatch(actions.finalMessage(toastId, "Export called successfully. Document will be availeble in uploads.", "success")));
    }
    else {
      yield put(dispatch(actions.finalMessage(toastId, "Export failed.", "warning")));
    }
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
  } catch (error) {
    yield put(dispatch(actions.finalMessage(toastId, "Export failed", "error")));
    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));
    console.log("error in resp ", error);
  }
}

function* validateExecutionFolders(action) {
  try {
    const state = yield select();
    const { studyDetail } = state.root;
    const toastId = uniqueId('ValidateExecutionFolders');
    yield put(dispatch(actions.initiateMessage(toastId, studyDetail.id, 'Validating order of execution...')));
    const api = yield getContext("api");
    const { sasExecutionFolders } = state.root.studyDetail;
    const resTopologicSort = yield call(api.get, `sas/SuggestFolderOrder/${studyDetail.id}`);
    yield put(dispatch(actions.validateExecutionFoldersReceived(sasExecutionFolders, resTopologicSort)));

    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));

    return
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getExecutionFiles(action) {
  try {
    yield put(dispatch(actions.getExecutionFilesInitiated()));
    const { folder } = action.payload;
    if (folder === "") { return }
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const params = {
      studyId: studyDetail.id,
      folder,
      Page: 1,
      ResultsPrPage: 31
    };

    const resfiles = yield call(api.post, `sas/ListRO`, params);
    yield put(dispatch(actions.sasJobsFilesReceived(resfiles ? resfiles : [])));
    const res = yield call(api.get, "sas/GetExecutionFiles/" + studyDetail.id + "/" + encodeURIComponent(folder));
    yield put(dispatch(actions.executionFilesReceived(res)));
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* applyExecutionOrder(action) {
  try {
    const { study, folder } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.put, "sas/UpdatePriority/" + study + "/" + encodeURIComponent(folder));
    yield put(dispatch(actions.updatedExecutionOrderReceived(res)));
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* validateExecutionFiles(action) {
  try {
    const state = yield select();
    const { folder } = action.payload;
    const { studyDetail } = state.root;
    const toastId = uniqueId('ValidateExecutionFiles');
    yield put(dispatch(actions.initiateMessage(toastId, studyDetail.id, 'Validating order of execution...')));
    const api = yield getContext("api");
    const { sasExecutionFiles } = state.root.studyDetail;
    const urlEncodedFolder = encodeURIComponent(folder)
    const resTopologicSort = yield call(api.get, `sas/SuggestFileOrder/${studyDetail.id}/${urlEncodedFolder}`);
    yield put(dispatch(actions.validateExecutionFilesReceived(sasExecutionFiles, resTopologicSort)));

    yield delay(2500);
    yield put(dispatch(actions.toastClose(toastId)));

    return
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* getExecutionFolders(action) {
  try {
    yield put(dispatch(actions.getExecutionFoldersInitiated()));
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;

    const resfolders = yield call(api.get, `sas/ListFolders/${studyDetail.id}`);
    yield put(dispatch(actions.sasJobFoldersReceived(resfolders ? resfolders : [])));
    const res = yield call(api.get, "sas/GetExecutionFolders/" + studyDetail.id);
    yield put(dispatch(actions.executionFoldersReceived(res)));
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* setExecutionFile(action) {
  try {
    const { item, type } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;

    yield call(api.put, "sas/PutExecutionFileOrder/" + studyDetail.id + "/" + encodeURIComponent(studyDetail.sasjobsfolder) + "/" + encodeURIComponent(item["item"].id) + "/" + item["order"])
    yield put(dispatch(actions.getExecutionFiles(studyDetail.sasjobsfolder)))
    yield put(dispatch(actions.validateExecutionFiles(type, studyDetail.sasjobsfolder)))
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* setExecutionFolders(action) {
  try {
    const { item, type } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;

    yield call(api.put, "sas/PutExecutionFolderOrder/" + studyDetail.id + "/" + encodeURIComponent(item["item"].id) + "/" + item["order"])
    yield put(dispatch(actions.getExecutionFolders()))
    yield put(dispatch(actions.validateExecutionFolders(type)))
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* deleteExecutionFolder(action) {
  try {
    const { folder } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    yield call(api.put, "sas/RemoveExecutionFolder/" + studyDetail.id + "/" + encodeURIComponent(folder));
    yield put(dispatch(actions.sasJobsGetFolders()))
    yield put(dispatch(actions.getExecutionFolders()))
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* updateDependencies(action) {
  try {
    const { studyId, folder } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.put, "sas/UpdateDependencies/" + studyId + "/" + encodeURIComponent(folder));
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* deleteExecutionFile(action) {
  try {
    const { file } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    yield call(api.delete, "sas/DeleteExecutionFile/" + studyDetail.id + "/" + encodeURIComponent(studyDetail.sasjobsfolder) + "/" + encodeURIComponent(file));
    yield put(dispatch(actions.getExecutionFiles(studyDetail.sasjobsfolder)))
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

export function* sasJobSagas() {
  yield takeLatest(actions.SAS_JOB_UPDATE_PROGRAM, updateProgram);
  yield takeLatest(actions.SAS_JOB_CREATE_PROGRAM, createProgram);
  yield takeLatest(actions.SAS_JOB_DELETE_PROGRAM, deleteProgram);
  yield takeLatest(actions.SAS_JOB_DELETE_ALL, deleteAll);
  yield takeLatest(actions.SAS_JOB_INIT, initialize);
  yield takeLatest(actions.FILE_COMPARER_OPEN_MODAL, compareProgram)
  yield takeLatest(actions.STUDY_RUN_ALL_SAS_FILES, updatePrograms);
  yield takeLatest(actions.EXPORT_ALL_SASJOBS, exportAllSasJobs)
  yield takeLatest(actions.EXPORT_PROGRAMMING_PLAN, exportProgrammingPlan)
  yield takeLatest(actions.GET_EXECUTION_FOLDERS, getExecutionFolders)
  yield takeLatest(actions.VALIDATE_EXECUTION_FOLDERS, validateExecutionFolders)
  yield takeLatest(actions.VALIDATE_EXECUTION_FILES, validateExecutionFiles)
  yield takeLatest(actions.SET_EXECUTION_FOLDER, setExecutionFolders)
  yield takeLatest(actions.SET_EXECUTION_FILE, setExecutionFile)
  yield takeLatest(actions.DELETE_EXECUTION_FOLDER, deleteExecutionFolder)
  yield takeLatest(actions.GET_EXECUTION_FILES, getExecutionFiles)
  yield takeLatest(actions.APPLY_EXECUTION_ORDER, applyExecutionOrder)
  yield takeLatest(actions.DELETE_EXECUTION_FILE, deleteExecutionFile)
  yield takeLatest(actions.UPDATE_DEPENDENCIES, updateDependencies)
}
