import { parsePhoneNumberFromString } from "libphonenumber-js";

export const isValidEmail = (inp) => {
  // Simplified and more commonly used regex for email validation
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inp);
};

export const isValidPhone = (inp) => {
  if (!inp) return false;

  try {
    const phoneNumber = parsePhoneNumberFromString(inp);
    return phoneNumber ? phoneNumber.isValid() : false;
  } catch (error) {
    console.error("Error parsing phone number: ", error);
    return false;
  }
};

export const parsePhoneNumber = (value) => {
  try {
    // Remove non-digit characters and prepend '+', then parse
    return parsePhoneNumberFromString("+" + value.replace(/\D/g, ""));
  } catch (error) {
    console.error("Error parsing phone number: ", error);
    return null;
  }
};
