import React from "react";
import PropTypes from "prop-types";
//import { withStyles } from "@mui/material/styles";
import UploadFilesV2 from "../UploadFilesV2";
import Modal from "./Modal";
//import { useLocation } from "react-router-dom";

const styles = ({
  paper: {
    width: 855,
    height: 445,
    borderRadius: "8px"
  },
  dialog: {
    overflow: "hidden"
  }
});

const UploadDialog = ({
  viewMode,
  /*actions,*/
  onClose,
  permissions,
  /*classes,*/
  uploading,
  uploadSucces,
  potentialUploads,
  potentialUploadsWithStatus,
  selectedUploadDownloadfolder,
  uploadFolders
}) => {
  //const location = useLocation();

  // Now you can use `location` as needed
  // ...
  console.log("UploadDialog " + viewMode)
  return (
    <Modal
      onClose={onClose}
      classes={styles}
      maxWidth="lg"
      open={true}
      title={"Upload files"}
      titleBackground
    >
      <UploadFilesV2
        viewMode={viewMode}
        uploading={uploading}
        uploadSucces={uploadSucces}
        permissions={permissions}
        /*actions={actions}*/
        potentialUploads={potentialUploads}
        potentialUploadsWithStatus={potentialUploadsWithStatus}
        selectedUploadDownloadfolder={selectedUploadDownloadfolder}
        uploadFolders={uploadFolders}
      />
    </Modal>
  );
};

UploadDialog.propTypes = {
  //classes: PropTypes.object.isRequired,
  //location: PropTypes.object.isRequired,
  viewMode: PropTypes.string,
  selectedUploadDownloadfolder: PropTypes.array,
  uploading: PropTypes.bool,
  uploadSucces: PropTypes.bool,
  uploadFolders: PropTypes.array,
  downloadFolders: PropTypes.array,
  potentialUploads: PropTypes.array,
  selectedUploadDownloadfolder: PropTypes.array,
  uploadFolders: PropTypes.array
};

export default UploadDialog;
