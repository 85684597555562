import { createSelector } from "reselect";

const isOpen = state => state.permissions.open;
export const selectDashBoardPermissisons = state => state.root.studyDetail?.dashboardPermissions;
export const selectPermissisonDetail = state => state.permissions;

export const isAdminPermissionModalOpen = createSelector(
  [isOpen],
  open => {
    return open;
  }
);
export const getPermissionDetail = createSelector(
  [selectPermissisonDetail],
  permissisons => {
    return permissisons;
  }
);
export const signupUserName = state => state.signup.userName;
export const signupPassword = state => state.signup.password;
export const signupPhone = state => state.signup.phone;


export const getSignup = createSelector(
  [signupUserName, signupPassword, signupPhone],
  (userName, password, phone) => {
    return { userName, password, phone };
  }
);

export const resetPasswordPassword = state => state.userProfile.confirmUserAttributesModal.password;
export const resetPasswordOldPassword = state => state.userProfile.confirmUserAttributesModal.oldPassword;
export const userProfilePhone = state => state.userProfile.confirmUserAttributesModal.phoneNumber;

export const getUserProfilePhoneNumber = state => state.userProfile.confirmUserAttributesModal.phone;

export const getVerificationCode = state => state.userProfile.confirmUserAttributesModal.verificationCode;


export const getRestPasswordFields = createSelector(
  [resetPasswordPassword, resetPasswordOldPassword],
  (password, oldPassword) => {
    return { password, oldPassword };
  }
);

export const selectStudies = state => state.root.studies;
export const selectUserId = state => state.root.userId;
export const selectStudiesLoaded = state => state.root.studiesLoaded;

export const getStudiesAndStudiesLoaded = createSelector(
  [selectStudies, selectStudiesLoaded],
  (studies, studiesLoaded) => {
    return { studies, studiesLoaded };
  }
);
const getUserProfileEmail = state => state.userProfile.email;
const getUserCustomerId = state => state.userProfile.customerId;

export const getEmailAndCustomer = createSelector(
  [getUserProfileEmail, getUserCustomerId],
  (email, customerId) => {
    return { email, customerId };
  }
);

export const getStudyInviteState = state => state.studyInvite;

export const getCreateStudyState = state => state.createStudy;

export const selectInboxOpen = state => state.userProfile?.inboxOpen;
export const selectFileReviewOpen = state => state.fileReview?.open;
export const selectFileVersionsOpen = state => state.fileVersions?.open;
export const selectSasJobComparer = state => state.sasJob?.Comparer;
export const selectStudyDetailComparer = state => state.root.studyDetail?.Comparer;
export const selectToasts = state => state.toasts;

export const selectUserProfile = state => state.userProfile;
export const selectStudyDetail = state => state.root.studyDetail;

export const selectIssues = state => state.issues;
export const selectCognitoUser = state => state.root.cognitoUser;
export const selectShowOnboarding = state => state.root.showOnboarding;

export const selectVisible = state => state.root.visible;