import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions/";
import { withRouter } from "react-router-dom";
import { isValidEmail } from "../../utils/validation";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
//import { withStyles } from "@mui/material/styles";
import loginStyles from "../../auth/LoginStyles";
import Button from "../../ui/Button";
import PermissionTable from "../components/PermissionsTable";
import AutoCompleteField from "../../ui/AutoCompleteField";
import ModalTitle from "../components/ModalTitle";
import HorizontalStepper from "./HorizontalStepper";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
//import { Scrollbars } from "react-custom-scrollbars";
import { DeveloperBoard } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';

const paperStyles = theme => ({
  ...theme.typography,
  paper: {
    width: 800,
    height: 350,
    borderRadius: '8px'
  },
  paperLarge: {
    width: 1000,
    height: 700,
    borderRadius: '8px'
  },
  dialog: {
    padding: 0,
    overflow: "hidden"
  },
  createContent: {
    width: "60%",
    position: "relative",
    margin: "2rem auto"
  },
  buttonWrapper: {
    position: "absolute",
    right: "0",
    top: "1rem",
    background: "#0273b5",
    border: 0,
    color: "white",
    padding: "0 1rem",
    height: "2rem",
    borderRadius: "2rem",
    minWidth: "5rem",
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#0273b5"
    },
    ...theme.typography
  },
  buttonWrapperBack: {
    position: "absolute",
    top: "1rem",
    right: '130px',
    background: "pink",
    border: 0,
    color: "white",
    padding: "0 1rem",
    height: "2rem",
    borderRadius: "2rem",
    minWidth: "5rem",
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "pink"
    },
    ...theme.typography
  },
  content: {
    width: "90%",
    margin: "1rem auto"
  },
  permissionTable: {
    margin: "2.2rem auto"
  },
  userSelect: {
    position: "relative",
    top: "2.5rem",
    left: '4.5rem',
    display: "inline-block",
    verticalAlign: "text-top",
    height: "7rem",
    width: "50%",
    boxSizing: "border-box"
  },
  stepper: {
    width: "100%",
    left: "0.5rem",
    top: "1rem"
  },
  startBtn: {
    right: "5rem",
    top: "-3.6rem"
  },
  spinner: {
    position: "absolute",
    top: '4rem',
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto"
  },
  icon: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "45%",
    left: "50%",
    fontSize: "4rem",
    color: /*theme.palette.primary.light*/ "#4caf50",
    transform: `translate3d(-50%, -0%, 0)`
  },
  succesLabel: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "75%",
    left: "50%",
    fontSize: "0.7rem",
    transform: `translate3d(-50%, -50%, 0)`
  },
  errorLabel: {
    ...theme.typography,
    position: 'absolute',
    top: '4rem',
    left: '4rem',
    fontSize: '0.7rem',
    color: 'red',

  },

});

const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});

const StudyInviteUser = (props) => {

  const { studyId,
    userEmail,
    permissions,
    loading,
    userEmails,
    onClose,
    step,
    open,
  } = props;

  const [showUserlist, setShowUserlist] = useState(false);
  const [init, setInit] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailError, setEmailError] = useState("");

  const dispatch = useDispatch();


  const initialStudyFetch = () => {
    if (!init) {
      setInit(true)
      dispatch(actions.studyInviteFetchData(studyId));
    }
  };

  useEffect(() => {
    initialStudyFetch();
    return () => {
      // componentwillunmount in functional component.
      // Anything in here is fired on component unmount.
      dispatch(actions.studyInviteReset());
    }
  }, []);

  const handleKeyPress = e => {

    if (e.key === "Enter") {
      if (isValidEmail(userEmail)) {
        dispatch(actions.studyInviteShowPermissions());
      } else {
        setEmailError("Please enter a valid e-mail")
      }
    }
  };

  const handleChange = value => {
    const emailValid = isValidEmail(value);
    if (emailError && emailValid) {
      setEmailError(undefined)
    }
    dispatch(actions.studyInviteUpdateInput("userEmail", value));
  };

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const handleNextClick = () => {
    if (isValidEmail(userEmail)) {
      dispatch(actions.studyInviteShowPermissions());
    }
    else {
      if (!emailError) {
        setEmailError("Please enter a valid e-mail")
      }
    }
  }

  const handleClick = () => {
    dispatch(actions.studyInviteSubmit());
  };

  const handleUserPopup = () => {
    setShowUserlist(!this.state.showUserlist)
  };

  const hasPermissions = () => {
    let cnt = 0;
    permissions &&
      permissions.length &&
      permissions.forEach(item => {
        if (item.selected) {
          cnt++;
        }
      });
    if (cnt > 0) {
      return true;
    } else {
      return false;
    }
  };

  let selectablePermissions = []
  if (permissions && step === 1) {
    if (userEmail.split("@")[1].toUpperCase() !== "ME-TA.DK") {
      selectablePermissions = permissions.map(perm => {
        let item
        (perm.permission === "SASUPLOAD") ?
          item = { ...perm, disabled: true } :
          item = { ...perm, disabled: false }
        return item
      })
    }
    else {
      selectablePermissions = permissions.map(perm => {
        return { ...perm, disabled: false }
      })
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown={false}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      maxWidth="lg"
      sx={{
        paper: step === 1 ? styles.paperLarge : styles.paper
      }}
      open={open}
    >
      <ModalTitle id="customized-dialog-title" onClose={onClose} background>
        <GroupAddIcon style={{ color: "#004884", top: "5px", position: "relative" }} /> Invite user
      </ModalTitle>

      <DialogContent sx={{ root: styles.dialog }}>
        <div>
          <HorizontalStepper
            activeStep={step}
            sx={{
              root: styles.stepper
            }}
          />
          {step === 0 && (
            <div className={styles.content}>
              <div
                className={styles.userSelect}
                onKeyPress={handleKeyPress}
              >
                <AutoCompleteField
                  initialValue={userEmail}
                  placeholder={'Input e-mail here'}
                  allSuggestions={userEmails.map(item => {
                    return { label: item };
                  })}
                  onSelect={handleChange}
                />
                {emailError && <p className={styles.errorLabel}>{emailError}</p>}
              </div>

              <div className={styles.relative}>
                <Button
                  id="inviteNext"
                  className={classNames(
                    styles.buttonWrapper,
                    styles.startBtn
                  )}
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {step === 1 && permissions && permissions.length > 0 && (
            <div
              className={classNames(
                styles.content,
                styles.permissionTable
              )}
            >
              <div style={{ height: 300 }}>
                <PermissionTable
                  permissions={/*permissions*/ selectablePermissions}
                  selectAll={() => dispatch(actions.studyInvitePermissionsToggleAll())}
                  permissionToggle={() => dispatch(actions.studyInvitePermissionsToggle())}
                />
              </div>
              <div className={styles.relative}>
                <Button
                  id="inviteBack"
                  sx={styles.buttonWrapperBack}
                  onClick={() => dispatch(actions.studyInviteShowUsers())}
                >
                  Back
                </Button>
                <Button
                  id="inviteSend"
                  sx={styles.buttonWrapper}
                  disabled={loading || !hasPermissions()}
                  onClick={handleClick}
                >
                  Send
                </Button>
              </div>


            </div>
          )}
          {step === 2 && <div className={styles.content}>
            {loading && (
              <CircularProgress
                sx={styles.spinner}
                size={48}
              />
            )}
            {!loading &&
              <CheckCircleIcon sx={styles.icon} />
            }
            {!loading && <p className={styles.succesLabel}>Invite sent to {userEmail}</p>}
          </div>}

        </div>
      </DialogContent>
    </Dialog>
  );
}

/*
StudyInviteUser.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
*/
export default (StudyInviteUser);
