import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formatDateTime } from "../..//utils/date";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
//import { withStyles } from "@mui/material/styles";
import loginStyles from "../../auth/LoginStyles";
import { useSelector, useDispatch } from 'react-redux';

const paperStyles = theme => ({
  paper: {
    width: 550
  },
  requestBtn: {
    display: "inline-block",
    background: "#0273b5",
    "&:hover": {
      cursor: "pointer",
      background: "black"
    },
    transition: "all 180ms ease-out",
    padding: "0 0.8rem"
  },
  rejectBtn: {
    display: "inline-block",
    background: "#e91e63",
    "&:hover": {
      cursor: "pointer",
      background: "black"
    },
    transition: "all 180ms ease-out",
    padding: "0 0.8rem",
    marginRight: "0.25rem"
  },
  statusItem: {
    color: "white",
    textAlign: "center",
    borderRadius: "18px",
    height: "1.2rem",
    lineHeight: "1.2rem",
    fontSize: "0.6rem",
    fontWeight: 500,
    top: "0.2rem",
    minHeight: "1rem",

    ...theme.typography
  },
  content: {
    width: "90%",
    margin: "0 auto"
  },
  h3: {
    ...theme.typography,
    fontSize: "1rem",
    color: theme.palette.grey[500],
    marginTop: "0.7rem"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  maillist: {
    marginTop: "2rem"
  },
  mailItem: {
    ...theme.typography,
    height: "4rem",
    width: "100%",
    padding: "0.5rem",
    borderBottom: "1px dotted #424141",
    background: "white",
    position: "relative",
    "&:hover": {
      background: "#f9f9f9"
    }
  },
  mailDate: {
    ...theme.typography,
    fontSize: "0.6rem",
    color: theme.palette.grey[500],
    position: "absolute",
    right: "0.5rem",
    top: "0.5rem"
  },
  mailFrom: {
    ...theme.typography,
    fontSize: ".6rem",
    color: theme.palette.grey[500]
  },
  mailTitle: {
    ...theme.typography,
    fontSize: "0.7rem",
    color: theme.palette.grey[800],
    marginTop: "0.2rem",
    maxWidth: "70%"
  },
  mailActions: {
    position: "absolute",
    right: "0.5rem",
    top: "2rem"
  },
  tabs: {
    position: 'absolute',
    top: '1.75rem',
  },
  tab: {
    ...theme.typography,
    fontSize: "0.7rem",
    position: "relative",
    display: "inline-block",
    color: theme.palette.grey[500],
    marginRight: "1rem",
    "&:after": {
      content: "''",
      position: "absolute",
      top: "0.05rem",
      right: "-0.5rem",
      height: "0.5rem",
      width: "1px",
      background: "#673ab7"
    }
  },
  tab2: {
    ...theme.typography,
    color: theme.palette.grey[500],
    fontSize: "0.7rem",
    position: "relative",
    display: "inline-block",
    marginRight: "0.5rem"
  },
  tabHover: {
    cursor: "pointer",
    fontSize: "0.6rem",
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  tabSelected: {
    color: theme.palette.grey[800]
  },
  spinner: {
    position: 'absolute',
    top: '-0.5rem',
    right: '2.5rem'
  }
});
const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});

const Inbox = () => {
  const [open, setOpen] = React.useState(false);
  const [showRejected, setShowRejected] = React.useState(false);
  const { studyInvites } = useSelector(state => state.userProfile?.studyInvites);

  const pending = studyInvites && studyInvites.length ? studyInvites.filter(item => !item.invitationRejectedDateTime) : [];
  const rejected = studyInvites && studyInvites.length ? studyInvites.filter(item => item.invitationRejectedDateTime) : [];
  const invites = showRejected ? rejected : pending;

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 10);

  }, []);

  const toggleDrawer = () => {
    setOpen(false);
    setTimeout(() => {
      dispatch(actions.userProfileToggleInbox());
    }, 150);
  };

  const handleTabClick = evt => {
    setShowRejected(evt.target.id === 'rejected')
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer}
      sx={{
        paper: styles.paper
      }}
    >
      <div className={styles.content}>
        <IconButton
          aria-label="Close"
          sx={styles.closeButton}
          onClick={toggleDrawer}
        >
          <CloseIcon />
        </IconButton>
        <h3 className={styles.h3}>Inbox</h3>
        <div className={styles.tabs}><span
          id="pending"
          className={classNames(
            styles.tab,
            styles.tabHover,
            !showRejected ? styles.tabSelected : ""
          )}
          onClick={handleTabClick}
        >
          Pending ({pending.length})
        </span>
          <span
            id="rejected"
            className={classNames(
              styles.tab2,
              styles.tabHover,
              showRejected ? styles.tabSelected : ""
            )}
            onClick={handleTabClick}
          >
            Rejected ({rejected.length})
          </span>
        </div>
        {studyInvites && studyInvites.length > 0 && (
          <ul className={styles.maillist}>
            {invites.map(item => {
              return (
                <li
                  key={item.studyUserInvitationId}
                  className={styles.mailItem}
                >
                  <div className={styles.mailFrom}>
                    {item.contactName}
                  </div>
                  <div className={styles.mailDate}>
                    {formatDateTime(item.invitationSendDateTime)}
                  </div>
                  <div className={styles.mailTitle}>
                    Invite to: {item.studyName},{" "}
                    {item.studyProject}
                  </div>

                  <div className={styles.mailActions}>
                    {item.loading && <CircularProgress size={20} sx={styles.spinner} />}
                    {!item.loading && <span
                      className={classNames(
                        styles.rejectBtn,
                        styles.statusItem
                      )}
                      onClick={() =>
                        dispatch(actions.userProfileResolveInvitation(
                          false,
                          item.studyUserInvitationId
                        ))
                      }
                    >
                      Reject
                    </span>}
                    {!item.loading && <span
                      className={classNames(
                        styles.requestBtn,
                        styles.statusItem
                      )}
                      onClick={() =>
                        dispatch(actions.userProfileResolveInvitation(
                          true,
                          item.studyUserInvitationId
                        ))
                      }
                    >
                      Accept
                    </span>}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </Drawer>
  );
}

/*
Inbox.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return state.userProfile;
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((Inbox));
*/
export default Inbox