
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/material/styles';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';

const styles = theme => ({
  root: {
    width: '118%',
    padding: 0,
    fontSize: 10,
    position: 'relative',
    left: '-3.3rem',
    top: '1.5rem',
    background: 'transparent',
    backgroundColor: 'transparent'
  },
  label: {
    fontSize: 10
  },
  bg: {
    backgroundColor: 'transparent',
    padding: 0
  },
  ...theme.typography

});

export const getSteps = () => {
  return ['Add recepient e-mail', 'Assign permissions', 'Send invite'];
}

const HorizontalLabelPositionBelowStepper = (props) => {
  const { activeStep } = props;

  const steps = getSteps();
  return (
    <div style={styles.root}>
      <Stepper activeStep={activeStep} alternativeLabel sx={styles.bg}>
        {steps.map(label => (
          <Step key={label} sx={styles.label}>
            <StepLabel sx={styles.label}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}


export default (HorizontalLabelPositionBelowStepper);