export const FETCH_ACCESS_LOG_INFO = 'FETCH_ACCESS_LOG_INFO';
export const FETCH_LIST_ACCESS_LOG_EXTRACTS = 'FETCH_LIST_ACCESS_LOG_EXTRACTS';
export const FETCH_ACCESS_LOG_EXTRACT_2_DB = 'FETCH_ACCESS_LOG_EXTRACT_2_DB';
export const FETCH_ACCESS_LOG_PARTITION_UPDATE = 'FETCH_ACCESS_LOG_PARTITION_UPDATE';
export const FETCH_ACCESS_LOG_EXTRACT = 'FETCH_ACCESS_LOG_EXTRACT';
export const FETCH_ADMIN_ENABLE_ACCESS_LOG = 'FETCH_ADMIN_ENABLE_ACCESS_LOG';

export const ACCESS_LOG_INFO_RECEIVED = 'ACCESS_LOG_INFO_RECEIVED';
export const LIST_ACCESS_LOG_EXTRACTS_RECEIVED = 'LIST_ACCESS_LOG_EXTRACTS_RECEIVED';
export const ACCESS_LOG_EXTRACT_2_DB_RECEIVED = 'ACCESS_LOG_EXTRACT_2_DB_RECEIVED';
export const ACCESS_LOG_PARTITION_UPDATE_RECEIVED = 'ACCESS_LOG_PARTITION_UPDATE_RECEIVED';
export const ACCESS_LOG_EXTRACT_RECEIVED = 'ACCESS_LOG_EXTRACT_RECEIVED';
export const ADMIN_ENABLE_ACCESS_LOG_RECEIVED = 'ADMIN_ENABLE_ACCESS_LOG_RECEIVED';

export const TEST_API = 'TEST_API';
export const SPECS_RECEIVED = 'SPECS_RECEIVED';
export const GET_STUDIES = 'GET_STUDIES';
export const GET_STUDIES_V2 = 'GET_STUDIES_V2'
export const STUDIES_RECEIVED = 'STUDIES_RECEIVED';
export const CLEAR_STUDIES = 'CLEAR_STUDIES'
export const GET_SASJOBS = 'GET_SASJOBS';
export const SASJOBS_RECEIVED = 'SASJOBS_RECEIVED';
export const SASJOBS_GET_PAGING = 'SASJOBS_GET_PAGING'
export const SASJOBS_RESET_PAGING = 'SASJOBS_RESET_PAGING'
export const SASJOBS_RECEIVED_PAGING = 'SASJOBS_RECEIVED_PAGING'
export const SASJOBS_QC_STATUS_ADD = 'SASJOBS_QC_STATUS_ADD'
export const SASJOBS_GET_FOLDERS = 'SASJOBS_GET_FOLDERS'
export const SASJOBS_FOLDERS_RECEIVED = 'SASJOBS_FOLDERS_RECEIVED'
export const SASJOBS_GET_FILES = 'SASJOBS_GET_FILES'
export const SASJOBS_FILES_RECEIVED = 'SASJOBS_FILES_RECEIVED'
export const GET_SASJOB_FILES_FOLDERS = 'GET_SASJOB_FILES_FOLDERS'
export const GET_SASJOB_PAGING = 'GET_SASJOB_PAGING'
export const AUTHENTICATED = 'AUTHENTICATED';
export const SELECT_STUDY = 'SELECT_STUDY';
export const STUDY_FILE_RESET_PAGING = 'STUDY_FILE_REST_PAGING'
export const STUDY_FILE_RESET_PAGING_WITH_FOLDER = 'STUDY_FILE_RESET_PAGING_WITH_FOLDER'
export const GET_STUDY_PATH = 'GET_STUDY_PATH'
export const GET_STUDY_PATH_PAGING = 'GET_STUDY_PATH_PAGING'
export const GET_STUDY_FILES_FOLDERS = 'GET_STUDY_FILES_FOLDERS';
export const GET_STUDY_FILES_FOLDERS_WITH_FOLDER = 'GET_STUDY_FILES_FOLDERS_WITH_FOLDER';
export const STUDY_FILES_FOLDERS = 'STUDY_FILES_FOLDERS'
export const STUDY_UPLOADS_RECEIVED = 'STUDY_UPLOADS_RECEIVED';
export const STUDY_UPLOADS_RECEIVED_PAGING = 'STUDY_UPLOADS_RECEIVED_PAGING';
export const GET_STUDY_PATH_PAGING_FINISHED = 'GET_STUDY_PATH_PAGING_FINISHED'
export const STUDY_FILE_FOLDERS_RECEIVED = 'STUDY_FILE_FOLDERS_RECEIVED'
export const DELETE_SUCCESS = 'DELETE_SUCCESS'
export const GET_STUDY_PERMISSIONS_FOR_DASHBOARD = 'GET_STUDY_PERMISSIONS_FOR_DASHBOARD';
export const GET_ISSUES_FOR_DASHBOARD = 'GET_ISSUES_FOR_DASHBOARD';
export const STUDY_PERMISSIONS_FOR_DASHBOARD_RECEIVED = 'STUDY_PERMISSIONS_FOR_DASHBOARD_RECEIVED';
export const ISSUES_FOR_DASHBOARD_RECEIVED = 'ISSUES_FOR_DASHBOARD_RECEIVED';
export const FETCH_STUDY_PERMISSIONS = 'FETCH_STUDY_PERMISSIONS';
export const STUDY_PERMISSIONS_RECEIVED = 'STUDY_PERMISSIONS_RECEIVED';
export const APPLY_SAS_REGEX = 'APPLY_SAS_REGEX';
export const APPLY_UPLOAD_REGEX = 'APPLY_UPLOAD_REGEX';
export const APPLY_DOWNLOAD_REGEX = 'APPLY_DOWNLOAD_REGEX';
export const APPLY_RELEASE_REGEX = 'APPLY_RELEASE_REGEX';
export const SHOW_IDLE_MODAL = 'SHOW_IDLE_MODAL';
export const STUDY_GET_JSON = 'STUDY_GET_PAYER_JSON'
export const STUDY_GET_CONFIG_CSV = 'STUDY_GET_CONFIG_CSV'
export const STUDY_PAYER_JSON_RECEIVED = 'STUDY_PAYER_JSON_RECEIVED'
export const STUDY_CONFIG_JSON_RECEIVED = 'STUDY_CONFIG_JSON_RECEIVED'
export const STUDY_CONFIG_CSV_RECEIVED = 'STUDY_CONFIG_CSV_RECEIVED'
export const PAYER_FILTER_CHANGED = 'PAYER_FILTER_CHANGED'
export const RESET_PAYER_FILTER = 'RESET_PAYER_FILTER'
export const SET_STUDY_FILE_FOLDER = 'SET_STUDY_FILE_FOLDER'
export const SET_SAS_JOB_FOLDER = 'SET_SAS_JOB_FOLDER'
export const GET_ADMIN_ON_STUDY = 'GET_ADMIN_ON_STUDY'
export const SET_EXECUTION_FOLDER = 'SET_EXECUTION_FOLDER'
export const SET_EXECUTION_FILE = 'SET_EXECUTION_FILE'
export const SET_EXECUTION_GROUP = 'SET_EXECUTION_GROUP'
export const VALIDATE_EXECUTION_FOLDERS = 'VALIDATE_EXECUTION_FOLDERS'
export const VALIDATE_EXECUTION_FILES = 'VALIDATE_EXECUTION_FILES'
export const VALIDATION_EXECUTION_FOLDERS_RECEIVED = 'VALIDATION_EXECUTION_FOLDERS_RECEIVED'
export const EXECUTION_FOLDERS_SET = 'EXECUTION_FOLDERS_SET'
export const EXECUTION_FILES_SET = 'EXECUTION_FILES_SET'
export const EMPTY_STUDY_FILE_FOLDERS = 'EMPTY_STUDY_FILE_FOLDERS'
export const EMPTY_SASJOB_FILE_FOLDERS = 'EMPTY_SASJOB_FILE_FOLDERS'
export const REFRESH_STUDY_FILE_FOLDER = 'REFRESH_STUDY_FILE_FOLDER'
export const REFRESH_SASJOB_FOLDER = 'REFRESH_SASJOB_FOLDER'
export const RESET_FILTERS = 'RESET_FILTERS'
export const PREVIEW_TEXT_FILE = 'PREVIEW_TEXT_FILE'
export const STUDY_FILE_INIT = 'STUDY_FILE_INIT'
export const STUDY_FILE_INIT_V2 = 'STUDY_FILE_INIT_V2'
export const STUDY_FILE_PLACE_INITIAL_JOB = 'STUDY_FILE_PLACE_INITIAL_JOB'
export const STUDY_FILE_PLACE_COMPARER_JOB = 'STUDY_FILE_PLACE_COMPARER_JOB'
export const STUDY_FILE_COMPARER_OPEN_MODAL = 'STUDY_FILE_COMPARER_OPEN_MODAL'
export const STUDY_FILE_COMPARER_RESET = 'STUDY_FILE_COMPARER_RESET'
export const START_REFRESH_FOLDER_FILES = 'START_REFRESH_FOLDER_FILES'
export const STORE_API_URL = 'STORE_API_URL'
export const SHOW_ONBOARDING = 'SHOW_ONBOARDING'
export const HIDE_ONBOARDING = 'HIDE_ONBOARDING'
export const STUDIES_RECEIVED_V2 = 'STUDIES_RECEIVED_V2'
export const RECEIVED_STUDY = 'RECEIVED_STUDY'

export const FILES_CHANGED_AFTER_DATE = 'FILES_CHANGED_AFTER_DATE'
export const FILES_CHANGED_AFTER_DATE_FINISHED = 'FILES_CHANGED_AFTER_DATE_FINISHED'
export const STUDY_FILE_FOLDER_RECEIVED = 'STUDY_FILE_FOLDER_RECEIVED'

export const SAS_FILES_CHANGED_AFTER_DATE = 'SAS_FILES_CHANGED_AFTER_DATE'
export const SAS_FILES_CHANGED_AFTER_DATE_FINISHED = 'SAS_FILES_CHANGED_AFTER_DATE_FINISHED'

export const REFRESHEVERYXMINUTES = 'REFRESHEVERYXMINUTES'
export const STUDY_FILE_RESET = 'STUDY_FILE_RESET'
export const CLEAR_SELECTED_FOLDERS = 'CLEAR_SELECTED_FOLDERS'
export const CLEAR_SELECTED_FOLDER = 'CLEAR_SELECTED_FOLLDER'
export const CLEAR_SELECTED_FOLDER_BEGIN = 'CLEAR_SELECTED_FOLDER_BEGIN'
export const CLEAR_SELECTED_SAS_FOLDER = 'CLEAR_SELECTED_SAS_FOLDER'
export const CLEAR_SELECTED_SAS_FOLDER_BEGIN = 'CLEAR_SELECTED_SAS_FOLDER_BEGIN'

export const PAGING_BUTTON_CLICKED = 'PAGING_BUTTON_CLICKED'
export const PAGING_BUTTON_CLICKED_SAS = 'PAGING_BUTTON_CLICKED_SAS'
export const PAGING_BUTTON_CLICKED_RELEASED = 'PAGING_BUTTON_CLICKED_RELEASED'
export const STUDY_FILE_DONWLOAD_SAS_JOB = 'STUDY_FILE_DONWLOAD_SAS_JOB'

export const RECEIVED_SIMPLE_STUDY = 'RECEIVED_SIMPLE_STUDY'
export const GET_STUDIES_WITH_PROJECTS = 'GET_STUDIES_WITH_PROJECTS'
export const RECEIVED_QC_JOB = 'RECEIVED_QC_JOB'

export const UPDATE_POTENTIAL_UPLOADS = 'UPDATE_POTENTIAL_UPLOADS'
export const UPLOAD_MD5_EXISTS = 'UPLOAD_MD5_EXISTS'
export const STORE_POTENTIAL_UPLOADS = 'STORE_POTENTIAL_UPLOADS'
export const POTENTIAL_UPLOAD_STATUS_DETERMINED = 'POTENTIAL_UPLOAD_STATUS_DETERMINED'
export const CLEAR_POTENTIAL_UPLOADS = 'CLEAR_POTENTIAL_UPLOADS'
export const UPDATE_UPLOAD_STATUS_ON_UPLOAD = 'UPDATE_UPLOAD_STATUS_ON_UPLOAD'
export const UPDATE_UPLOAD_PROGRESS_ON_UPLOAD = 'UPDATE_UPLOAD_PROGRESS_ON_UPLOAD'
export const EDIT_FOLDER_ON_POTENTIAL_UPLOAD = 'EDIT_FOLDER_ON_POTENTIAL_UPLOAD'
export const GET_SAS_ISSUES = 'GET_SAS_ISSUES'
export const SAS_ISSUES_RECEIVED = 'SAS_ISSUES_RECEIVED'

export const START_CORRECTING_ISSUES = 'START_CORRECTING_ISSUES'
export const FINISH_CORRECTING_ISSUES = 'FINISH_CORRECTING_ISSUES'
export const CORRECT_ISSUES_FROM_DASHBOARD = 'CORRECT_ISSUES_FROM_DASHBOARD'
export const RESET_STUDY_FILE_RO = 'RESET_STUDY_FILE_RO'
export const RESET_SAS_JOB_RO = 'RESET_SAS_JOB_RO'
export const RECEIVED_ADMIN_ON_STUDY = 'RECEIVED_ADMIN_ON_STUDY'

export const REFRESH_PAGE_BY_TYPE = 'REFRESH_PAGE_BY_TYPE'

export const refreshPage = (type, selected, id) => ({
    type: REFRESH_PAGE_BY_TYPE,
    payload: { type, selected, id }
})

export const updateUploadStatusOnUpload = (status, upload) => ({
    type: UPDATE_UPLOAD_STATUS_ON_UPLOAD,
    payload: { status, upload }
})
export const updateUploadProgressOnUpload = (progress, upload) => ({
    type: UPDATE_UPLOAD_PROGRESS_ON_UPLOAD,
    payload: { progress, upload }
})

export const uploadFileStatusDetermined = (upload) => ({
    type: POTENTIAL_UPLOAD_STATUS_DETERMINED,
    payload: { upload }
})
export const editFolderOnPotentialUpload = (id, value) => ({
    type: EDIT_FOLDER_ON_POTENTIAL_UPLOAD,
    payload: { id, value }
})


export const updatePotentialUploads = (files) => ({
    type: UPDATE_POTENTIAL_UPLOADS,
    payload: { files }
})

export const storePotentialUploads = (files) => ({
    type: STORE_POTENTIAL_UPLOADS,
    payload: { files }
})
export const clearPotentialUploads = () => ({
    type: CLEAR_POTENTIAL_UPLOADS,
    payload: {}
})

export const showOnboarding = () => ({
    type: SHOW_ONBOARDING,
    payload: {}
})

export const hideOnboarding = () => ({
    type: HIDE_ONBOARDING,
    payload: {}
})

export const clearStudiesStudyDetail = () => ({
    type: CLEAR_STUDIES,
    payload: {}
})

export const checkUploadExists = (file) => ({
    type: UPLOAD_MD5_EXISTS,
    payload: { file }
})

export const storeApiURL = (apiURL) => ({
    type: STORE_API_URL,
    payload: { apiURL }
})

export const fileComparerStudyFileReset = () => ({
    type: STUDY_FILE_COMPARER_RESET,
    payload: {}
});
export const studyFileReset = () => ({
    type: STUDY_FILE_RESET,
    payload: {}
});

export const studyFilePlaceComparerJob = (studyId, studyFile, fileContent) => ({
    type: STUDY_FILE_PLACE_COMPARER_JOB,
    payload: { studyId, studyFile, fileContent }
});

export const studyfileComparerOpenModal = (studyId, studyFileId) => ({
    type: STUDY_FILE_COMPARER_OPEN_MODAL,
    payload: { studyId, studyFileId }
});

export const studyFilePlaceInitialJob = (studyId, studyFile, versions, content, contentType, latest) => ({
    type: STUDY_FILE_PLACE_INITIAL_JOB,
    payload: { studyId, studyFile, versions, content, contentType, latest }
});

export const previewTextFile = (id) => ({
    type: PREVIEW_TEXT_FILE,
    payload: { id }
})

export const resetFilters = () => ({
    type: RESET_FILTERS,
    payload: {}
})

export const setExecutionFolder = (item, type) => ({
    type: SET_EXECUTION_FOLDER,
    payload: { item, type }
});

export const executionFoldersSet = (items, type) => ({
    type: EXECUTION_FOLDERS_SET,
    payload: { items, type }
})

export const setExecutionFile = (item, type) => ({
    type: SET_EXECUTION_FILE,
    payload: { item, type }
})

export const setExecutionGroup = (type) => ({
    type: SET_EXECUTION_GROUP,
    payload: { type }
})

export const validateExecutionFolders = (type) => ({
    type: VALIDATE_EXECUTION_FOLDERS,
    payload: { type }
})

export const validateExecutionFiles = (type, folder) => ({
    type: VALIDATE_EXECUTION_FILES,
    payload: { type, folder }
})

export const showIdleModal = state => ({
    type: SHOW_IDLE_MODAL,
    payload: { state }
});

export const authenticated = (isAuth = true) => ({
    type: AUTHENTICATED,
    payload: { isAuth }
});
export const testApi = () => ({
    type: TEST_API,
    payload: {}
});

export const issuesForDashboardReceived = (issues, studyId) => ({
    type: ISSUES_FOR_DASHBOARD_RECEIVED,
    payload: { issues, studyId }
});
export const startCorrectingIssues = () => ({
    type: START_CORRECTING_ISSUES,
    payload: {}
})
export const finishCorrectingIssues = () => ({
    type: FINISH_CORRECTING_ISSUES,
    payload: {}
})

export const correctIssueFromDashboard = (key) => ({
    type: CORRECT_ISSUES_FROM_DASHBOARD,
    payload: { key }
})

export const sasIssuesReceived = (issues, studyId) => ({
    type: SAS_ISSUES_RECEIVED,
    payload: { issues, studyId }
})

export const getSasIssues = (studyId) => ({
    type: GET_SAS_ISSUES,
    payload: { studyId }
})

export const studyPermissionsForDashboardReceived = (permissions, studyId) => ({
    type: STUDY_PERMISSIONS_FOR_DASHBOARD_RECEIVED,
    payload: { permissions, studyId }
});

export const accessLogInfo = (id, customerId) => ({
    type: FETCH_ACCESS_LOG_INFO,
    payload: { id, customerId }
});

export const accessLogInfoReceived = (metadata, studyId) => ({
    type: ACCESS_LOG_INFO_RECEIVED,
    payload: { metadata, studyId }
});

export const listAccessLogExtracts = (id, customerId) => ({
    type: FETCH_LIST_ACCESS_LOG_EXTRACTS,
    payload: { id, customerId }
});

export const listAccessLogExtractsReceived = (extracts, studyId) => ({
    type: LIST_ACCESS_LOG_EXTRACTS_RECEIVED,
    payload: { extracts, studyId }
});

export const accessLogExtract2Db = (id, customerId) => ({
    type: FETCH_ACCESS_LOG_EXTRACT_2_DB,
    payload: { id, customerId }
});

export const accessLogExtract2DbReceived = (extracts, studyId) => ({
    type: ACCESS_LOG_EXTRACT_2_DB_RECEIVED,
    payload: { extracts, studyId }
});

export const accessLogPartitionUpdate = (id, customerId) => ({
    type: FETCH_ACCESS_LOG_PARTITION_UPDATE,
    payload: { id, customerId }
});

export const accessLogPartitionUpdateReceived = (partitions, studyId) => ({
    type: ACCESS_LOG_PARTITION_UPDATE_RECEIVED,
    payload: { partitions, studyId }
});

export const accessLogExtract = (id, year, month, customerId) => ({
    type: FETCH_ACCESS_LOG_EXTRACT,
    payload: { id, year, month, customerId }
});

export const accessLogExtractReceived = (s3key, studyId) => ({
    type: ACCESS_LOG_EXTRACT_RECEIVED,
    payload: { s3key, studyId }
});

export const adminEnableAccessLog = (id, customerId) => ({
    type: FETCH_ADMIN_ENABLE_ACCESS_LOG,
    payload: { id, customerId }
});

export const adminEnableAccessLogReceived = (partitions, studyId) => ({
    type: ADMIN_ENABLE_ACCESS_LOG_RECEIVED,
    payload: { partitions, studyId }
});

export const fetchStudyPermissions = (id, customerId) => ({
    type: FETCH_STUDY_PERMISSIONS,
    payload: { id, customerId }
});

export const getStudyPermissionsForDashboard = (studyId) => ({
    type: GET_STUDY_PERMISSIONS_FOR_DASHBOARD,
    payload: { studyId }
});
export const getIssuesForDashboard = (studyId) => ({
    type: GET_ISSUES_FOR_DASHBOARD,
    payload: { studyId }
});
export const studyPermissionsReceived = res => ({
    type: STUDY_PERMISSIONS_RECEIVED,
    payload: { res }
});

export const studyUploadsReceived = (files, fileType) => ({
    type: STUDY_UPLOADS_RECEIVED,
    payload: { files, fileType }
});

export const studyUploadsReceivedPaging = (paginatedResult, fileType) => ({
    type: STUDY_UPLOADS_RECEIVED_PAGING,
    payload: { paginatedResult, fileType }
});

export const emptyStudyFileFolders = (fileType) => ({
    type: EMPTY_STUDY_FILE_FOLDERS,
    payload: { fileType }

})
export const emptySasJobFileFolders = () => ({
    type: EMPTY_SASJOB_FILE_FOLDERS,
    payload: {}
})

export const getStudyUploadsFinished = (folder) => ({
    type: GET_STUDY_PATH_PAGING_FINISHED,
    payload: { folder }
})

export const studyFileFoldersReceived = (folders, fileType) => ({
    type: STUDY_FILE_FOLDERS_RECEIVED,
    payload: { folders, fileType }
})
export const studyFileFolderReceived = (folder, fileType, selectedFolder) => ({
    type: STUDY_FILE_FOLDER_RECEIVED,
    payload: { folder, fileType, selectedFolder }
})

export const setStudyFileFolder = (folders) => ({
    type: SET_STUDY_FILE_FOLDER,
    payload: { folders }
});
export const setSasJobFolder = (folders) => ({
    type: SET_SAS_JOB_FOLDER,
    payload: { folders }
})
export const refreshStudyFileFolder = (folders) => ({
    type: REFRESH_STUDY_FILE_FOLDER,
    payload: { folders }
});
export const refreshSasJobFolder = (folders) => ({
    type: REFRESH_SASJOB_FOLDER,
    payload: { folders }
})

export const receivedStudy = (study, viewMode) => ({
    type: RECEIVED_STUDY,
    payload: { study, viewMode }
})

export const studyFileDownloadSasFile = (id) => ({
    type: STUDY_FILE_DONWLOAD_SAS_JOB,
    payload: { id: id }
})

export const getStudyFileFolders = () => ({
    type: GET_STUDY_FILES_FOLDERS,
    payload: {}
})

export const getStudyFileFoldersWithFolder = (folder, status) => ({
    type: GET_STUDY_FILES_FOLDERS_WITH_FOLDER,
    payload: { folder, status }
})

export const clearSelectedFolders = () => ({
    type: CLEAR_SELECTED_FOLDERS,
    payload: {}
})

export const clearSelectedFolder = (folder) => ({
    type: CLEAR_SELECTED_FOLDER,
    payload: { folder }
})
export const clearSelectedFolderBegin = (folder) => ({
    type: CLEAR_SELECTED_FOLDER_BEGIN,
    payload: { folder }
})

export const clearSelectedSasFolder = (folder) => ({
    type: CLEAR_SELECTED_SAS_FOLDER,
    payload: { folder }
})
export const clearSelectedSasFolderBegin = (folder) => ({
    type: CLEAR_SELECTED_SAS_FOLDER_BEGIN,
    payload: { folder }
})
export const getAdminsOnStudy = (studyId) => ({
    type: GET_ADMIN_ON_STUDY,
    payload: { studyId }
})
export const receivedAdminsOnStudy = (admins) => ({
    type: RECEIVED_ADMIN_ON_STUDY,
    payload: { admins }
})

export const selectStudy = (studyId, viewMode) => ({
    type: SELECT_STUDY,
    payload: { studyId, viewMode }
});
export const getStudies = () => ({
    type: GET_STUDIES,
    payload: {}
});

export const getStudiesV2 = () => ({
    type: GET_STUDIES_V2,
    payload: {}
});
export const getStudiesWithProjects = () => ({
    type: GET_STUDIES_WITH_PROJECTS,
    payload: {}
});

export const receivedQcJob = (qcJob) => ({
    type: RECEIVED_QC_JOB,
    payload: { qcJob }
})

export const getSasJobs = studyId => ({
    type: GET_SASJOBS,
    payload: { studyId }
});

export const applySasRegex = (value) => ({
    type: APPLY_SAS_REGEX,
    payload: { value }
})
export const applyUploadRegex = (value) => ({
    type: APPLY_UPLOAD_REGEX,
    payload: { value }
})
export const applyDownloadRegex = (value) => ({
    type: APPLY_DOWNLOAD_REGEX,
    payload: { value }
})
export const applyReleaseRegex = (value) => ({
    type: APPLY_RELEASE_REGEX,
    payload: { value }
})

export const getSasJobsPaging = (folders) => ({
    type: SASJOBS_GET_PAGING,
    payload: { folders }
});

export const specsReceived = (res) => ({
    type: SPECS_RECEIVED,
    payload: { res }
});

export const studiesReceived = (res) => ({
    type: STUDIES_RECEIVED,
    payload: { res }
});

export const studiesReceivedv2 = (res) => ({
    type: STUDIES_RECEIVED_V2,
    payload: { res }
});
export const receivedSimpleStudy = (study) => ({
    type: RECEIVED_SIMPLE_STUDY,
    payload: { study }
});

export const sasJobsReceived = (res) => ({
    type: SASJOBS_RECEIVED,
    payload: { res }
});
export const sasJobsReceivedPaging = (res) => ({
    type: SASJOBS_RECEIVED_PAGING,
    payload: { res }
})
export const sasJobsGetFolders = () => ({
    type: SASJOBS_GET_FOLDERS,
    payload: {}
})

export const getSasJobFileFolders = () => ({
    type: GET_SASJOB_FILES_FOLDERS,
    payload: {}
})
export const getSasJobPaging = () => ({
    type: GET_SASJOB_PAGING
});


export const sasJobFoldersReceived = (res) => ({
    type: SASJOBS_FOLDERS_RECEIVED,
    payload: { res }
})

export const sasJobsFilesReceived = (res) => ({
    type: SASJOBS_FILES_RECEIVED,
    payload: { res }
})

export const validationExecutionFoldersReceived = (res) => ({
    type: VALIDATION_EXECUTION_FOLDERS_RECEIVED,
    payload: { res }
})

export const sasJobsAddQCStatus = () => ({
    type: SASJOBS_QC_STATUS_ADD,
})
export const sasJobsResetPaging = (folder) => ({
    type: SASJOBS_RESET_PAGING,
    payload: { folder }
})

export const studyFileResetPaging = (fileType) => ({
    type: STUDY_FILE_RESET_PAGING,
    payload: { fileType }
})

export const startRefreshFolderFiles = () => ({
    type: START_REFRESH_FOLDER_FILES,
    payload: {}
})

export const studyFileResetPagingWithFolder = (folder, fileType) => ({
    type: STUDY_FILE_RESET_PAGING_WITH_FOLDER,
    payload: { folder, fileType }
})

export const studyFileInit = (studyId, studyFileId) => ({
    type: STUDY_FILE_INIT,
    payload: { studyId, studyFileId }
});
export const studyFileInitV2 = (studyId, studyFileId) => ({
    type: STUDY_FILE_INIT_V2,
    payload: { studyId, studyFileId }
});

export const deleteSuccess = id => ({
    type: DELETE_SUCCESS,
    payload: { id }
})

export const getJson = (file, type) => ({
    type: STUDY_GET_JSON,
    payload: { file, type }
});

export const getConfigCSV = file => ({
    type: STUDY_GET_CONFIG_CSV,
    payload: { file }
});

export const studyPayerJsonReceive = json => ({
    type: STUDY_PAYER_JSON_RECEIVED,
    payload: json
})
export const studyConfigJsonReceived = json => ({
    type: STUDY_CONFIG_JSON_RECEIVED,
    payload: json
})
export const configCSVReceived = csv => ({
    type: STUDY_CONFIG_CSV_RECEIVED,
    payload: csv
})

export const payerFilterChanged = (value, name) => ({
    type: PAYER_FILTER_CHANGED,
    payload: { value, name }
})

export const resetPayerFilter = () => ({
    type: RESET_PAYER_FILTER
})


export const filesChangedAfterDate = () => ({
    type: FILES_CHANGED_AFTER_DATE
})
export const sasfilesChangedAfterDate = () => ({
    type: SAS_FILES_CHANGED_AFTER_DATE
})

export const filesChangedAfterDateFinished = (changes, fileType) => ({
    type: FILES_CHANGED_AFTER_DATE_FINISHED,
    payload: { changes, fileType }
})
export const sasfilesChangedAfterDateFinished = (changes) => ({
    type: SAS_FILES_CHANGED_AFTER_DATE_FINISHED,
    payload: { changes }
})

export const refreshEveryXminutes = () => ({
    type: REFRESHEVERYXMINUTES,
    payload: {}
})

export const pagingButtonClicked = (folder, page, numPrPage) => ({
    type: PAGING_BUTTON_CLICKED,
    payload: { folder, page, numPrPage }
})

export const pagingButtonClickedSas = (folder, page, numPrPage) => ({
    type: PAGING_BUTTON_CLICKED_SAS,
    payload: { folder, page, numPrPage }
})

export const pagingButtonClickedReleased = (folder, page, numPrPage) => ({
    type: PAGING_BUTTON_CLICKED_RELEASED,
    payload: { folder, page, numPrPage }
})

export const resetStudyFileRO = (id) => ({
    type: RESET_STUDY_FILE_RO,
    payload: { id }
})
export const resetSasJobRO = (id) => ({
    type: RESET_SAS_JOB_RO,
    payload: { id }
})


