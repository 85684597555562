

// https://stackblitz.com/edit/material-ui-custom-outline-color?file=ValidField.js

// https://github.com/mui-org/material-ui/blob/a59903abde3925dd239a89a4d02a6c7020236302/packages/material-ui/src/FilledInput/FilledInput.js#L27-L28
const loginStyles = theme => ({

  icon: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "0%",
    left: "50%",
    fontSize: "4rem",
    color: /*theme.palette.primary.light*/ "#4caf50",
    transform: `translate3d(-50%, 0%, 0)`
  },
  textField: {
    marginTop: "1rem",
    width: "100%",
    '@media (max-width:600px)': {
      /*width: "80%"*/
    },
    // background: "white",
    ...theme.typography,
    marginLeft: 0,
    marginRight: 0
  },
  MuiAppBarColor: {
    color: "#000000",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    position: "static",
    width: "100%",
    display: "flex",
    zIndex: 1100,
    boxSizing: "border-box",
    flexShrink: 0,
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: '0px'
  },
  test: {
    background: "red"
  },
  adornedStart: {
    marginTop: "0px !important"
  },
  textFieldPhone: {
    marginTop: "1rem",
    marginBottom: '1rem',
    width: "100%",
    '@media (max-width:600px)': {
      width: "80%",
      marginLeft: '3rem',
    },
    background: "white",
    ...theme.typography,
    marginLeft: 0,
    marginRight: 0
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
    ...theme.typography
  },
  containerPhonetextField: {
    "label[data-shrink=false] + & ::-webkit-input-placeholder": {
      opacity: "1 !important"
    },
    /*"label[data-shrink=false] + & ::-webkit-input-placeholder": {
      opacity: "1 !important"
    },*/
    marginTop: '0!important',
    background: 'white'
  },
  phoneLabel: {
    ...theme.typography,
    color: "#333",
    fontSize: "0.7rem",/**before 5 */

    transform: "translate(0, -0.5rem)",
    textTransform: "uppercase",
    '@media (max-width:600px)': {
      fontSize: "1rem"
    }
  },
  input: {
    borderRadius: "0.25rem",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    height: "2.2rem",
    fontSize: "0.6rem",
    padding: 0,
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none"
    },

    "&:placeholder": {
      color: "red"
    },
    "label[data-shrink=false] + & ::-webkit-input-placeholder": {
      opacity: "1 !important"
    },
    // backgroundColor: "rgba(0, 0, 0, 0)",
    backgroundColor: "white",
    '@media (max-width:600px)': {
      fontSize: "1rem",
      height: "2.5rem"
    }
  },
  input2: {
    // borderRadius: "0.25rem",
    // border: "1px solid rgba(0, 0, 0, 0.1)",
    width: '100%',
    height: "2.2rem",
    fontSize: "0.6rem",
    padding: 0,
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none"
    },

    "&:placeholder": {
      color: "red"
    },
    "label[data-shrink=false] + & ::-webkit-input-placeholder": {
      opacity: "1 !important"
    },
    backgroundColor: "rgba(0, 0, 0, 0)",
    '@media (max-width:600px)': {
      fontSize: "1rem",
      height: "2.5rem"
    }
  },
  formPhone: {
    margin: '1rem 0',
    '@media (max-width:600px)': {
      margin: '2rem 0',
    },
  },
  phoneAdornment: {
    marginLeft: '0.5rem',
    marginRight: 0,
  },
  input3: {
    borderRadius: "0.25rem",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    height: "2.2rem",
    fontSize: "0.6rem",
    /*marginTop: '0!important',*/
    padding: 0,
    "&:before": {
      borderBottom: "none!important"
    },
    "&:after": {
      borderBottom: "none"
    },

    "&:placeholder": {
      color: "red"
    },

    "label[data-shrink=false] + & ::-webkit-input-placeholder": {
      opacity: "1 !important"
    },
    backgroundColor: "white",
    '@media (max-width:600px)': {

      fontSize: "1rem",
      height: "2.5rem"
    },

    marginLeft: 0,
    marginRight: 0
  },
  inputRoot: {
    "label[data-shrink=false] + & ::-webkit-input-placeholder": {
      opacity: "1 !important"
    }
  },

  root: {
    flexGrow: 1,
    width: "18rem",
    textAlign: "center",
    background: "white",
    margin: "5rem auto"
  },
  popopPaper: {
    width: "900px",
    background: "white"
  },
  floatRight: {
    float: "right",
    marginTop: "0.5rem"
  },
  container: {
    width: "100%",
    height: "100%",
    background: "white"
  },
  splash: {
    display: "inline-block",
    verticalAlign: "top",
    position: "relative",
    width: "30%",
    height: "100vh",
    color: "white",
    transform: "translate(20%, 20%)",

    '@media (max-width:600px)': {
      width: "50%",
      height: "8rem",
      marginTop: "2rem"
    },
    ...theme.typography,
    '& > svg': {
      pointerEvents: 'none'
    }
  },
  title: {
    textAlign: "center",
    color: "#004884"
  },
  toolbar: {
    margin: "0 auto"
  },
  content: {
    width: "370px",
    maxWidth: "370px",
    background: "white",
    Maxheight: "600px",
    position: "relative",
    margin: "0 auto",
    top: "80px",
    boxShadow: "0px 3px 4px 1px rgba(0, 0, 0, 0.1)"
  },
  contentSingle: {
    width: "100%",
    height: "100vh",
    display: "inline-block",
    verticalAlign: "top",
    position: "relative",
    '@media (max-width:600px)': {
      width: "100%",
      height: "80vh",
      maxWidth: "600px",
      margin: "0 auto"
    }
  },
  singleSplash: {
    textAlign: "center",
    margin: "2rem auto",
    '@media (max-width:600px)': {
      width: "80%",
      textAlign: "center",
      margin: "2rem auto"
    }
  },
  logoHeading: {
    top: "-1.8rem",
    textAlign: "center",
    margin: "0 auto",
    position: "relative",
    height: "0px",
  },
  textheading: {
    marginTop: "0.2rem",
  },
  innerSignup: {
    padding: "1.5rem"
  },
  innerSignin: {
    padding: "1.5rem"
  },

  description: {
    textAlign: "center",
    ...theme.typography,
    fontSize: "0.7rem",
    marginBottom: "1rem"
  },
  header: {
    fontSize: "1.5rem",
    color: "black",
    marginBottom: "1.5rem"
  },
  innerText: {
    ...theme.typography,
    marginBottom: "1rem",
    fontSize: "0.7rem",
    '& > span': {
      display: 'inline-block',
      position: 'relative',
      top: '-0.3rem',
      left: '0.5rem'
    },
  },
  formControl: {
    marginTop: 0
  },
  wrapper: {
    position: "relative",
    marginTop: "1rem"
  },
  wrapperCta: {
    position: "relative",
    marginTop: "2rem"
  },
  m2: {
    marginTop: "2rem"
  },
  ctaBtn: {
    width: "50%",
    '@media (max-width:600px)': {
      width: "80%"
    }
  },
  ctaBtnLogin: {
    '@media (max-width:600px)': {
      width: "80%",
      marginBottom: "1rem",
      marginTop: "1rem"
    }
  },
  contentModal: {
    width: "100%"
  },
  fabProgress: {
    color: "white",
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    width: "50px",
    top: "50%",
    // right: "58px",
    marginTop: -12,
    marginLeft: 0 /*-12*/
  },
  cssFocused: {
    color: "#333",
  },
  phoneInputFocused: {
    color: "#4caf50",
    backgroundColor: "rgba(205, 240, 247, 0.2)"
  },
  cssLabel: {
    color: "#333",
    backgroundColor: "white",
    borderRadius: "18px"
  },
  label: {
    color: "#333",
    fontSize: "0.5rem",
    transform: "translate(0, -1rem)",
    textTransform: "uppercase",
    '@media (max-width:600px)': {
      fontSize: "0.7rem"
    },
    width: "100%",
    display: "flex"
  },
  inputPadding: {
    padding: "14px 20px 10px",
    "&::placeholder": {
      color: "#b5b3b3"
    }
  },
  inputPadding2: {
    height: '2.2rem',
    padding: "14px 20px 10px",
    /*"&::placeholder": {
      color: "#b5b3b3"
    }*/
  },
  lastElm: {
    // flex: "50%",
    textAlign: "center",
    // width: "80%",
    '@media (max-width:600px)': {
      alignItems: "center",
      width: "100%",
      textAlign: "center"
    }
  },
  relative: {
    position: "relative"
  },
  drawerHeader: {
    margin: "1rem 0 0 1.5rem",
    ...theme.typography,
    fontSize: "1rem",
    color: "#9e9e9e"
  },
  baseBtnOverWrite: {
    marginTop: "1rem",
    background: "white",
    color: "black",
    "&:hover": {
      background: "#4caf50",
      color: "white"
    },
    width: "100%",
    minWidth: "4rem",
    /*marginTop: "1rem",*/
    padding: "0 1rem",
    '@media (max-width:600px)': {
      width: "80%"
    }
  },
  btn: {
    marginTop: "1rem",
    background: "#2196F3",
    color: "white",
    "&:hover": {
      background: "#4caf50"
    },
    '@media (max-width:600px)': {
      width: "80%"
    }
  },
  center: {
    textAlign: "center"
  },
  clearTop: {
    top: '0!important',
  },
  talignLeft: {
    textAlign: "left"
  },
  tooltipList: {},
  tooltipHeader: {
    ...theme.typography,
    fontSize: "0.6rem",
    fontWeight: "bold",
    margin: "0.2rem 0"
  },
  tooltipListElement: {
    ...theme.typography,
    fontSize: "0.6rem",
    lineHeight: "0.8rem"
  },
  mbHalf: {
    marginBottom: '0.5rem',
  }
});

export default loginStyles;
