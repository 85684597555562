import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
} from "@mui/material";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../actions/';

const styles = {
  avatar: {
    /*margin: 10,*/
    margin: 0
  },
  paper: {
    // backgroundColor: 'red',
    boxShadow: "none",
    width: 230
  },
  purpleAvatar: {
    color: "#fff",
    marginLeft: "1.5rem",
    backgroundColor: "#333" /*deepPurple[500]*/,
    fontSize: "10px",
    width: "1.5rem",
    height: "1.5rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0273b5"
    }
  },
  redWarningAvatar: {
    color: "#fff",
    marginLeft: "1.5rem",
    backgroundColor: "red" /*deepPurple[500]*/,
    fontSize: "12px",
    fontWeight: 600,
    width: "1.5rem",
    height: "1.5rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#8b0000"
    }
  },
  btn: {
    "&:hover": {
      backgroundColor: "none"
    },
    "& purpleAvatar": {
      backgroundColor: "#0273b5"
    }
  },
  inline: {
    display: "inline-flex"
  },
  menuItem: {
    textTransform: "uppercase",
    fontSize: "0.55rem"
  },
  icon: {
    textAlign: "center",
    lineHeight: "15px",
    fontSize: "15px",
    color: "#fff"
  },
};

const UserMenu = ({ emailConfirmed, initials }) => {
  const dispatch = useDispatch();

  //const { initials, actions, emailConfirmed } = this.props;
  let avatarStyle = { ...styles.avatar, ...styles.purpleAvatar };
  if (!initials || initials === "?" || !emailConfirmed) avatarStyle = { ...avatarStyle, ...styles.redWarningAvatar };
  return (
    <Grid sx={{ ...styles.inline }}>
      <Button
        id="userMenuClose"
        size="small"
        sx={{ ...styles.btn, backgroundColor: "transparent" }}
        disableRipple
        //buttonRef={node => {
        //  this.anchorEl = node;
        //}}
        /*
        ref={node => {
          anchorEl = node;
      }}
      */
        aria-owns={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={() => dispatch(actions.userProfileToggleDrawer())}
      >
        <Avatar id="avatar" sx={avatarStyle}>
          {(!initials || initials === "?") ? "!" : initials}
        </Avatar>
      </Button>

    </Grid >
  );
}

export default UserMenu;
