import { get, post, put, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth'
import { Amplify } from 'aws-amplify';

export default class APIService {

    async get(_endpoint) {
        const apiName = 'metawebserviceRestApi'

        const bearer = (await fetchAuthSession()).tokens.idToken.toString();
        const myInit = {
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                'Authorization': `Bearer ${bearer}`,
            },
        };
        const getRequest = {
            apiName: apiName,
            path: _endpoint,
            options: myInit
        };

        try {
            const { body } = await get(getRequest).response;
            const data = await body.json();

            return data;
        }

        catch (e) {
            console.log('GET call failed: ', JSON.parse(e.$metadata.httpStatusCode));

            return { ...undefined, status: e.$metadata.httpStatusCode };
        }
    }
    async post(_endpoint, _body) {
        const apiName = 'metawebserviceRestApi'
        const bearer = (await fetchAuthSession()).tokens.idToken.toString();
        const myInit = {
            body: _body,
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                'Authorization': `Bearer ${bearer}`
            }
        };
        const postRequest = {
            apiName: apiName,
            path: _endpoint,
            options: myInit
        };
        const { body } = await post(postRequest).response;
        const data = await body.json();
        return data;
    }

    async put(_endpoint, _body) {
        const apiName = 'metawebserviceRestApi';
        const bearer = (await fetchAuthSession()).tokens.idToken.toString();
        const myInit = {
            body: _body,
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                'Authorization': `Bearer ${bearer}`
            }
        };
        const putRequest = {
            apiName: apiName,
            path: _endpoint,
            options: myInit
        };
        try {
            const existingConfig = Amplify.getConfig();
            //console.log("Existing config 1.3", existingConfig, _endpoint, myInit);

            //const restOperation = put(putRequest);
            //const response = await restOperation.response;

            const { body } = await put(putRequest).response;

            const data = await body.json();
            return data;
        }
        catch (e) {
            console.log('PUT call failed: ', JSON.parse(e.$metadata.httpStatusCode));

            return { ...undefined, status: e.$metadata.httpStatusCode };
        }

    }
    async delete(_endpoint, _body) {
        const apiName = 'metawebserviceRestApi'
        const bearer = (await fetchAuthSession()).tokens.idToken.toString();
        const myInit = {
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                'Authorization': `Bearer ${bearer}`
            }
        };
        const delRequest = {
            apiName: apiName,
            path: _endpoint,
            options: myInit
        };

        try {
            const response = await del(delRequest).response;
            //const data = await body.json();
            return response;
        }
        catch (e) {
            return { ...undefined, status: e.response.statusCode };
        }
    }
};
