import { all, fork } from "redux-saga/effects";
import { loginSagas } from './login';
import { createStudySagas } from './createStudy';
import { userProfileSagas } from './userProfile';
import { studyInviteSagas } from './studyInvite';
import { adminPermissionsSagas } from './adminPermissions';
import { fileVersionsSagas } from './fileVersions';
import { sasJobSagas } from './sasJob';
import commonSagas from './common';
import { UtilitySagas } from './utility'

export function* rootSagas() {
    yield all([
        fork(loginSagas),
        fork(createStudySagas),
        fork(userProfileSagas),
        fork(studyInviteSagas),
        fork(adminPermissionsSagas),
        fork(fileVersionsSagas),
        fork(sasJobSagas),
        fork(UtilitySagas),
        fork(commonSagas)
    ])
}