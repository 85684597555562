import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import Button from '../ui/Button';
import BasicTextField from '../ui/BasicTextField';
import styles from '../auth/LoginStyles';

const CustomContainer = styled('div')(styles);

const resolveVerificationCodeError = (errorMessage) => {
  if (!errorMessage) return false;

  const errorMapping = {
    "confirmationCode": "Please type in the 6 digits you received on SMS",
    "Invalid code provided": errorMessage,
    "verification": errorMessage,
    "password": errorMessage
  };

  return errorMapping[Object.keys(errorMapping).find(key => errorMessage.includes(key))] || false;
};

const ChangePassword = ({ errorMessage, loading, changeSuccess, actions }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !loading) {
        e.preventDefault();
        dispatch(actions.userProfileChangePassword(verificationCode, password));
      }
    },
    [actions, verificationCode, password, loading]
  );

  const handleSubmitBtn = () => {
    if (!loading) {
      dispatch(actions.userProfileChangePassword(verificationCode, password));
    }
  };

  if (changeSuccess) {
    return (
      <CustomContainer style={{ marginTop: '4rem' }}>
        <CheckCircleIcon />
        <p>Password changed successfully</p>
        <div className={clsx('wrapper', 'center')}>
          <Button onClick={() => dispatch(actions.userProfileCloseAttributeModal())} id="userProfileCloseAttributeModal">
            Close
          </Button>
        </div>
      </CustomContainer>
    );
  }

  return (
    <CustomContainer>
      {/* Remaining JSX */}
    </CustomContainer>
  );
};

ChangePassword.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  changeSuccess: PropTypes.bool,
  actions: PropTypes.object.isRequired,
};

export default ChangePassword;
