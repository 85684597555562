import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/material/";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import loginStyles from "./LoginStyles";
import Button from "../ui/Button";
import BasicTextField from "../ui/BasicTextField";
import * as actions from "../actions";

const styles = loginStyles;

function VerifyAttribute(props) {

  //state = { verificationCode: '' }
  const [verificationCode, setVerificationCode] = useState(' ');
  const { errorMessage, loading, type, phone, email } = props;

  resolveError = type => {
    if (errorMessage && errorMessage.length && errorMessage.includes(type)) {
      return errorMessage;
    }
  };

  handleKeyPress = e => {
    //const { actions, loading, type } = props;
    //const { verificationCode } = this.state

    if (!loading) {
      if (e.key === "Enter") {
        e.preventDefault();
        if (type === "phoneNumber") {
          dispatch(actions.userProfileVerifyPhoneNumber(verificationCode))
        }
        else if (type === "email") {
          dispatch(actions.userProfileVerifyEmail(verificationCode))
        }
      }
    }
  };

  userUpdateVerificationCode = (value) => {
    setVerificationCode(value)
  }

  handleSubmitBtn = () => {
    /*const {
      loading,
      actions,
      type,
    } = this.props;

    const { verificationCode } = this.state
    */

    if (!loading) {
      if (type === "phoneNumber") {
        dispatch(actions.userProfileVerifyPhoneNumber(verificationCode))
      }
      else if (type === "email") {
        dispatch(actions.userProfileVerifyEmail(verificationCode))
      }
    }
  };
  /*
  render() {
    const {
      classes,
      loading,
      phone,
      email,
      type,
    } = this.props;
    const { verificationCode } = this.state
    */
  return (
    <div className={styles.base}>
      <Grid container direction="column">
        {/* Information */}
        <Grid item>
          {type === "phoneNumber" &&
            <p style={{ marginLeft: "0.5rem" }} className={styles.innerText}>
              Enter the verification code sent to this phone number: {phone}
            </p>
          }
          {type === "email" &&
            <p style={{ marginLeft: "0.5rem" }} className={styles.innerText}>
              Enter the verification code sent to this email: {email}
            </p>
          }
        </Grid>
        {/* Verification code field*/}
        <Grid item>
          <form autoComplete="off" onKeyPress={handleKeyPress}>
            <BasicTextField
              placeholder={"VerificationCode"}
              id="verificationCode"
              label="Verification code"
              classes={styles}
              error={resolveError("verification")}
              value={verificationCode}
              onChange={userUpdateVerificationCode}
            />
          </form>
        </Grid>
        {/* submit button */}
        <Grid item className={styles.base}>
          <Grid item className={styles.lastElm}>
            <div className={styles.wrapperCta}>
              <Button
                id="verifyCodeSubmit"
                disabled={loading}
                className={classNames(
                  styles.buttonWrapper,
                  styles.clearTop
                )}
                onClick={handleSubmitBtn}
              >
                Verify
                {loading && (
                  <CircularProgress
                    size={24}
                    className={styles.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}


VerifyAttribute.propTypes = {
  classes: PropTypes.object.isRequired,
  phone: PropTypes.string,
  loading: PropTypes.bool,
  email: PropTypes.string,
  type: PropTypes.string
};

export default (VerifyAttribute);
