import { fetchAuthSession } from 'aws-amplify/auth';

export const awsExports = {
  aws_project_region: "eu-west-1",
  aws_cognito_identity_pool_id: "eu-west-1:9eb11c60-23ab-48b1-892c-196bd7577a3d",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_jmS0VsTuM",
  aws_user_pools_web_client_id: "4jun66qhtkbm1gr3parou7gnh6",
  oauth: {
    domain: 'dev-metawebservice.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: ("dev" === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'http://localhost:3000' :
      ("dev" === "dev") ? "https://dev.metawebservice.com" :
        ("dev" === "test") ? "https://test.metawebservice.com" : "https://metawebservice.com",
    redirectSignOut: ("dev" === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'http://localhost:3000' :
      ("dev" === "dev") ? "https://dev.metawebservice.com" :
        ("dev" === "test") ? "https://test.metawebservice.com" : "https://metawebservice.com",
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
 "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_cloud_logic_custom": [
    {
      name: 'metawebserviceRestApi',
      endpoint: ("dev" === "dev") ? "https://devapi.metawebservice.com/dev/" : ("dev" === "test") ? "https://testapi.metawebservice.com/test/" : "https://api.metawebservice.com/prod/",
      //endpoint: "http://localhost:50350/",
      region: "eu-west-1"
    }
  ],
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: "_eu-west-1:9eb11c60-23ab-48b1-892c-196bd7577a3d",
    // REQUIRED - Amazon Cognito Region
    region: "_eu-west-1",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "_eu-west-1_jmS0VsTuM",
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: "_4jun66qhtkbm1gr3parou7gnh6",
    _oauth: {
      domain: 'dev-metawebservice.auth.eu-west-1.amazoncognito.com',
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: ("dev" === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'http://localhost:3000' :
        ("dev" === "dev") ? "https://dev.metawebservice.com" :
          ("dev" === "test") ? "https://test.metawebservice.com" : "https://metawebservice.com",
      redirectSignOut: ("dev" === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'http://localhost:3000' :
        ("dev" === "dev") ? "https://dev.metawebservice.com" :
          ("dev" === "test") ? "https://test.metawebservice.com" : "https://metawebservice.com",
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    cookieStorage: {
      domain: ("dev" === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'localhost' :
        "metawebservice.com",
      secure: (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "") ? false : true,
      path: '/',
      sameSite: "strict",
      expires: 365,
    }
  }
}

export default awsExports

