import "./Sidebar.scss";
import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions/";
import PropTypes from "prop-types";
import classNames from "classnames";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { withStyles } from "@mui/material"; // maybe try to do it in dif import
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from "@mui/material/IconButton";
import UploadModal from "../pages/modals/UploadModal";
import StudyInviteUser from "../pages/invite/StudyInviteUser";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { selectDashBoardPermissisons, getStudyInviteState, selectUserProfile, selectStudyDetail, selectStudiesLoaded, selectStudies } from "../selectors/storeselectors";

const styles = /*theme =>*/ ({
  root: {
    //color: theme.palette.text.primary
  },
  lightTooltip: {
    pointerEvents: "none",
    cursor: "pointer"
  },
  tooltipStudy: {},
});

const Sidebar = ({ studiesSelected }) => {

  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const id = useSelector(state => state.root.selectedStudy);
  const [selected, setSelected] = useState("dashboard");


  useEffect(() => {
    if (location.pathname === "/") {
      setSelected("dashboard")
    }
    else if (location.pathname.includes("uploads") && selected !== "uploads") {
      setSelected("uploads")
    }
    else if (location.pathname.includes("downloads") && selected !== "downloads") {
      setSelected("downloads")
    }
    else if (location.pathname.includes("release") && selected !== "release") {
      setSelected("relase")
    }
    else if (location.pathname.includes("sasjobs") && selected !== "sasjobs") {
      setSelected("sasjobs")
    }
    else if (location.pathname.includes("sasexecution") && selected !== "sasexecution") {
      setSelected("sasexecution")
    }
    else if (location.pathname.includes("permissions") && selected !== "permissions") {
      setSelected("permissions")
    }
  }, [location, selected]);


  const handleLinkClick = (value) => {
    setSelected(value);

    if (value === "studies" && pathname !== "/") {
      dispatch(actions.resetStudyDetail());
      setSelected("dashboard")
      navigate("/");

    }

    if (value === "dashboard" && pathname !== `/study/${id}`) {
      navigate(`/study/${id}`);
    }
    else if (value === "uploads" && pathname !== `/study/${id}/uploads`) {
      navigate(`/study/${id}/uploads`);
    } else if (
      value === "downloads" &&
      navigate(`/study/${id}/downloads`)
    ) {
      navigate(`/study/${id}/downloads`);
    } else if (
      value === "release" &&
      pathname !== `/study/${id}/release`
    ) {
      navigate(`/study/${id}/release`);
    } else if (
      value === "sasjobs" &&
      pathname !== `/study/${id}/sasjobs`
    ) {
      navigate(`/study/${id}/sasjobs`);
    }
    else if (
      value === "sasexecution" &&
      pathname !== `/study/${id}/sasexecution`
    ) {
      navigate(`/study/${id}/sasexecution`);
    }
    else if (
      value === "permissions" &&
      pathname !== `/study/${id}/permissions`
    ) {
      navigate(`/study/${id}/permissions`);
    }
    /*else if (
      value === "security" &&
      pathname !== `/study/${id}/security`
    ) {
      navigate(`/study/${id}/security`);
    }*/
    else if (
      value === "utilities" &&
      pathname !== `/study/${id}/utilities`
    ) {
      navigate(`/study/${id}/utilities`);
    }
  };

  const showOnboarding = () => {
    dispatch(actions.showOnboarding())
    //use navigate to redirect to onboarding page
    //old code: props.history.push("/");
  }

  const renderInvitesModal = (studyInvite) => {
    if (studyInvite && studyInvite.open) {
      return (
        <StudyInviteUser
          {...studyInvite}
          open={true}
          onClose={() => dispatch(actions.studyInviteToggleModal())}
          value={0}
        />
      );
    }
  };


  const studySelected = pathname.indexOf("/study") === -1 ? false : true;
  const permissionDetail = useSelector(selectDashBoardPermissisons);
  const studyInvite = useSelector(getStudyInviteState);
  const studyDetail = useSelector(selectStudyDetail);
  const userProfile = useSelector(selectUserProfile);
  const studiesLoaded = useSelector(selectStudiesLoaded);
  const studies = useSelector(selectStudies);

  const permissionList =
    permissionDetail &&
      permissionDetail.length > 0
      ? permissionDetail.filter(item => item.selected === true).map(item => item.permission)
      : [];

  const canRenderUploadBtn = permissionList.includes("UPLOAD");
  const hasAdminRights = permissionList.includes("ADMIN");

  return (
    <React.Fragment>
      {(studySelected) &&
        <React.Fragment>

          {studyDetail && studyDetail.uploadModal &&
            <UploadModal
              actions={actions}
              viewMode={studyDetail.viewMode}
              uploading={studyDetail.uploading}
              uploadSucces={studyDetail.uploadSucces}
              permissions={studyDetail.dashboardPermissions}
              open={true}
              onClose={() => dispatch(actions.studyCloseUploadModal())}
              value={0}
              potentialUploads={studyDetail.potentialUploads}
              potentialUploadsWithStatus={studyDetail.potentialUploadsWithStatus}
              selectedUploadDownloadfolder={studyDetail.selectedUploadDownloadfolder}
              uploadFolders={studyDetail.uploadFolders}
            />
          }
          {renderInvitesModal(studyInvite)}
          <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
            <nav className="side-bar-big">
              {(permissionList) &&
                <React.Fragment>
                  <Typography variant="h6" component="h6" sx={{ margin: "0.35rem" }} className={classNames(
                    "side-bar-big__list_heading"
                  )}>
                    NAVIGATION
                  </Typography>
                  <List component="nav" aria-label="navigation to pages" style={{ marginTop: "0.4rem" }}>
                    {pathname !== "/" &&
                      <ListItemButton id="navStudies" className={selected === 'studies' ? "selected" : null} key="Studies" onClick={() => handleLinkClick('studies')}>
                        <ListItemText primary="Studies" />
                        {selected === 'studies' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    }
                    <ListItemButton id="navDashboard" className={selected === 'dashboard' ? "selected" : null} key="overview" onClick={() => handleLinkClick('dashboard')}>
                      <ListItemText primary="Overview" />
                      {selected === 'dashboard' &&
                        <IconButton edge="end" aria-label="selected">
                          <ArrowBackIosIcon />
                        </IconButton>
                      }
                    </ListItemButton>
                    {permissionList.includes("UPLOAD") && (
                      <ListItemButton id="navUploads" className={selected === 'uploads' ? "selected" : null} key="uploads" onClick={() => handleLinkClick('uploads')}>
                        <ListItemText primary="Uploads" />
                        {selected === 'uploads' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    )}
                    {permissionList.includes("DOWNLOAD") && (
                      <ListItemButton id="navDownloads" className={selected === 'downloads' ? "selected" : null} key="downloads" onClick={() => handleLinkClick('downloads')}>
                        <ListItemText primary="Downloads" />
                        {selected === 'downloads' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    )}
                    {permissionList.includes("RELEASE") && (
                      <ListItemButton id="navRelease" className={selected === 'Released' ? "selected" : null} key="released" onClick={() => handleLinkClick('release')}>
                        <ListItemText primary="Released" />
                        {selected === 'release' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    )}
                    {permissionList.includes("SASUPLOAD") && (
                      <ListItemButton id="navSasjobs" className={selected === 'sasjobs' ? "selected" : null} key="sasjobs" onClick={() => handleLinkClick('sasjobs')}>
                        <ListItemText primary="Sas jobs" />
                        {selected === 'sasjobs' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    )}
                    {permissionList.includes("SASUPLOAD") && (
                      <ListItemButton id="navSasexecution" className={selected === 'sasexecution' ? "selected" : null} key="sasexecution" onClick={() => handleLinkClick('sasexecution')}>
                        <ListItemText primary="Sas execution" />
                        {selected === 'sasexecution' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    )}
                    {permissionList.includes("ADMIN") && (
                      <ListItemButton id="navPermissions" className={selected === 'permissions' ? "selected" : null} key="permissions" onClick={() => handleLinkClick('permissions')}>
                        <ListItemText primary="Permissions" />
                        {selected === 'permissions' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    )}
                    {/*permissionList.includes("ADMIN") && permissionList.includes("UPLOAD") && (
                      <ListItemButton id="navSecurity" className={selected === 'security' ? "selected" : null} key="security" onClick={() => handleLinkClick('security')}>
                        <ListItemText primary="Security" />
                        {selected === 'security' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItemButton>
                    )*/}
                    <ListItemButton id="navUtilities" className={selected === 'utilities' ? "selected" : null} key="Utilities" onClick={() => handleLinkClick('utilities')}>
                      <ListItemText primary="Utilities" />
                      {selected === 'utilities' &&
                        <IconButton edge="end" aria-label="selected">
                          <ArrowBackIosIcon />
                        </IconButton>
                      }
                    </ListItemButton>
                  </List>
                  <Divider />
                  <Typography variant="h6" component="h6" className={classNames(
                    "side-bar-big__list_heading"
                  )}>
                    ACTIONS
                  </Typography>
                  <List component="nav" aria-label="upload invite">
                    {canRenderUploadBtn && (
                      <ListItemButton id="actionUpload" key="upload" onClick={() => dispatch(actions.studyOpenUploadModal())}>
                        <ListItemIcon style={{ minWidth: "40px" }}>
                          <CloudUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary="Upload" />
                      </ListItemButton>
                    )}
                    {hasAdminRights && (
                      <ListItemButton id="actionInviteUser" key="invite" onClick={() => dispatch(actions.studyInviteToggleModal(id))}>
                        <ListItemIcon style={{ minWidth: "40px" }}>
                          <GroupAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Invite user" />
                      </ListItemButton>
                    )}
                  </List>
                </React.Fragment>
              }
            </nav>
          </Box>

          <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
            <nav className={open === true ? "side-bar-big" : "side-bar-small"}>
              {!open ?
                <React.Fragment>
                  <List id="navMenuOpen" component="nav" aria-label="menu upload invite create" style={{ marginTop: "0.4rem" }}>
                    <ListItemButton key="menu" onClick={() => setOpen(!open)}>
                      <Tooltip
                        title="Toggle menu"
                        aria-label="Toggle Menu"
                        sx={{
                          pointerEvents: "none",
                          cursor: "pointer"
                        }}
                      >
                        <ListItemIcon style={{ minWidth: "40px", color: "#fff" }}>
                          <MoreVertIcon />
                        </ListItemIcon>
                      </Tooltip>
                    </ListItemButton>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="upload invite">
                    {canRenderUploadBtn && (
                      <ListItemButton id="navUpload" key="upload" onClick={() => dispatch(actions.studyOpenUploadModal())}>
                        <Tooltip
                          title="Upload"
                          aria-label="Upload"
                          sx={{
                            pointerEvents: "none",
                            cursor: "pointer"
                          }}
                        >
                          <ListItemIcon style={{ minWidth: "40px" }}>
                            <CloudUploadIcon />
                          </ListItemIcon>
                        </Tooltip>
                      </ListItemButton>
                    )}
                    {hasAdminRights && (
                      <ListItemButton id="navInviteUser" key="invite" onClick={() => dispatch(actions.studyInviteToggleModal(id))}>
                        <Tooltip
                          title="Invite user"
                          aria-label="Invite user"
                          sx={{
                            pointerEvents: "none",
                            cursor: "pointer"
                          }}
                        >
                          <ListItemIcon style={{ minWidth: "40px" }}>
                            <GroupAddIcon />
                          </ListItemIcon>
                        </Tooltip>
                      </ListItemButton>
                    )}
                  </List>
                </React.Fragment>
                :
                <React.Fragment>
                  {(permissionList) &&
                    <React.Fragment>
                      <List id="menuClose" component="nav" aria-label="menu upload invite create" style={{ marginTop: "0.4rem" }}>
                        <ListItemButton key="menu" onClick={() => setOpen(!open)}>
                          <Tooltip
                            title="Toggle menu"
                            aria-label="Toggle Menu"
                            sx={{
                              pointerEvents: "none",
                              cursor: "pointer"
                            }}
                          >
                            <ListItemIcon style={{ minWidth: "40px", color: "#fff" }}>
                              <MoreVertIcon />
                            </ListItemIcon>
                          </Tooltip>
                        </ListItemButton>
                      </List>
                      <Divider />
                      <List component="nav" aria-label="navigation to pages" style={{ marginTop: "0.4rem" }}>
                        {pathname !== "/" &&
                          <ListItemButton id="navStudies" className={selected === 'studies' ? "selected" : null} key="Studies" onClick={() => handleLinkClick('studies')}>
                            <ListItemText primary="Studies" />
                          </ListItemButton>
                        }
                        <ListItemButton id="navDashboard" className={selected === 'dashboard' ? "selected" : null} key="overview" onClick={() => handleLinkClick('dashboard')}>
                          <ListItemText primary="Overview" />
                        </ListItemButton>
                        {permissionList.includes("UPLOAD") && (
                          <ListItemButton id="navUploads" className={selected === 'uploads' ? "selected" : null} key="uploads" onClick={() => handleLinkClick('uploads')}>
                            <ListItemText primary="Uploads" />
                          </ListItemButton>
                        )}
                        {permissionList.includes("DOWNLOAD") && (
                          <ListItemButton id="navDownloads" className={selected === 'downloads' ? "selected" : null} key="downloads" onClick={() => handleLinkClick('downloads')}>
                            <ListItemText primary="Downloads" />
                          </ListItemButton>
                        )}
                        {permissionList.includes("RELEASE") && (
                          <ListItemButton id="navReleased" className={selected === 'release' ? "selected" : null} key="released" onClick={() => handleLinkClick('release')}>
                            <ListItemText primary="Released" />
                          </ListItemButton>
                        )}
                        {permissionList.includes("SASUPLOAD") && (
                          <ListItemButton id="navSasjobs" className={selected === 'sasjobs' ? "selected" : null} key="sasjobs" onClick={() => handleLinkClick('sasjobs')}>
                            <ListItemText primary="Sas jobs" />
                          </ListItemButton>
                        )}
                        {permissionList.includes("SASUPLOAD") && (
                          <ListItemButton id="navSasexecution" className={selected === 'sasexecution' ? "selected" : null} key="sasexecution" onClick={() => handleLinkClick('sasexecution')}>
                            <ListItemText primary="Sas execution" />
                          </ListItemButton>
                        )}
                        {permissionList.includes("ADMIN") && (
                          <ListItemButton id="navPermissions" className={selected === 'permissions' ? "selected" : null} key="permissions" onClick={() => handleLinkClick('permissions')}>
                            <ListItemText primary="Permissions" />
                          </ListItemButton>
                        )}
                        <ListItemButton id="navUtilities" className={selected === 'utilities' ? "selected" : null} key="Utilities" onClick={() => handleLinkClick('utilities')}>
                          <ListItemText primary="Utilities" />
                        </ListItemButton>
                      </List>
                      <Divider />
                      <Typography variant="h6" component="h6" className={classNames(
                        "side-bar-big__list_heading"
                      )}>
                        ACTIONS
                      </Typography>
                      <List component="nav" aria-label="upload invite">
                        {canRenderUploadBtn && (
                          <ListItemButton id="actionUpload" key="upload" onClick={() => dispatch(actions.studyOpenUploadModal())}>
                            <ListItemIcon style={{ minWidth: "40px" }}>
                              <CloudUploadIcon />
                            </ListItemIcon>
                            <ListItemText primary="Upload" />
                          </ListItemButton>
                        )}
                        {hasAdminRights && (
                          <ListItemButton id="actionInvite" key="invite" onClick={() => dispatch(actions.studyInviteToggleModal(id))}>
                            <ListItemIcon style={{ minWidth: "40px" }}>
                              <GroupAddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Invite user" />
                          </ListItemButton>
                        )}
                        {/*userProfile &&
                          userProfile.initials &&
                          studiesSelected &&
                          studiesLoaded &&
                          studies &&
                          studies.length && (
                            <ListItemButton id="actionCreate" key="create" onClick={showOnboarding}>
                              <ListItemIcon style={{ minWidth: "40px" }}>
                                <AddIcon />
                              </ListItemIcon>
                              <ListItemText primary="Create study" />
                            </ListItemButton>
                          )
                        */}
                      </List>
                    </React.Fragment >
                  }
                </React.Fragment >
              }
            </nav >
          </Box >
        </React.Fragment >
      }
    </React.Fragment >
  );
}
export default Sidebar;
/*
Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  studiesSelected: PropTypes.bool,
  viewMode: PropTypes.string,
  uploading: PropTypes.bool,
  uploadSucces: PropTypes.bool,
  userProfile: PropTypes.object,
  studies: PropTypes.array,
  uploadModal: PropTypes.bool,
  studyInvite: PropTypes.object,
  id: PropTypes.number,
  dashboardPermissions: PropTypes.array,
  selectedUploadDownloadfolder: PropTypes.array,
  uploadFolders: PropTypes.array
};


function mapStateToProps(state) {
  return {
    id: state.root.studyDetail?.id,
    viewMode: state.root.studyDetail?.viewMode,
    uploading: state.root.studyDetail?.uploading,
    uploadSucces: state.root.studyDetail?.uploadSucces,
    userProfile: state.userProfile,
    studies: state.root.studies,
    uploadModal: state.root.studyDetail?.uploadModal,
    studyInvite: state.studyInvite,
    dashboardPermissions: state.root.studyDetail?.dashboardPermissions,
    potentialUploads: state.root.studyDetail?.potentialUploads,
    potentialUploadsWithStatus: state.root.studyDetail?.potentialUploadsWithStatus,
    selectedUploadDownloadfolder: state.root.studyDetail?.selectedUploadDownloadfolder,
    uploadFolders: state.root.studyDetail?.uploadFolders
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}
*/