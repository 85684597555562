import React, { Suspense, lazy } from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import { Routes, Route } from "react-router-dom";

const StudyDownloadsPage = lazy(() => import('./pages/StudyDownloadsPage'));
const StudyReleasePage = lazy(() => import('./pages/StudyReleasePage'));
const Studies = lazy(() => import('./pages/Studies'));
const Study = lazy(() => import('./pages/Study'));
const Security = lazy(() => import('./pages/Security'));
const StudyPermissions = lazy(() => import('./pages/StudyPermissions'));
const SasPage = lazy(() => import('./pages/SasPage'));
const SasJob = lazy(() => import('./pages/SasJob'));
const SasExecution = lazy(() => import('./pages/SasExecution'));
const StudyUploadsPage = lazy(() => import('./pages/StudyUploadsPage'));
const UtilitiesPage = lazy(() => import('./pages/UtilitiesPage'));
const StudyFile = lazy(() => import('./pages/StudyFileV2'));
//const Authentication = lazy(() => import('./auth/Authentication'));
//const AuthenticatedRoute = lazy(() => import('./routecomponents/AuthenticatedRoute'));
const Meta = lazy(() => import('./auth/Meta'));
const Leo = lazy(() => import('./auth/Leo'));
const Hansson = lazy(() => import('./auth/Hansson'));
const Mke = lazy(() => import('./auth/Mke'));
const Products = lazy(() => import('./pages/ProductPage'));
//const OnBoarding = lazy(() => import('./pages/OnBoarding'));
const NotFound = lazy(() => import('./pages/NotFound')); // Import the NotFound component
//import { StudyProvider } from './common/StudyContext';

const MyComponent = ({ childProps }) => (
  <Suspense fallback={
    <div
      style={{
        width: "3.125rem",
        height: "3.125rem",
        position: "absolute",
        top: '0.5rem',
        left: '0.5rem',
      }}
    >
      <CircularProgress size={35} />
    </div>}

  >
    <Routes>
      {/*<Route path="/Auth" element={<Authentication />} childProps={childProps} />*/}
      {/*<Route path="/auth/:email?/:invitationid?" element={<Authentication />} childProps={childProps} />*/}
      <Route path="/" element={<Studies />} childProps={childProps} />
      <Route exact path="/studies/:invitationid?" /*exact*/ element={<Studies />} childProps={childProps} />

      <Route exact path="/newStudy" element={<Products />} childProps={childProps} />
      <Route exact path="study/:id" element={<Study />} />
      <Route exact path="/study/:id/security" element={<Security />} childProps={childProps} />
      <Route exact path="/study/:id/permissions" element={<StudyPermissions />} childProps={childProps} />
      <Route exact path="/study/:id/sasexecution" element={<SasExecution />} childProps={childProps} />
      <Route exact path="/study/:id/sasjobs" element={<SasPage />} childProps={childProps} />
      <Route exact path="/study/:id/sasjob/:sasJobId" element={<SasJob />} childProps={childProps} />
      <Route exact path="/study/:id/utilities" element={<UtilitiesPage />} childProps={childProps} />
      <Route exact path="/study/:id/uploads" element={<StudyUploadsPage />} childProps={childProps} />
      <Route exact path="/study/:id/downloads" element={<StudyDownloadsPage />} childProps={childProps} />
      <Route exact path="/study/:id/release" element={<StudyReleasePage />} childProps={childProps} />
      {/*/study/1337/studyFile/download/10116b1f-fb26-4109-bf32-baf1fbfd4770*/}
      <Route exact path="/study/:id/studyFile/:fileType/:studyFileId" element={<StudyFile />} childProps={childProps} />

      {/*<Route exact path="/utility/collect" component={Collect/>} childProps={childProps} />*/}
      <Route path="*" element={<NotFound />} /> {/* Add the NotFound route */}
    </Routes>
  </Suspense>
);

export default MyComponent;